import "./CmsPageForm.css";

import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    Paper,
    Select,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DeleteIcon from "@material-ui/icons/Delete";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import uuid from "uuid-random";

import FileUpload from "../../../components/fileUpload/FileUpload";
import ImageUpload from "../../../components/imageUpload/ImageUpload";
import Notify from "../../../components/notify/Notify";
import TeamTile from "../../../components/team/TeamTile";
import TextEdit from "../../../components/wysiwyg/TextEdit";
import CmsPageElementTypeEnum from "../../../enums/CmsPageElementTypeEnum";
import DocumentTypeEnum from "../../../enums/DocumentTypeEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import ICmsPage from "../../../types/ICmsPage";
import ICmsPageElement from "../../../types/ICmsPageElement";
import IDocument from "../../../types/IDocument";
import IDocumentRef from "../../../types/IDocumentRef";
import InitialCmsPageData from "../../../types/initialData/InitialCmsPageData";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import ITeam from "../../../types/ITeam";
import ITeamRef from "../../../types/ITeamRef";
import deepCopy from "../../../utils/deepCopy";
import {
    createCmsPageRequest,
    loadCmsPageRequest,
    updateCmsPageRequest,
} from "../../../xhr/CmsPageRequests";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import {
    loadAdminProjectSelectboxRequest,
    loadPublicProjectTeamListRequest,
} from "../../../xhr/ProjectRequests";

const InitialImageRef: IDocumentRef = {
    key: "",
    type: DocumentTypeEnum.IMAGE,
};

const InitialDocumentRef: IDocumentRef = {
    key: "",
    type: DocumentTypeEnum.FILE,
};

const InitialTeamRef: ITeamRef = {
    id: "",
};

// @ts-ignore
// @ts-ignore
const initialColValue: { [key: string]: ICmsPageElement } = {
    header_1: {
        type: CmsPageElementTypeEnum.HEADER_1,
        content: "",
    },
    header_2: {
        type: CmsPageElementTypeEnum.HEADER_2,
        content: "",
    },
    header_3: {
        type: CmsPageElementTypeEnum.HEADER_3,
        content: "",
    },
    html: {
        type: CmsPageElementTypeEnum.HTML,
        content: "",
    },
    html_text_text: {
        type: CmsPageElementTypeEnum.HTML_TEXT_TEXT,
        content: ["", ""],
    },
    html_text_text_text: {
        type: CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT,
        content: ["", "", ""],
    },
    html_text_image: {
        type: CmsPageElementTypeEnum.HTML_TEXT_IMAGE,
        content: ["", { ...InitialImageRef, type: DocumentTypeEnum.IMAGE }],
    },
    html_image_text: {
        type: CmsPageElementTypeEnum.HTML_IMAGE_TEXT,
        content: [{ ...InitialImageRef, type: DocumentTypeEnum.IMAGE }, ""],
    },
    download_list: {
        type: CmsPageElementTypeEnum.DOWNLOAD_LIST,
        content: [[]],
    },
    team_list: {
        type: CmsPageElementTypeEnum.TEAM_LIST,
        content: [],
    },
};

const CmsPageForm = () => {
    const history = useHistory();

    const { mandantKey, pageId }: { mandantKey: string; pageId: any } =
        useParams<{
            mandantKey: string;
            pageId: any;
        }>();

    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(pageId));

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    /**
     * The form data
     */
    const [pageData, setPageData] = React.useState<ICmsPage>({
        ...InitialCmsPageData,
    });

    const [images, setImages] = React.useState<{ [key: string]: IDocument }>(
        {}
    );

    const [uploads, setUploads] = React.useState<{ [key: string]: IDocument }>(
        {}
    );

    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    const [teamList, setTeamList] = React.useState<{ [key: string]: ITeam }>(
        {}
    );

    /**
     * Snackkbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    React.useEffect(() => {
        if (isEditForm) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadDataFromServer(pageId);
        } else {
            // if we create an event we need at least the project
            setPageData({
                ...pageData,
            });
        }
    }, []);

    /**
     *
     */
    React.useEffect(() => {
        loadAdminProjectSelectboxRequest(mandantKey).then((response) => {
            setProjectList(response.data);
        });
    }, [mandantKey]);

    /**
     */
    React.useEffect(() => {
        if (pageData.data.project?.id) {
            loadPublicProjectTeamListRequest(
                mandantKey,
                "" + pageData.data.project?.id
            ).then((response) => {
                setTeamList(response.data);
            });
        } else {
            setTeamList({});
        }
    }, [pageData.data.project?.id]);

    const loadDataFromServer = (loadPageId: number) => {
        setIsLoading(true);

        loadCmsPageRequest(mandantKey, loadPageId)
            .then((response) => {
                setPageData({
                    data: response.data.data,
                    elements: response.data.elements,
                });
                setImages(response.data.images);
                setUploads(response.data.uploads);

                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seite ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handleSave = () => {
        setIsLoading(true);
        if (isEditForm) {
            updateCmsPageRequest(mandantKey, pageData.data.id, {
                ...pageData.data,
                content: pageData.elements,
                images,
                uploads,
            })
                .then((response) => {
                    //setLoading(false);

                    setPageData({
                        data: response.data.data,
                        elements: response.data.elements,
                    });
                    setImages(response.data.images);
                    setUploads(response.data.uploads);

                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    //setLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            createCmsPageRequest(mandantKey, {
                ...pageData.data,
                content: pageData.elements,
                images,
                uploads,
            })
                .then((response: any) => {
                    //setLoading(false);
                    setPageData({
                        data: response.data.data,
                        elements: response.data.elements,
                    });
                    setImages(response.data.images);
                    setUploads(response.data.uploads);
                    setIsEditForm(true);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Seite wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    //setLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Erstellen der Seite ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const onDeleteRow = (index: number) => {
        pageData.elements.splice(index, 1);
        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const movePageSectionUp = (index: number) => {
        const varReplace = pageData.elements[index - 1];

        pageData.elements[index - 1] = pageData.elements[index];
        pageData.elements[index] = varReplace;

        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const movePageSectionDown = (index: number) => {
        const varReplace = pageData.elements[index + 1];

        pageData.elements[index + 1] = pageData.elements[index];
        pageData.elements[index] = varReplace;

        setPageData(deepCopy<ICmsPage>(pageData));
    };

    /*
    Handle generic change
   */
    const handleTextChange = (event: any) => {
        const newData = {
            ...pageData.data,
            [event.target.name]: event.target.value,
        };
        setPageData({ ...pageData, data: newData });
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...pageData.data,
            [event.target.name]: event.target.checked,
        };
        setPageData({ ...pageData, data: newData });
    };

    const changeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newData = {
            ...pageData.data,
            project: { id: event.target.value as number },
        };

        setPageData({ ...pageData, data: newData });
    };

    const changeTeam = (index: number, subIndex: number, value: string) => {
        // @ts-ignore
        pageData.elements[index].content[subIndex].id = value;
        setPageData(deepCopy(pageData));
    };

    /*   const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...pageData, [attrKey]: image };
        setPageData(newData);
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...pageData, [name]: markup };
        setPageData(newData);
    }; */

    /*  const handleDateChange = (date: Date, attrKey: string) => {
        setPageData({
            ...pageData,
            [attrKey]: moment(date).format(Format.dateTimeEn),
        });
    }; */

    const addNewPageElement = (
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
        const type = "" + event.currentTarget.dataset.type;

        const newCol: ICmsPageElement = deepCopy(
            initialColValue["" + event.currentTarget.dataset.type]
        );

        if (type === CmsPageElementTypeEnum.HTML_IMAGE_TEXT) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[0] = { ...InitialImageRef, key: newRef };
        } else if (type === CmsPageElementTypeEnum.HTML_TEXT_IMAGE) {
            const newRef = uuid();
            images[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };
            setImages(deepCopy(images));
            // @ts-ignore
            newCol.content[1] = { ...InitialImageRef, key: newRef };
        } else if (type === CmsPageElementTypeEnum.DOWNLOAD_LIST) {
            const newRef = uuid();
            //uploads[newRef] = { ...InitialDocumentData, key: newRef, type: 1 };

            setUploads(
                deepCopy({
                    ...uploads,
                    [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
                })
            );
            // @ts-ignore
            newCol.content[0].push({ ...InitialDocumentRef, key: newRef });
        }

        pageData.elements.push(newCol);
        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const addAdditionalFile = (index: number) => {
        const newRef = uuid();
        setUploads(
            deepCopy({
                ...uploads,
                [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
            })
        );

        // @ts-ignore
        pageData.elements[index].content[0].push({
            ...InitialDocumentRef,
            key: newRef,
        });
        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const addAdditionalTeam = (index: number) => {
        // @ts-ignore
        pageData.elements[index].content.push({
            ...InitialTeamRef,
        });
        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const handleContentTextChange = (
        event: any,
        index: number,
        index2?: number
    ) => {
        if (index2 !== undefined) {
            // @ts-ignore
            pageData.elements[index].content[index2] = event.target.value;
        } else {
            pageData.elements[index].content = event.target.value;
        }

        setPageData(deepCopy<ICmsPage>(pageData));
    };

    const handleContentWysiwygChange = (
        editorState: any,
        index: number,
        index2?: number
    ) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );

        if (index2 !== undefined) {
            // @ts-ignore
            pageData.elements[index].content[index2] =
                draftToHtml(rawContentState);
        } else {
            pageData.elements[index].content = draftToHtml(rawContentState);
        }

        setPageData(deepCopy<ICmsPage>(pageData));
    };

    /**
     * upload event for the document
     */
    const handleImageChange = (image: IDocument) => {
        setImages({ ...images, ["" + image.key]: image });
    };

    const handleUploadChange = (upload: Partial<IDocument>) => {
        setUploads({ ...uploads, ["" + upload.key]: upload as IDocument });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    const headerSection = (index: number, additionalButtons?: any) => {
        return (
            <Toolbar disableGutters={true}>
                {index > 0 && (
                    <IconButton
                        onClick={() => movePageSectionUp(index)}
                        color="primary"
                    >
                        <ArrowDropUpIcon />
                    </IconButton>
                )}

                {pageData.elements.length - 1 !== index && (
                    <IconButton
                        onClick={() => movePageSectionDown(index)}
                        color="primary"
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                )}

                {additionalButtons !== undefined && additionalButtons}

                <IconButton onClick={() => onDeleteRow(index)} color="primary">
                    <DeleteIcon />
                </IconButton>
            </Toolbar>
        );
    };

    const header_1 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                {headerSection(index)}
                <TextField
                    variant="outlined"
                    label="Header 1"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const header_2 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                {headerSection(index)}
                <TextField
                    variant="outlined"
                    label="Header 2"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const header_3 = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                {headerSection(index)}
                <TextField
                    variant="outlined"
                    label="Header 3"
                    value={element.content ? element.content : ""}
                    onChange={(event: any) => {
                        handleContentTextChange(event, index);
                    }}
                    fullWidth
                    required={true}
                />
            </Grid>
        );
    };

    const html = (index: number, element: ICmsPageElement) => {
        return (
            <Grid item xs={12} key={index}>
                {headerSection(index)}
                <TextEdit
                    handleChange={(editorState) => {
                        handleContentWysiwygChange(editorState, index);
                    }}
                    title="Seiten Inhalt"
                    defaultValue={element.content ? element.content : ""}
                />
            </Grid>
        );
    };

    const html_text_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[];

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_text_text_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[];

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 2);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[2] ? content[2] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_text_image = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[1] as IDocumentRef;

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index)}
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 0);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[0] ? content[0] : ""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={images[imgRef.key] as IDocument}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const html_image_text = (index: number, element: ICmsPageElement) => {
        const content = element.content as string[] | IDocumentRef[];

        const imgRef: IDocumentRef = content[0] as IDocumentRef;

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index)}
                </Grid>

                <Grid item xs={6}>
                    <ImageUpload
                        onChange={handleImageChange}
                        docData={images[imgRef.key] as IDocument}
                        name={imgRef.key}
                        label={"Bild"}
                        defaultFilename="Bild.jpg"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextEdit
                        handleChange={(editorState) => {
                            handleContentWysiwygChange(editorState, index, 1);
                        }}
                        title="Seiten Inhalt"
                        defaultValue={content[1] ? content[1] : ""}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const download_list = (index: number, element: ICmsPageElement) => {
        // @ts-ignore
        const content = element.content[0] as IDocumentRef[];

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index, [
                        <IconButton
                            key="add-file"
                            onClick={() => addAdditionalFile(index)}
                            color="primary"
                        >
                            <AddIcon />
                        </IconButton>,
                    ])}
                </Grid>

                {content.map((docRef, fileIndex) => {
                    return (
                        <Grid item xs={6} key={fileIndex}>
                            <FileUpload
                                onChange={handleUploadChange}
                                docData={uploads[docRef.key] as IDocument}
                                name={fileIndex}
                                label={"Datei"}
                                defaultFilename="data.dat"
                            />
                        </Grid>
                    );
                })}
            </React.Fragment>
        );
    };

    const team_list = (index: number, element: ICmsPageElement) => {
        // @ts-ignore
        const content = element.content as ITeamRef[];

        const teamListSorted = Object.values(teamList).sort((left, right) => {
            if (
                "" + left.displayTitle + "-" + left.name >
                "" + right.displayTitle + "-" + right.name
            ) {
                return 1;
            } else if (
                "" + left.displayTitle + "-" + left.name ===
                "" + right.displayTitle + "-" + right.name
            ) {
                return 0;
            } else {
                return -1;
            }
        });

        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    {headerSection(index, [
                        <IconButton
                            key="add-file"
                            onClick={() => addAdditionalTeam(index)}
                            color="primary"
                        >
                            <AddIcon />
                        </IconButton>,
                    ])}
                </Grid>

                {content.map((teamRef, fileIndex) => {
                    // @ts-ignore
                    return (
                        <Grid item xs={6} key={fileIndex}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel
                                    id={"team-select-label" + teamRef.id}
                                >
                                    Teammitglied
                                </InputLabel>
                                <Select
                                    labelId={"team-select-label" + teamRef.id}
                                    value={"" + teamRef.id}
                                    onChange={(event) => {
                                        changeTeam(
                                            index,
                                            fileIndex,
                                            "" + event.target.value
                                        );
                                    }}
                                    label="Teammitglied"
                                >
                                    <MenuItem key="empty" value="">
                                        <em>Teammitglied wählen</em>
                                    </MenuItem>
                                    {teamListSorted.map(
                                        (team: ITeam, teamIndex: number) => {
                                            return (
                                                <MenuItem
                                                    key={"team-" + teamIndex}
                                                    value={"" + team.id}
                                                >
                                                    {team.displayTitle}{" "}
                                                    {team.name}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            {teamRef.id !== "" && teamList[teamRef.id] && (
                                <TeamTile
                                    participant={teamList[teamRef.id] as ITeam}
                                    fullWidth={true}
                                />
                            )}
                        </Grid>
                    );
                })}
            </React.Fragment>
        );
    };

    const handleEvents: any = {
        header_1,
        header_2,
        header_3,
        html,
        html_text_text,
        html_text_text_text,
        html_text_image,
        html_image_text,
        download_list,
        team_list,
    };

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    data-url={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Link
                    color="inherit"
                    href={"/" + mandantKey + RoutesEnum.ADMIN_CMS_PAGE_LIST}
                    data-url={"/" + mandantKey + RoutesEnum.ADMIN_CMS_PAGE_LIST}
                    onClick={handleBread}
                >
                    Liste der CMS Seiten
                </Link>
                <Typography color="textPrimary">
                    {isEditForm && <>Seite: {pageData.data.title}</>}
                    {!isEditForm && <>Eine neue Seite erstellen</>}
                </Typography>
            </Breadcrumbs>

            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button onClick={handleSave} color={"primary"}>
                        Seite speichern
                    </Button>
                </Grid>
                <Grid item xs={8} className={"gap-bottom"}>
                    <TextField
                        variant="outlined"
                        label="Seiten Titel"
                        name={"title"}
                        value={pageData.data.title ? pageData.data.title : ""}
                        onChange={handleTextChange}
                        fullWidth
                        required={true}
                    />
                    <TextField
                        variant="outlined"
                        label="Url Schlüssel"
                        name={"urlKey"}
                        value={pageData.data.urlKey ? pageData.data.urlKey : ""}
                        onChange={handleTextChange}
                        fullWidth
                        required={true}
                    />

                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel id="project-select-label">
                            Projekt
                        </InputLabel>
                        <Select
                            labelId="project-select-label"
                            value={
                                pageData.data?.project?.id
                                    ? pageData.data?.project?.id
                                    : ""
                            }
                            onChange={changeProject}
                            label="Projekt"
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte Projekt wählen</em>
                            </MenuItem>
                            {projectList.map(
                                (project: ISelectOption, index: number) => {
                                    return (
                                        <MenuItem
                                            key={"project-" + index}
                                            value={project.id}
                                        >
                                            {project.label}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={pageData.data.flagPublished}
                                    onChange={handleCheckboxChange}
                                    name="flagPublished"
                                />
                            }
                            label="Seite ist publiziert"
                        />
                    </FormGroup>

                    <Divider />

                    <Grid container spacing={2}>
                        {pageData.elements.map(
                            (element: ICmsPageElement, index: number) => {
                                if (handleEvents[element.type]) {
                                    return handleEvents[element.type](
                                        index,
                                        element
                                    );
                                } else {
                                    return <div key={index} />;
                                }
                            }
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <div className="sticky-menu">
                        <Typography variant="h2" className="gap-bottom">
                            Seiten Elemente
                        </Typography>

                        <ButtonGroup
                            fullWidth={true}
                            variant="outlined"
                            orientation="vertical"
                            color="primary"
                            aria-label="vertical outlined primary button group"
                        >
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.HEADER_1}
                            >
                                Header 1
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.HEADER_2}
                            >
                                Header 2
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.HEADER_3}
                            >
                                Header 3
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.HTML}
                            >
                                Text Voll
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={
                                    CmsPageElementTypeEnum.HTML_TEXT_TEXT
                                }
                            >
                                Text | Text
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={
                                    CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT
                                }
                            >
                                Text | Text | Text
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={
                                    CmsPageElementTypeEnum.HTML_TEXT_IMAGE
                                }
                            >
                                Text | Bild
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={
                                    CmsPageElementTypeEnum.HTML_IMAGE_TEXT
                                }
                            >
                                Bild | Text
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.DOWNLOAD_LIST}
                            >
                                Download Liste
                            </Button>
                            <Button
                                onClick={addNewPageElement}
                                data-type={CmsPageElementTypeEnum.TEAM_LIST}
                            >
                                Team Liste
                            </Button>
                        </ButtonGroup>
                    </div>
                </Grid>
            </Grid>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </Paper>
    );
};
export default CmsPageForm;
