import isEmail from "validator/lib/isEmail";

import IElseVote from "../../types/IElseVote";
import IPollCampaignList from "../../types/IPollCampaignList";
import IPollPoint from "../../types/IPollPoint";

export interface IErrorState {
    hasNoError: boolean;
    emailIsValid: boolean;
    nameIsValid: boolean;
}
interface IVoteAllowed {
    hasError: boolean;
    flagName: boolean;
    flagEmail: boolean;
}

export const userAllowedToVote = (
    voteCampaign: IPollCampaignList
): IVoteAllowed => {
    const voteAllowed: IVoteAllowed = {
        hasError: false,
        flagName: false,
        flagEmail: false,
    };
    if (voteCampaign.flagName && voteCampaign.flagEmail) {
        if (voteCampaign.flagName > 0) {
            voteAllowed.hasError = true;
            voteAllowed.flagName = true;
        }
        if (voteCampaign.flagEmail > 0) {
            voteAllowed.hasError = true;
            voteAllowed.flagEmail = true;
        }
    }

    return voteAllowed;
};
export const calculateSum = (results: any) => {
    let sum = 0;
    for (const el in results) {
        // eslint-disable-next-line no-prototype-builtins
        if (results.hasOwnProperty(el)) {
            sum += parseFloat(results[el]);
        }
    }
    return sum.toFixed(2);
};
export const calculatePointPercentage = (
    poll: IPollCampaignList,
    point: IPollPoint,
    sum: number
) => {
    const count = poll.results ? poll.results[point.id] : 0;

    if (!count) {
        return "0";
    }

    return ((count * 100) / sum).toFixed(0);
};

export const validateMailAndName = (
    campaignVote: IPollCampaignList,
    elseVote: IElseVote
): IErrorState => {
    const result: IErrorState = {
        hasNoError: true,
        emailIsValid: true,
        nameIsValid: true,
    };
    if (campaignVote.flagEmail && campaignVote.flagEmail > 1) {
        if (!isEmail(elseVote.emailOfPerson ? elseVote.emailOfPerson : "")) {
            result.hasNoError = false;
            result.emailIsValid = false;
        }
    }
    if (campaignVote.flagName && campaignVote.flagName > 1) {
        if (elseVote.nameOfPerson === "") {
            result.hasNoError = false;
            result.nameIsValid = false;
        }
    }

    return result;
};
