import {
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IChangePassword from "../../../types/IChangePassword";
import IMandant from "../../../types/IMandant";
import { postChangePassword } from "../../../xhr/UserRequests";

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {}

interface IChangePasswordParams extends IMapStateProps, IOwnProps {}

const ChangePassword = (props: IChangePasswordParams) => {
    const history = useHistory();
    const { mandant } = props;
    //const { mandantKey }: { mandantKey: string } = useParams();
    const [formData, setFormData] = React.useState<IChangePassword>({
        oldpassword: "",
        password: "",
        passwordTest: "",
    });
    const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");

    const handleChange = (event: any) => {
        const newValue = {
            ...formData,
            [event.target.name]: event.target.value,
        };

        setFormData(newValue);
    };

    const handleChangePassword = () => {
        postChangePassword(mandant.key, formData)
            .then(() => {
                setErrorResponse("");
                setSnackBarOpen(true);
            })
            .catch(() => {
                setErrorResponse("Fehler beim ändern das Passworts");
                setSnackBarOpen(true);
            });
    };

    const closeSnackbar = () => {
        setSnackBarOpen(false);
    };

    if (!mandant.id) {
        return <></>;
    }
    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={"/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD}
                    data-url={"/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Passwort ändern</Typography>
            </Breadcrumbs>

            <div className={"gap-top"}>
                <Typography variant="subtitle1">
                    Hier können Sie einfach ihr Passwort ändern.
                </Typography>
            </div>

            <Grid container alignItems="flex-start">
                {snackBarOpen && (
                    <Grid xs={12} md={12} lg={12}>
                        {errorResponse.length && (
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={closeSnackbar}
                                severity="error"
                            >
                                {errorResponse}
                            </MuiAlert>
                        )}

                        {!errorResponse.length && (
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={closeSnackbar}
                                severity="success"
                            >
                                Ihr Passwort wurde erfolgreich geändert.
                            </MuiAlert>
                        )}
                    </Grid>
                )}

                <Grid item xs={12} md={8} lg={6}>
                    <Paper variant="outlined" className="main-paper gap-top">
                        <TextField
                            name="oldpassword"
                            label="Das alte Passwort"
                            type={"password"}
                            fullWidth
                            onChange={handleChange}
                            value={formData.oldpassword}
                            variant="outlined"
                        />
                        <TextField
                            name="password"
                            label="Das neue Passwort"
                            type={"password"}
                            fullWidth
                            onChange={handleChange}
                            value={formData.password}
                            variant="outlined"
                        />
                        <TextField
                            name="passwordTest"
                            label="Zur Sicherheit das neue Passwort wiederholen"
                            type={"password"}
                            value={formData.passwordTest}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        color="primary"
                        onClick={handleChangePassword}
                        startIcon={<VpnKeyIcon />}
                    >
                        Das neue Passwort jetzt verwenden
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(ChangePassword);
