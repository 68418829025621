import axios from "axios";

import { API_URL_STATIC } from "../config/config";
import {
    ADMIN_CHANGE_PASSWORD,
    ADMIN_LOGOUT_END_POINT,
    ADMIN_TOKEN_REFRESH,
    ADMIN_USER,
} from "../enums/Endpoints";
import { IPasswordResetRequest } from "../masks/public/forgotPassword/ForgotPassword";
import { IResetPassword } from "../masks/public/resetPassword/ResetPassword";
import ICreateUser from "../types/ICreateUser";
import IUserIsDuplicate from "../types/IUserIsDuplicate";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISyncUser from "./interface/ISyncUser";
import ISyncUserList from "./interface/ISyncUserList";

const loadUserListRequest = (mandant: string): Promise<any> => {
    return getData<ISyncUserList[]>(ADMIN_USER, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadSingleUserRequest = (
    mandant: string,
    userId: number
): Promise<any> => {
    return getData<ISyncUser>(ADMIN_USER + userId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const saveUserRequest = (
    mandant: string,
    userData: any,
    userId: number
): Promise<any> => {
    return putData<ISyncUser, any>(ADMIN_USER, userId, userData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const createUserPortalRequest = (
    mandant: string,
    userData: ICreateUser
): Promise<any> => {
    return postData<ICreateUser, ICreateUser>(ADMIN_USER, userData, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const updateUserPortalRequest = (
    mandant: string,
    userData: ICreateUser
): Promise<any> => {
    return putData<ICreateUser, ICreateUser>(ADMIN_USER, "self", userData, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const checkUserDuplicateNicknameRequest = (
    mandantKey: string,
    nickName: string
): Promise<any> => {
    return getData<IUserIsDuplicate>(
        ADMIN_USER + "check_duplicate_name/" + nickName,
        {
            mandant: mandantKey,
            isPublic: true,
            withLang: true,
        }
    );
};

const checkUserDuplicateEmailRequest = (
    mandantKey: string,
    email: string
): Promise<any> => {
    return getData<IUserIsDuplicate>(
        ADMIN_USER + "check_duplicate_email/" + email,
        {
            mandant: mandantKey,
            isPublic: true,
            withLang: true,
        }
    );
};

const demandResetPasswordRequest = (
    mandant: string,
    resetPwd: IPasswordResetRequest
): Promise<any> => {
    return postData<IPasswordResetRequest, any>(
        ADMIN_USER + "reset_pwd_request",
        resetPwd,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

const resetPasswordRequest = (
    mandant: string,
    resetPwd: IResetPassword
): Promise<any> => {
    return postData<IResetPassword, any>(ADMIN_USER + "reset_pwd", resetPwd, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const createUserRequest = (mandant: string, userData: any): Promise<any> => {
    return postData<ISyncUser, any>(ADMIN_USER, userData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const deleteUserRequest = (mandant: string, userId: number): Promise<any> => {
    return deleteData<ISyncUser>(ADMIN_USER, userId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const tokenRefreshRequest = (mandant: string, bodyData: any): Promise<any> => {
    return postData<any, any>(ADMIN_TOKEN_REFRESH, bodyData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
const loginRequest = (bodyData: any, mandant: string): Promise<any> => {
    return axios.post(
        `${API_URL_STATIC}api/auth/${mandant}/de/login?mandant=${mandant}`,
        bodyData
    );
};

const logoutRequest = (): Promise<any> => {
    return postData<any, any>(ADMIN_LOGOUT_END_POINT, {});
};

const postChangePassword = (mandant: string, bodyData: any): Promise<any> => {
    return postData<any, any>(ADMIN_CHANGE_PASSWORD, bodyData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export {
    loginRequest,
    logoutRequest,
    loadUserListRequest,
    postChangePassword,
    loadSingleUserRequest,
    deleteUserRequest,
    saveUserRequest,
    createUserRequest,
    createUserPortalRequest,
    updateUserPortalRequest,
    tokenRefreshRequest,
    checkUserDuplicateNicknameRequest,
    checkUserDuplicateEmailRequest,
    demandResetPasswordRequest,
    resetPasswordRequest,
};
