import "./wysiwyg.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Paper, Typography } from "@material-ui/core";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";

interface ITextEditProps {
    defaultValue: any;
    title: string;
    handleChange: (editorState: any) => void;
    customTools?: boolean;
    gapBottom?: boolean;
}

const TextEdit = (props: ITextEditProps) => {
    const { defaultValue, title, handleChange } = props;
    const [state, setState] = React.useState({
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML("" + defaultValue).contentBlocks,
                convertFromHTML("" + defaultValue).entityMap
            )
        ),
    });

    const onEditorStateChange = (editorState: EditorState) => {
        handleChange(editorState);
        setState({
            editorState,
        });
    };

    const paperClassName = props.gapBottom
        ? "main-paper gap-bottom"
        : "main-paper";

    // eslint-disable-next-line eqeqeq
    if (title == null) {
        return <></>;
    }

    return (
        <Paper variant="outlined" className={paperClassName}>
            <Typography variant="h3" className="main-header">
                {title}
            </Typography>
            <Editor
                editorState={state.editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    inline: {
                        options: ["bold", "italic", "underline"],
                    },
                    list: {
                        options: ["unordered", "ordered"],
                    },
                    options: ["inline", "list"],
                }}
            />
        </Paper>
    );
};
export default TextEdit;
