import "./Accordion.css";

import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

import { ITab } from "../tabContainer/TabContainer";

interface IAccordionProps {
    tabs: ITab[];
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    })
);

export default function AccordionUi(props: IAccordionProps) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<number | false>(false);

    const scrollToAnchor = (anchor: string) => {
        const element = document.getElementById(anchor);
        // eslint-disable-next-line no-unused-expressions
        element?.scrollIntoView(true);
    };

    const handleChange =
        (id: string, panel: number) =>
        (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            scrollToAnchor(id);
        };
    React.useEffect(() => {
        setExpanded(0); /*
        const node = document.getElementById(tabs[0].label);
        node?.scrollIntoView(true); */
    }, [props]);

    const { tabs } = props;
    return (
        <div className={classes.root}>
            {tabs.map((tab, index) => {
                if (!tab.display) {
                    return <></>;
                }

                return (
                    <Accordion
                        id={tab.id}
                        key={tab.id + index}
                        expanded={expanded === index}
                        onChange={handleChange(tab.id, index)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>
                                {tab.label}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {expanded === index && tab.content}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
}
