import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useParams } from "react-router-dom";

import PublicMap from "../../../components/olMap/publicMap/PublicMap";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import IProjectList from "../../../types/IProjectList";
import { loadPublicEventList } from "../../../xhr/EventRequests";
import { loadPublicProjectListRequest } from "../../../xhr/ProjectRequests";

type IMapStateProps = {
    mandant: IMandant;
};
type IOwnProps = {
    history: any;
};

type IPublicMapParams = IMapStateProps & IOwnProps;

function AllMap(props: IPublicMapParams) {
    const { history, mandant } = props;

    const { mandantKey }: { mandantKey: string } = useParams();
    const [projectList, setProjectList] = React.useState<IProjectList[]>([]);

    const [eventList, setEventList] = React.useState<IEventList[]>([]);

    React.useEffect(() => {
        const loadEvent = () => {
            loadPublicEventList(mandantKey).then((result: any) => {
                setEventList(result.data);
                loadPublicProjectListRequest(mandantKey).then(
                    (response: any) => {
                        setProjectList(response.data);
                        return Promise.resolve();
                    }
                );
            });
        };
        loadEvent();
    }, [mandantKey]);

    return (
        <div className="portal-section">
            <Helmet>
                <title>Übersichtskarte Projekte und Veranstaltungen</title>
            </Helmet>
            <Header mandant={mandant} history={history} />

            <Grid container>
                {projectList.length > 0 && (
                    <PublicMap
                        events={eventList}
                        projects={projectList}
                        mandant={mandantKey}
                    />
                )}
            </Grid>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
}
/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(AllMap);
