import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { loginRequest } from "../../../xhr/UserRequests";
import IApplicationRootState from "../../StoreTypes";
import {
    loginError,
    loginPending,
    loginSuccess,
} from "../userAction/UserAction";

const LoginAction = (
    email: string,
    password: string,
    mandantKey: string
): ThunkAction<void, IApplicationRootState, {}, Action> => {
    return async (
        dispatch: ThunkDispatch<{}, {}, Action>,
        getState: () => IApplicationRootState
    ) => {
        dispatch(loginPending());
        try {
            dispatch(loginError(""));
            // since mandant is not set for the moment we can get it using getState from redux-thunk
            const mandant = getState().mandant.mandant.key;
            const userData = await loginRequest(
                { username: email, password },
                mandant
            );
            //can make status check here
            localStorage.setItem(
                "jwt-" + mandantKey,
                userData.data.loginToken.token
            );
            localStorage.setItem(
                "expiresAt-" + mandantKey,
                userData.data.loginToken.expiresAt
            );
            dispatch(loginSuccess(userData.data));
        } catch (err) {
            dispatch(loginError("Failed to login"));
        }
    };
};

export default LoginAction;
