import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";

export interface IMandantDataTabProps {
    mandantData: IMandant;
    onChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
}

const MandantDataTab = (props: IMandantDataTabProps) => {
    const { onImageChange, mandantData, onChange } = props;

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper gap-top">
                        <Typography
                            variant="h3"
                            className="main-header gap-bottom"
                        >
                            Portal Daten
                        </Typography>
                        <TextField
                            name="name"
                            label="Name Mandant"
                            fullWidth
                            value={mandantData.name}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="key"
                            label="Url Key"
                            fullWidth
                            value={mandantData.key}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="contactEmail"
                            label="Standard Kontakt E-Mail"
                            fullWidth
                            value={mandantData.contactEmail}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="notifyEmailsElse"
                            label="Notify E-Mails"
                            fullWidth
                            multiline
                            value={mandantData.notifyEmailsElse}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="domain"
                            label="Adresse Portal"
                            fullWidth
                            value={mandantData.domain}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="urlWebsite"
                            label="Webseite Mandant"
                            fullWidth
                            value={mandantData.urlWebsite}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="slogan"
                            label="Slogan"
                            fullWidth
                            value={mandantData.slogan}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper gap-top">
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.logoImage}
                            name={"logoImage"}
                            label={"Logo"}
                            defaultFilename="Logo.jpg"
                        />
                        {mandantData.favIcon && (
                            <ImageUpload
                                onChange={onImageChange}
                                docData={mandantData.favIcon}
                                name={"favIcon"}
                                label={"Fav Icon"}
                                defaultFilename="FavIcon.jpg"
                            />
                        )}
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.headerImage}
                            name={"headerImage"}
                            label={"Standard Header Bild"}
                            defaultFilename="StandardHeaderBild.jpg"
                        />
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.defaultHeaderImage}
                            name={"defaultHeaderImage"}
                            label={"Fallback Header Bild"}
                            defaultFilename="FallbackHeaderBild.jpg"
                        />
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.defaultMenuImage}
                            name={"defaultMenuImage"}
                            label={"Fallback Menu Bild"}
                            defaultFilename="FallbackMenuBild.jpg"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MandantDataTab;
