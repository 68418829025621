import { Grid, Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import PersonTile from "../../../../components/personTile/PersonTile";
import IProjectFull from "../../../../types/IProjectFull";
import ITeam from "../../../../types/ITeam";

interface IProjectTeamTabProps {
    project: IProjectFull;
}

function ProjectTeamTab(props: IProjectTeamTabProps) {
    const { project } = props;

    const hasAvatar: boolean =
        project.team.filter((team: ITeam) => {
            // eslint-disable-next-line eqeqeq
            return team.image?.url != null;
        }).length > 0;

    return (
        <>
            {project.teamText && (
                <>
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Über das Team
                    </Typography>
                    <HtmlContent
                        className="content-block"
                        content={project.teamText}
                    />
                </>
            )}

            {project.team.length && (
                <>
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Teammitglieder
                    </Typography>
                    <Grid container spacing={2} direction="row">
                        {project.team.map((member: ITeam, index: number) => {
                            return (
                                <Grid
                                    key={member.displayTitle + index}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                >
                                    <PersonTile
                                        hasAvatar={hasAvatar}
                                        key={index}
                                        {...member}
                                        minData={false}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </>
    );
}

export default ProjectTeamTab;
