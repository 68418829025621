import "./CmsPage.css";

import { Grid } from "@material-ui/core";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ImageHeader from "../../../components/layout/ImageHeader";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";
import CmsPageLoader from "./components/CmsPageLoader";

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {}

interface ICmsPageProps extends IMapStateProps, IOwnProps {}

const CmsPage = (props: ICmsPageProps) => {
    const history = useHistory();
    const { mandant } = props;
    const { mandantKey, pageKey }: { mandantKey: string; pageKey: string } =
        useParams();

    return (
        <div className="portal-section">
            <Grid container>
                <Header mandant={mandant} history={history} />
            </Grid>
            <ImageHeader image={mandant.headerImage} />

            <div className="portal-section-body-position">
                <div className="App-main">
                    <CmsPageLoader mandantKey={mandantKey} pageKey={pageKey} />
                </div>
            </div>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(CmsPage);
