import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React from "react";

import HtmlContent from "../../cms/HtmlContent";

export interface ISimpleEntryParams {
    id: number;
    title: string;
    subTitle?: string;
    description: string | any;
    onLinkClick?: (event: any) => void;
    fromPublicMask?: boolean;
    onDownloadClick?: (event: any) => void;
}

const SimpleEntry = (props: ISimpleEntryParams) => {
    return (
        <Card
            data-id={props.id}
            onClick={(event) => {
                if (props.onLinkClick) {
                    props.onLinkClick(event);
                }
            }}
            square={true}
            variant={"outlined"}
            className="App-sidebar--list-entry"
        >
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h3" component="h3">
                        {props.title}
                    </Typography>
                    <div style={{ display: "flex" }}>
                        {props.subTitle && (
                            <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="h3"
                            >
                                {props.subTitle}
                            </Typography>
                        )}
                        {props.fromPublicMask && (
                            <CalendarTodayIcon
                                fontSize="small"
                                onClick={props.onDownloadClick}
                            />
                        )}
                    </div>

                    {typeof props.description === "string" && (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            align="left"
                        >
                            <HtmlContent content={props.description} />
                        </Typography>
                    )}
                    {typeof props.description !== "string" && props.description}
                </CardContent>
            </CardActionArea>
            {props.onLinkClick ? (
                <CardActions>
                    <Button
                        data-id={props.id}
                        size="small"
                        color="primary"
                        onClick={props.onLinkClick}
                    >
                        Mehr lesen...
                    </Button>
                </CardActions>
            ) : (
                <></>
            )}
        </Card>
    );
};

export default SimpleEntry;
