import IAppointmentBulk from "../appointment/IAppointmentBulk";

const InitialAppointmentBulkData: IAppointmentBulk = {
    startTime: { date: "" },
    endTime: { date: "" },
    startDate: { date: "" },
    endDate: { date: "" },
    period: 0,
    rooms: [],
    isGroup: false,
};

export default InitialAppointmentBulkData;
