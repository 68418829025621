import {
    ADMIN_EVENT_PARTICIPANT,
    ADMIN_PROJECT_TEAM,
} from "../enums/Endpoints";
import ITeam from "../types/ITeam";
import { deleteData, postData, putData } from "../utils/httpRequest/request";
/* Create project Participant */
const createProjectTeamRequest = (mandant: string, team: any): Promise<any> => {
    return postData<any, ITeam>(ADMIN_PROJECT_TEAM, team, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/* update project participant */
const updateProjectTeamRequest = (
    mandant: string,
    team: ITeam
): Promise<any> => {
    return putData<any, ITeam>(
        ADMIN_PROJECT_TEAM,
        team.id ? team.id : 0,
        team,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

/* delete  project participant */
const deleteProjectTeamRequest = (
    id: string,
    mandant: string
): Promise<any> => {
    return deleteData<ITeam>(ADMIN_PROJECT_TEAM, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const sortProjectTeamRequest = (
    mandant: string,
    members: number[],
    projectId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT_TEAM,
        projectId + "/sort/",
        members,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

/* create event participant */
const createEventParticipantRequest = (
    mandant: string,
    team: any
): Promise<any> => {
    return postData<any, ITeam>(ADMIN_EVENT_PARTICIPANT, team, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/* update event participant */
const updateEventParticipantRequest = (
    mandant: string,
    team: ITeam
): Promise<any> => {
    return putData<any, ITeam>(
        ADMIN_EVENT_PARTICIPANT,
        team.id ? team.id : 0,
        team,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
/* Delete Event Participant */
const deleteEventParticipantRequest = (
    id: string,
    mandant: string
): Promise<any> => {
    return deleteData<ITeam>(ADMIN_EVENT_PARTICIPANT, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const sortEventParticipantRequest = (
    mandant: string,
    participants: string[],
    eventId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_EVENT_PARTICIPANT,
        eventId + "/sort/",
        participants,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

export {
    createProjectTeamRequest,
    updateProjectTeamRequest,
    deleteProjectTeamRequest,
    sortProjectTeamRequest,
    createEventParticipantRequest,
    updateEventParticipantRequest,
    deleteEventParticipantRequest,
    sortEventParticipantRequest,
};
