import IProjectFull from "../../../types/IProjectFull";

export type IProjectValidationState = {
    hasError: boolean;
    title?: boolean;
    urlKey?: boolean;
    shortDesc?: boolean;
    theStart?: boolean;
    theEnd?: boolean;
    imageMenu?: boolean;
    imageHeader?: boolean;
};

export const requiredProjectDataFilled = (
    project: IProjectFull
): IProjectValidationState => {
    const validationState: IProjectValidationState = {
        hasError: false,
    };

    if (project.title.length < 1) {
        validationState.title = true;
        validationState.hasError = true;
    }

    if (project.urlKey.length < 1) {
        validationState.urlKey = true;
        validationState.hasError = true;
    }

    if (project.shortDesc.length < 1) {
        validationState.shortDesc = true;
        validationState.hasError = true;
    }

    if (isNaN(Date.parse(project.theStart))) {
        validationState.theStart = true;
        validationState.hasError = true;
    }

    if (
        project.imageMenu === null ||
        (!project.imageMenu.data && !project.imageMenu.url)
    ) {
        validationState.imageMenu = true;
        validationState.hasError = true;
    }

    if (
        project.imageHeader === null ||
        (!project.imageHeader.data && !project.imageHeader.url)
    ) {
        validationState.imageHeader = true;
        validationState.hasError = true;
    }

    return validationState;
};
