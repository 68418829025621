import React from "react";
import { useHistory } from "react-router-dom";

import CookiesConsent from "../../../components/cookiesConsent/CookiesConsent";

const MandantOverview = () => {
    const history = useHistory();

    history.push(`/uhingen/`);

    return (
        <div className="portal-section">
            <CookiesConsent />
            <div className="App-main">
                <div className="App-cms-container">
                    Hier finden Sie eine Liste von Projekten
                </div>
            </div>
        </div>
    );
};

export default MandantOverview;
