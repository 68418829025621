import IMandant from "../types/IMandant";
import setCssVariable from "./setCssVariable";

const setMandantThemeByServer = (mandant: IMandant) => {
    /*
    primaryColor?: string;
    secondaryColor?: string;
    backgroundColorFooter1?: string;
    backgroundColorFooter2?: string;
    buttonBackground?: string;
    buttonBorder?: string;
    buttonTextColor?: string;
    colorLink?: string;
    colorLinkHover?: string;
    contactFormTextAfter?: string;
    contactFormTextBefore?: string;
    footerLeftText?: string;
    footerLeftTitel?: string;
    gap?: string;
    headerH1Color?: string;
    headerH1FontFamily?: string;
    headerH1Size?: string;
    headerH1Weight?: string;
    headerH2Color?: string;
    headerH2H3FontFamily?: string;
    headerH2Size?: string;
    headerH2Weight?: string;
    headerH3Color?: string;
    headerH3Size?: string;
    headerH3Weight?: string;

    linkColorFooter1?: string;
    linkColorFooter1Hover?: string;
    linkColorFooter2?: string;
    linkColorFooter2Hover?: string;

    textColor?: string;
    textColorFooter1?: string;
    textColorFooter2?: string;
    textColorSmaller?: string;
    textFontFamily?: string;
    textFontSize?: string;
    textFontSizeSmaller?: string;
    textFontWeight?: string;
    */

    setCssVariable("--primary-color", "" + mandant.primaryColor);
    setCssVariable("--scondary-color", "" + mandant.secondaryColor);
    setCssVariable("--text-color", "" + mandant.textColor);
    setCssVariable("--text-font-size", "" + mandant.textFontSize);
    setCssVariable(
        "--text-font-size-smaller",
        "" + mandant.textFontSizeSmaller
    );
    setCssVariable("--text-font-family", "" + mandant.textFontFamily);
    setCssVariable("--header-h1-color", "" + mandant.headerH1Color);
    setCssVariable("--header-h1-size", "" + mandant.headerH1Size);
    setCssVariable("--header-h2-color", "" + mandant.headerH2Color);
    setCssVariable("--header-h2-size", "" + mandant.headerH2Size);
    setCssVariable("--header-h3-color", "" + mandant.headerH3Color);
    setCssVariable("--header-h3-size", "" + mandant.headerH3Size);
    setCssVariable("--header-font-family", "" + mandant.headerH2H3FontFamily);
    setCssVariable("--button-background", "" + mandant.buttonBackground);
    setCssVariable("--button-text-color", "" + mandant.buttonTextColor);
    setCssVariable("--button-border", "" + mandant.buttonBorder);
    setCssVariable(
        "--footer-background-color",
        "" + mandant.backgroundColorFooter1
    );
    setCssVariable(
        "--footer2-background-color",
        "" + mandant.backgroundColorFooter2
    );
    setCssVariable("--color-link", "" + mandant.colorLink);
    setCssVariable("--color-link-hover", "" + mandant.colorLinkHover);
    setCssVariable("--link-color-footer1", "" + mandant.linkColorFooter1);
    setCssVariable("--link-color-footer2", "" + mandant.linkColorFooter2);
    setCssVariable("--text-color-footer1", "" + mandant.textColorFooter1);
    setCssVariable("--text-color-footer2", "" + mandant.textColorFooter2);
    setCssVariable("--gap", "" + mandant.gap);
};

export default setMandantThemeByServer;
