import "./ProjectListing.css";

import {
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import ProjectTile from "../../../components/project/projectTile/ProjectTile";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IProjectList from "../../../types/IProjectList";
import ISnackbarState from "../../../types/ISnackbarState";
import userHasRoles from "../../../utils/userHasRole";
import {
    deleteProjectRequest,
    loadProjectsRequest,
    switchProjectPublishFlagRequest,
} from "../../../xhr/ProjectRequests";

interface IStateProps {
    mandant: IMandant;
    activeUser: IUser | null;
}
interface IOwnProps {
    onProjectEditClick?: (id: number) => {};
    //mandantKey prop from privateRoute
    mandantKey: string;
    handleSelectedHome: (index: number) => void;
}

type IProjectListingProps = IStateProps & IOwnProps;

const ProjectListing = (props: IProjectListingProps) => {
    const history = useHistory();
    const [data, setData] = React.useState<IProjectList[]>([]);
    const [projectsLoaded, setProjectsLoaded] = React.useState<boolean>(false);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    const { mandant, mandantKey, activeUser, handleSelectedHome } = props;
    const confirm = useConfirm();
    const loadProjectsToState = () => {
        loadProjectsRequest(mandantKey)
            .then((response: any) => {
                setData(response.data);
                setProjectsLoaded(true);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setProjectsLoaded(true);
            });
    };
    React.useEffect(() => {
        loadProjectsToState();
    }, [mandantKey]);
    React.useEffect(() => {
        handleSelectedHome(1);
    }, [handleSelectedHome]);

    const onProjectEditClick = (id: number) => {
        const url = "/" + mandantKey + RoutesEnum.ADMIN_PROJECT + id;
        history.push(url);
    };

    const onCreateProjectClick = () => {
        const url = "/" + mandantKey + RoutesEnum.ADMIN_PROJECT + "create";
        history.push(url);
    };

    const onProjectPublishClick = (id: number, mandKey: string) => {
        switchProjectPublishFlagRequest(id, mandKey)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Projekt wurde publiziert.",
                    type: "success",
                });

                loadProjectsRequest(mandantKey)
                    .then((newResp: any) => {
                        setData(newResp.data);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Die aktualisierte Projektliste konnte nicht geladen werden",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Projekt wurde depubliziert.",
                    type: "error",
                });
            });
    };

    const handleDeleteProject = (id: number) => {
        confirm({
            description: "Möchten Sie dieses Projekt wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                deleteProjectRequest(id, mandantKey)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Das Projekt wurde gelöscht.",
                            type: "success",
                        });
                        loadProjectsRequest(mandantKey).then(
                            (responses: any) => {
                                setData(responses.data);
                            }
                        );
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Löschen des Projektes ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                    });
                loadProjectsRequest(mandantKey)
                    .then((response: any) => {
                        setData(response.data);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Die aktualisierte Projektliste konnte nicht geladen werden",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                return;
            });
    };
    return (
        <>
            {!projectsLoaded ? (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            ) : (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">
                            Projekte verwalten
                        </Typography>
                    </Breadcrumbs>

                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            {userHasRoles(activeUser, [
                                RoleEnum.ROLE_ADMIN,
                                RoleEnum.ROLE_PROJECT_MANAGER,
                            ]) && (
                                <Button
                                    onClick={onCreateProjectClick}
                                    color="primary"
                                    startIcon={<IconAdd />}
                                >
                                    Ein neues Projekt anlegen
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                        {data
                            .sort((a, b) => {
                                const firstdate = new Date(a.theStart);
                                const seconddate = new Date(b.theStart);
                                return (
                                    firstdate.getTime() - seconddate.getTime()
                                );
                            })
                            .map((project: IProjectList, index) => (
                                <ProjectTile
                                    key={`project-${index}`}
                                    {...project}
                                    fallbackImage={mandant.defaultMenuImage}
                                    onDeleteClick={() =>
                                        handleDeleteProject(project.id)
                                    }
                                    onEditClick={() => {
                                        onProjectEditClick(project.id);
                                    }}
                                    onPublishClick={() => {
                                        onProjectPublishClick(
                                            project.id,
                                            mandantKey
                                        );
                                    }}
                                />
                            ))}
                    </Grid>
                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </>
            )}
        </>
    );
};

const mapStateToProps: MapStateToPropsParam<
    IStateProps,
    {},
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.User.userData,
    };
};

export default connect(mapStateToProps)(ProjectListing);
