import { Grid } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../components/cms/HtmlContent";
import FooterContact from "../../components/contact/FooterContact";
import CookiesConsent from "../../components/cookiesConsent/CookiesConsent";
import RoutesEnum from "../../routes/RoutesEnum";
import IMandant from "../../types/IMandant";

interface IFooterHeaderParams {
    history: any;
    mandant: IMandant;
}

const PortalFooter = (props: IFooterHeaderParams) => {
    const { history, mandant } = props;

    const onLinkClick = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        history.push(
            "/" +
                mandant.key +
                RoutesEnum.PORTAL_PAGE +
                "/" +
                event.currentTarget.dataset.page
        );
    };

    const showSecondFooter =
        !!mandant.flagDisabelFooterContact || !!mandant.flagDisableContact;

    return (
        <>
            <div className="App-footer">
                <div className="App-footer-outer">
                    <Grid className="App-footer-inner" spacing={2} container>
                        <Grid item sm={12} md={6} lg={6}>
                            <h2>{mandant.footerLeftTitel}</h2>
                            <HtmlContent
                                content={"" + mandant.footerLeftText}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                            {showSecondFooter && (
                                <>
                                    <h2>{mandant.footerRightTitel}</h2>
                                    <HtmlContent
                                        content={"" + mandant.footerRightText}
                                    />
                                </>
                            )}
                            {!showSecondFooter && <FooterContact />}
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className="App-footer2">
                <div className="App-footer-outer">
                    <div className="App-footer2-inner">
                        {mandant.slogan} ·{" "}
                        <span
                            className="App-link"
                            data-page="impressum"
                            onClick={onLinkClick}
                        >
                            Impressum
                        </span>{" "}
                        ·{" "}
                        <span
                            className="App-link"
                            data-page="datenschutz"
                            onClick={onLinkClick}
                        >
                            Datenschutzerklärung
                        </span>{" "}
                        ·{" "}
                        <span
                            className="App-link"
                            data-page="nutzungsbedingungen"
                            onClick={onLinkClick}
                        >
                            Nutzungsbedingungen
                        </span>
                    </div>
                </div>
            </div>
            <CookiesConsent />
        </>
    );
};

export default PortalFooter;
