import { Paper, Typography } from "@material-ui/core";
import React from "react";

import IElseVote from "../../types/IElseVote";
import InitialPollCompaignData from "../../types/initialData/InitialPollCompaignData";
import InitialSnackbarState from "../../types/initialData/InitialSnackbarState";
import IPollCampaignList from "../../types/IPollCampaignList";
import IPollPoint from "../../types/IPollPoint";
import IProjectFull from "../../types/IProjectFull";
import ISnackbarState from "../../types/ISnackbarState";
import {
    campaignVoteElseRequest,
    campaignVoteRequest,
    loadPortalSinglePollRequest,
} from "../../xhr/PollCampaign";
import HtmlContent from "../cms/HtmlContent";
import Notify from "../notify/Notify";
import Poll from "../poll/Poll";
import { incrementElseresult, updateResult } from "./helperFunc";

interface IOwnsProps {
    project: IProjectFull;
    mandantKey: string;
    signedUser: any;
}

interface IPollComponentPropos extends IOwnsProps {}

export const PollComponent = (props: IPollComponentPropos) => {
    /* snackbare state  */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    }; /* vote state */
    const [voteCompaign, setVoteCompaign] = React.useState<IPollCampaignList>({
        ...InitialPollCompaignData,
    });
    /* elseVote State */

    const { project, mandantKey, signedUser } = props;
    React.useEffect(() => {
        loadPortalSinglePollRequest(mandantKey, project.id + "")
            .then((response) => {
                if (response.data) {
                    setVoteCompaign(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    /**
     * @param elseVoteVal
     */
    const handleElseVote = (elseVoteVal: IElseVote) => {
        if (!signedUser) {
            return;
        }

        const newPoint: IPollPoint = {
            title: "Etwas anderes, und zwar",
            id: 0,
        };
        const newPointList = [...voteCompaign.points];
        newPointList.push(newPoint);
        setVoteCompaign({ ...voteCompaign, points: newPointList });

        campaignVoteElseRequest(mandantKey, elseVoteVal, voteCompaign.id)
            .then(() => {
                const newResults = incrementElseresult(voteCompaign);
                setVoteCompaign({
                    ...voteCompaign,
                    results: newResults,
                });
                setSnackBarState({
                    isOpen: true,
                    message: "Vielen Dank für ihre Teilnahme",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Bei der Abgabe der Stimme ist leider ein Fehler aufgetreten.",
                    type: "error",
                });
            });
    };

    /**
     * @param vote
     */
    const handleVote = (vote: string) => {
        if (!signedUser) {
            return;
        }

        const votedoption: IPollPoint[] = voteCompaign.points.filter(
            (elem: IPollPoint) => {
                return elem.title === vote;
            }
        );

        const voteTosubmit = {
            point: { id: votedoption[0].id },
        };
        campaignVoteRequest(mandantKey, voteTosubmit, voteCompaign.id)
            .then(() => {
                const newResult = updateResult(voteCompaign, votedoption[0]);
                setVoteCompaign({
                    ...voteCompaign,
                    results: newResult,
                });
                setSnackBarState({
                    isOpen: true,
                    message: "Vielen Dank für ihre Teilnahme",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Bei der Abgabe der Stimme ist leider ein Fehler aufgetreten.",
                    type: "error",
                });
            });
    };
    if (!voteCompaign.flagPublished) {
        return <></>;
    }
    const handleEmptyVote = () => {
        setSnackBarState({
            isOpen: true,
            message:
                "Bei der Abgabe der Stimme ist leider ein Fehler aufgetreten.",
            type: "error",
        });
    };
    return (
        <>
            <Paper
                variant="outlined"
                className="main-paper gap-top"
                elevation={1}
                square
            >
                <div className="full-pad">
                    <Typography gutterBottom variant="h2" className="gap-top">
                        {voteCompaign.title}
                    </Typography>
                    <HtmlContent
                        className="content-block"
                        content={voteCompaign.description}
                    />
                </div>
                <Poll
                    poll={voteCompaign}
                    onNewOptionVote={handleElseVote}
                    handleVote={handleVote}
                    signedUser={signedUser}
                    readOnly={!signedUser}
                    onEmptyVote={handleEmptyVote}
                />
            </Paper>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};
