import "./ProjectDrawer.css";

import { IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useHistory } from "react-router-dom";

import useWindowSize from "../../../../customhooks/useWindowSize";
import { IMenuEntry } from "../../../admin/projectForm/tabs/ProjectMenuTab";

interface IProjectDrawerProps {
    entries: IMenuEntry[];
    mandantKey: string;
    projectKey: string;
    pageKey?: string;
    tabKey?: string;
}

export default function ProjectDrawer(props: IProjectDrawerProps) {
    const history = useHistory();

    const { entries, mandantKey, projectKey, pageKey, tabKey } = props;
    const [state, setState] = React.useState({
        left: false,
    });
    const WindowWidth = useWindowSize();

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, left: open });
        };

    const executeLinkAction = (menuEntry: IMenuEntry) => {
        if (menuEntry.type === "page") {
            history.push(
                "/" + mandantKey + "/" + projectKey + "/seite/" + menuEntry.url
            );
        } else {
            history.push(
                "/" + mandantKey + "/" + projectKey + "/" + menuEntry.url
            );
        }
    };

    const checkIfIsActive = (
        menuEntry: IMenuEntry,
        checkKids: boolean
    ): boolean => {
        if (menuEntry.type === "tab") {
            if (tabKey === menuEntry.url) {
                return true;
            }
        } else {
            if (pageKey === menuEntry.url) {
                return true;
            }
        }

        if (!checkKids) {
            return false;
        }

        if (menuEntry.children.length === 0) {
            return false;
        }

        for (const child of menuEntry.children) {
            if (child.type === "tab") {
                if (tabKey === child.url) {
                    return true;
                }
            } else {
                if (pageKey === child.url) {
                    return true;
                }
            }
        }

        return false;
    };

    const list = () => (
        <div role="presentation" onKeyDown={toggleDrawer(false)}>
            <List>
                <Divider />
                {entries.map((entry, index) => (
                    <>
                        <ListItem
                            button
                            key={index}
                            className={
                                checkIfIsActive(entry, false) ? "is-active" : ""
                            }
                            onClick={() => {
                                executeLinkAction(entry);
                            }}
                        >
                            <ListItemText primary={entry.label} />
                        </ListItem>
                        <Divider />
                        {entry.children.length > 0 && (
                            <List>
                                {entry.children.map((subEntry, subIndex) => {
                                    return (
                                        <React.Fragment
                                            key={index + "-" + subIndex}
                                        >
                                            <ListItem
                                                button
                                                className={
                                                    checkIfIsActive(
                                                        subEntry,
                                                        false
                                                    )
                                                        ? "is-active"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    executeLinkAction(subEntry);
                                                }}
                                            >
                                                <ListItemText
                                                    primary={subEntry.label}
                                                />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })}
                            </List>
                        )}
                    </>
                ))}
            </List>
        </div>
    );

    return (
        <React.Fragment>
            <div onClick={toggleDrawer(true)}>
                <IconButton>
                    <MenuIcon />
                </IconButton>
                Projekt Menu
            </div>
            <SwipeableDrawer
                anchor={"left"}
                open={state.left}
                className="project-drawer"
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
        </React.Fragment>
    );
}
