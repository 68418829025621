import * as React from "react";

import IDocument from "../../types/IDocument";
import CmsImage from "../cms/CmsImage";

interface IImageHeaderParams {
    image: IDocument;
    isBig?: boolean;
}

const ImageHeader = (props: IImageHeaderParams) => {
    return (
        <div
            className={
                props.isBig ? "App-main-image big-image" : "App-main-image"
            }
        >
            <CmsImage image={props.image} />
        </div>
    );
};

export default ImageHeader;
