import { DEFAULT_LOCALE } from "../../../translations/I18n";
/*
 *
 * LanguageProvider reducer
 *
 */
import ActionTypes from "../LanguageActions/ActionTypesEnum";
import { AppActions, ILanguageProviderState } from "./types";

export const initialState: ILanguageProviderState = {
    locale: DEFAULT_LOCALE,
};

function languageProviderReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ILanguageProviderState = initialState,
    action: AppActions
): ILanguageProviderState {
    switch (action.type) {
        case ActionTypes.CHANGE_LOCALE:
            return {
                locale: action.payload,
            };
        default:
            return state;
    }
}

export default languageProviderReducer;
