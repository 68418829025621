import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImmageGallery from "../../../../components/imageGallery/ImageGallery";
import IEventFull from "../../../../types/IEventFull";

interface IAboutProps {
    event: IEventFull;
}

function AboutTab(props: IAboutProps) {
    const { event } = props;

    return (
        <>
            <h3>Worum geht es bei dieser Veranstaltung?</h3>
            <HtmlContent
                className="content-block"
                content={event.description}
            />
            {Boolean(event.attachments?.length) && (
                <FolderList files={event.attachments} fromEventTab={true} />
            )}

            {Boolean(event.images?.length) && (
                <>
                    <h3>Bilder zur Veranstaltung</h3>
                    <ImmageGallery images={event.images} />
                </>
            )}
        </>
    );
}

export default AboutTab;
