import { Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";
import IDocumentRef from "../../types/IDocumentRef";
import { getExtension } from "../../utils/checkFileAndDecideIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: "450px",
            backgroundColor: theme.palette.background.paper,
        },
    })
);

interface IFolderListCmsProps {
    refs: IDocumentRef[];
    uploads: { [key: string]: IDocument };
}

export default function FolderListCms(props: IFolderListCmsProps) {
    const classes = useStyles();
    const { refs, uploads } = props;

    if (!refs?.length) {
        return <></>;
    }

    return (
        <>
            <List className={classes.root}>
                <Divider />

                {refs.map((ref) => {
                    const document = uploads[ref.key];

                    if (document === undefined) {
                        return <></>;
                    }

                    const isPdf = getExtension(document.name) === "pdf";

                    return (
                        <div key={`file-${document.url}`}>
                            <ListItem>
                                <ListItemAvatar>
                                    <a
                                        href={API_URL_STATIC + document.url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <Avatar>
                                            {isPdf ? (
                                                <PictureAsPdfIcon />
                                            ) : (
                                                <SlideshowIcon />
                                            )}
                                        </Avatar>
                                    </a>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <a
                                            href={API_URL_STATIC + document.url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {document.name}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </div>
                    );
                })}
            </List>
        </>
    );
}
