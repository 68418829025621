import { Grid, Paper } from "@material-ui/core";
import React from "react";

import TextEdit from "../../../../../components/wysiwyg/TextEdit";
import IEventFull from "../../../../../types/IEventFull";

type IEventTextTab = {
    eventData: IEventFull;
    onWysiwygChange: (editorState: any, name: string) => void;
};

const EventTextTab = (props: IEventTextTab) => {
    const { eventData, onWysiwygChange } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(event: any) => {
                            onWysiwygChange(event, "description");
                        }}
                        title="Veranstaltungs Beschreibung"
                        defaultValue={eventData.description || " "}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EventTextTab;
