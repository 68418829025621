import ActionTypes from "../ProjectSelectboxActionTypesEnum";
import { AppActions, IProjectSelectboxState } from "./types";

export const initialState: IProjectSelectboxState = {
    error: null,
    projectData: [],
};

function ProjectSelectboxReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: IProjectSelectboxState = initialState,
    action: AppActions
): IProjectSelectboxState {
    switch (action.type) {
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                projectData: action.payload,
                error: null,
            };
        case ActionTypes.LOAD_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default ProjectSelectboxReducer;
