import ICampaign from "../appointment/ICampaign";
import InitialDocumentData from "./InitialDocumentData";
import InitialEmail from "./InitialEmail";

const InitialCampaignData: ICampaign = {
    id: 0,
    title: "",
    location: "",
    locationLink: "",
    description: "",
    serialNumber: "",
    startDate: "",
    endDate: "",
    bookingEndDate: "",
    appointmentStartDate: "",
    appointmentEndDate: "",
    managementEmail: "",
    assistantEmail: "",
    image: { ...InitialDocumentData },
    document: { ...InitialDocumentData },
    confirmationMail: { ...InitialEmail },
    reminderMail: { ...InitialEmail },
    isAppointmentGroupSplittable: false,
    isPublicAvailable: false,
    isActive: false,
    campaignRooms: [],
    campaignAppointments: [],
};

export default InitialCampaignData;
