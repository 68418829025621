import "./Comments.css";

import { CircularProgress, Divider, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { AxiosResponse } from "axios";
import moment from "moment";
import React from "react";

import userLogo from "./../../assets/images/user.svg";
import {
    API_URL_STATIC,
    COMMENT_PAGINATION,
    Format,
} from "../../config/config";
import IUser from "../../store/userStore/interfaces/IUser";
import InitialComment from "../../types/initialData/InitialComment";
import InitialSnackbarState from "../../types/initialData/InitialSnackbarState";
import IPortalComment from "../../types/IPortalComment";
import IPortalCommentList from "../../types/IPortalCommentList";
import IProjectFull from "../../types/IProjectFull";
import ISnackbarState from "../../types/ISnackbarState";
import deepCopy from "../../utils/deepCopy";
import {
    createCommentRequest,
    loadEventCommentsRequest,
    loadProjectCommentsRequest,
} from "../../xhr/CommentRequests";
import HtmlContent from "../cms/HtmlContent";

export interface ICommentsProps {
    commentsPerPage?: number;
    mandantKey?: string;
    eventId?: number;
    projectId?: number;
    projectRoles: string[];
    project: IProjectFull;
    user: IUser | null; // is null when the user is not logged in
}

const Comments = (props: ICommentsProps) => {
    const { mandantKey, eventId, projectId, user, project } = props;

    const [commentList, setCommentList] = React.useState<IPortalCommentList[]>(
        []
    );

    const [loading, setLoading] = React.useState<boolean>(false);

    const [commentsToDisplay, setCommentsToDisplay] = React.useState<
        IPortalCommentList[]
    >([]);

    const [commentData, setCommentData] = React.useState<IPortalComment>({
        ...InitialComment,
    });

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [page, setPage] = React.useState(1);

    /**
     * here we load either the comments for an event or for the project
     */
    React.useEffect(() => {
        if (eventId) {
            setCommentData({
                ...commentData,
                // @ts-ignore
                event: { id: eventId },
            });
            loadEventCommentsRequest("" + mandantKey, eventId)
                .then((response: AxiosResponse<IPortalCommentList[]>) => {
                    setCommentList(response.data);

                    setCommentsToDisplay(
                        response.data.slice(
                            (page - 1) * COMMENT_PAGINATION,
                            page * COMMENT_PAGINATION
                        )
                    );
                })
                .catch((error: any) => {
                    console.error(error);
                });
        } else if (projectId) {
            setCommentData({
                ...commentData,
                // @ts-ignore
                project: { id: projectId },
            });
            loadProjectCommentsRequest("" + mandantKey, projectId)
                .then((response: AxiosResponse<IPortalCommentList[]>) => {
                    setCommentList(response.data);

                    setCommentsToDisplay(
                        response.data.slice(
                            (page - 1) * COMMENT_PAGINATION,
                            page * COMMENT_PAGINATION
                        )
                    );
                })
                .catch((error: any) => {
                    console.error("some error", error);
                });
        }
    }, []);

    /**
     * The handleTextChange
     */
    const handleTextChange = (event: any) => {
        const newData = {
            ...commentData,
            [event.target.name]: event.target.value,
        };
        setCommentData(newData);
    };

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const commentSaveClickAction = () => {
        setLoading(true);

        createCommentRequest("" + mandantKey, commentData)
            .then((response: AxiosResponse<IPortalComment>) => {
                //@ts-ignore
                commentsToDisplay.unshift(response.data);

                if (commentsToDisplay.length + 2 > COMMENT_PAGINATION) {
                    commentsToDisplay.pop();
                }

                setCommentsToDisplay([...commentsToDisplay]);
                //@ts-ignore
                commentList.unshift(response.data);
                setCommentList([...commentList]);

                if (eventId) {
                    setCommentData(
                        deepCopy<IPortalComment>({
                            ...InitialComment,
                            // @ts-ignore
                            event: { id: eventId },
                        })
                    );
                } else if (projectId) {
                    setCommentData(
                        deepCopy<IPortalComment>({
                            ...InitialComment,
                            // @ts-ignore
                            project: { id: projectId },
                        })
                    );
                }

                setSnackBar({
                    isOpen: true,
                    type: "success",
                    message: "Vielen Dank für Ihren Kommentar.",
                });

                setLoading(false);
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    type: "error",
                    message:
                        "Beim Erstellen des Kommentars ist ein Fehler aufgetreten.",
                });

                setLoading(false);
            });

        //
    };

    const handlePagerChange = (event: object, pagerPos: number) => {
        setPage(pagerPos);

        setCommentsToDisplay(
            commentList.slice(
                (pagerPos - 1) * COMMENT_PAGINATION,
                pagerPos * COMMENT_PAGINATION
            )
        );
    };

    const renderUserImage = (comment: IPortalCommentList) => {
        if (!comment.user || !comment.user.image) {
            return <img src={userLogo} alt="not found" />;
        } else {
            return (
                <img
                    src={API_URL_STATIC + comment.user.image.url}
                    alt="not found"
                />
            );
        }
    };

    return (
        <div className="ui comments">
            {mandantKey !== "silbernes-erzgebirge" && (
                <Typography
                    className="project-comments-title"
                    gutterBottom
                    variant="h2"
                >
                    {" "}
                    Was ist Ihnen sonst noch wichtig?
                </Typography>
            )}

            <HtmlContent
                content={project.commmentRulesText}
                className="gap-bottom gap-top"
            />

            <div className="gap-bottom">
                <TextField
                    className="comment-textarea"
                    fullWidth={true}
                    multiline
                    rows={5}
                    name="content"
                    disabled={!user}
                    onChange={handleTextChange}
                    value={commentData.content}
                    placeholder="Kommentar"
                    variant="outlined"
                    size="small"
                />
                {loading && <CircularProgress />}

                {!loading && (
                    <Button
                        onClick={commentSaveClickAction}
                        disabled={!user}
                        color="primary"
                    >
                        Kommentieren
                    </Button>
                )}
            </div>

            {commentsToDisplay.map(
                (comment: IPortalCommentList, index: number) => {
                    return (
                        <div key={"comment" + index + comment.id}>
                            <div
                                className="comment"
                                key={"comment_number_" + comment.id}
                            >
                                <div className="avatar">
                                    {renderUserImage(comment)}
                                </div>
                                <div className="content">
                                    <div className="author">
                                        {user ? (
                                            <>{comment.user?.nickName}</>
                                        ) : (
                                            <>{comment.nameOfPerson}</>
                                        )}
                                    </div>
                                    <div className="metadata">
                                        <div>
                                            {moment(
                                                comment.creationDate
                                            ).format(Format.dateTime)}
                                        </div>
                                    </div>
                                    <div className="text">
                                        {comment.content}
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    );
                }
            )}
            {commentList.length > COMMENT_PAGINATION && (
                <Pagination
                    className="comment-pager"
                    onChange={handlePagerChange}
                    count={Math.ceil(commentList.length / COMMENT_PAGINATION)}
                />
            )}

            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default Comments;
