import "./ProjectData.css";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { LinearProgress, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
//import download from "downloadjs";
//import ical from "ical-generator";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ImageHeader from "../../../components/layout/ImageHeader";
import MapPicker from "../../../components/olMap/MapPicker";
import { PollComponent } from "../../../components/pollComponent/PollComponent";
import { ITab } from "../../../components/tabContainer/TabContainer";
import WebsiteMenu from "../../../components/websiteMenu/WebsiteMenu";
import { API_URL_STATIC, AccordionMinWidth } from "../../../config/config";
import useWindowSize from "../../../customhooks/useWindowSize";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import ICampaign from "../../../types/appointment/ICampaign";
import IMandant from "../../../types/IMandant";
import InitialProjectFullData from "../../../types/initialData/InitialProjectFullData";
import IProjectFull from "../../../types/IProjectFull";
import ITheme from "../../../types/ITheme";
import { loadActiveCampaignRequest } from "../../../xhr/CampaignRequests";
import {
    loadPublicProjectDataRequest,
    loadPublicProjectMenuRequest,
} from "../../../xhr/ProjectRequests";
import { IProjectMenu } from "../../admin/projectForm/tabs/ProjectMenuTab";
import ProjectDataContent from "./components/ProjectDataContent";
import ProjectDataSidebarMapper from "./components/ProjectDataSidebarMapper";
import toLabel from "./helper/toLabel";
import ProjectAboutTab from "./tabs/ProjectAboutTab";
import ProjectCampaignTab from "./tabs/ProjectCampaignTab";
import ProjectConsultingTab from "./tabs/ProjectConsultingTab";
import ProjectEventsTab from "./tabs/ProjectEventsTab";
import ProjectParticipateTab from "./tabs/ProjectParticipateTab";
import ProjectResultsTab from "./tabs/ProjectResultTab";
import ProjectStartTab from "./tabs/ProjectStartTab";
import ProjectTeamTab from "./tabs/ProjectTeamTab";

export enum ProjectTabEnum {
    START_TAB = "start",
    ABOUT_TAB = "ueber",
    CAMPAIGN_TAB = "termine",
    EVENTS_TAB = "ereignisse",
    RESULTS_TAB = "ergebnisse",
    PARTICIPATE_TAB = "teilnehmen",
    CONSULTING_TAB = "beratung",
    TEAM_TAB = "team",
}

interface IMapStateProps {
    mandant: IMandant;
    mandantTheme: ITheme;
    actualUser: any;
}

interface IOwnProps {}

interface IProjectDataParams extends IMapStateProps, IOwnProps {}

const ProjectData = (props: IProjectDataParams) => {
    const history = useHistory();

    const {
        mandantKey,
        id,
        tabKey,
        pageKey,
    }: { mandantKey: string; id: string; tabKey: string; pageKey: string } =
        useParams();
    const [projectData, setProjectData] = React.useState<IProjectFull>({
        ...InitialProjectFullData,
    });
    const [campaignData, setCampaignData] = React.useState<ICampaign | null>(
        null
    );
    const { mandant, actualUser } = props;
    const [projectLoaded, setProjectLoaded] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<string>("start");
    const windowWidth = useWindowSize();
    const [customMenu, setCustomMenu] = React.useState<IProjectMenu>();

    const isMobileSize = windowWidth < AccordionMinWidth;

    const getTabByHash = (hash: string): string => {
        if (String(hash).trim() !== "") {
            return hash;
        } else {
            return ProjectTabEnum.START_TAB;
        }
    };

    React.useEffect(() => {
        loadPublicProjectDataRequest(mandantKey, id)
            .then((result) => {
                setProjectData(result.data);
                setProjectLoaded(true);

                if (result.data.flagEnableProjectCms) {
                    loadPublicProjectMenuRequest(
                        mandantKey,
                        "" + result.data.id
                    ).then((menuResult) => {
                        setCustomMenu(menuResult.data);
                    });
                }

                loadActiveCampaignRequest(mandantKey, "" + result.data.id)
                    .then((campaignResult) => {
                        setCampaignData(campaignResult.data);
                        return Promise.resolve();
                    })
                    .catch((error) => {
                        console.log("error", { error });
                    });

                return Promise.resolve();
            })
            .catch(() => {
                setProjectLoaded(true);
            });
    }, []);

    React.useEffect(() => {
        if (tabKey) {
            setActiveTab(getTabByHash(tabKey));
        } else {
            const tab = window.location.hash.replace("#", "");
            setActiveTab(getTabByHash(tab));
        }
    }, [window.location.hash, tabKey]);

    const handleTabChange = (event: React.ChangeEvent<{}>, tabIdx: string) => {
        history.push(
            "/" + mandant.key + "/" + projectData.urlKey + "/" + tabIdx
        );
    };

    const fitStyle = () => {
        if (isMobileSize) {
            return { margin: "2px" };
        } else {
            return {};
        }
    };

    if (!projectData?.id) {
        return (
            <div className="portal-section">
                <Helmet>
                    <title>{projectData.title}</title>
                </Helmet>

                <Header mandant={mandant} history={history} />
                <div className="App-main" style={fitStyle()}>
                    <LinearProgress color="secondary" />
                </div>

                <PortalFooter history={history} mandant={mandant} />
            </div>
        );
    }
    /*
    const handleDownloadClick = (e: any, event: IEventList) => {
        const cal = ical({ name: event.title });
        cal.createEvent({
            start: event.theStart ? new Date(event.theStart) : "",
            end: event.theEnd ? new Date(event.theEnd) : "",
            summary: "" + event.shortDesc,
            description: "" + event.shortDesc,
            location: event.locationName + " " + event.locationUrl,
        });
        const dataTodownload = cal.toString();
        download(dataTodownload, event.title + ".ics", "text/calendar");
    };
     */

    const getTabs = (): ITab[] => {
        return [
            {
                id: "tab-project-start",
                label: toLabel(
                    projectData.titleTabStart,
                    <span>
                        {projectData.shortTitle} <br />
                        <sub>Startseite</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.START_TAB,
                display: !!projectData.flagTabStart,
                content: <ProjectStartTab project={projectData} />,
            },
            {
                id: "tab-the-info",
                label: toLabel(
                    projectData.titleTabInfo,
                    <span>
                        informieren <br />
                        <sub>Projektvorstellung</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.ABOUT_TAB,
                display: !!projectData.flagTabInfo,
                content: (
                    <ProjectAboutTab project={projectData} mandant={mandant} />
                ),
            },
            {
                id: "tab-project-events",
                label: toLabel(
                    projectData.titleTabEvents,
                    <span>
                        teilnehmen <br />
                        <sub>Veranstaltungen</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.EVENTS_TAB,
                display: !!projectData.flagTabEvents,
                content: (
                    <ProjectEventsTab
                        project={projectData}
                        mandant={mandant}
                        history={history}
                    />
                ),
            },
            {
                id: "tab-results",
                label: toLabel(
                    projectData.titleTabResults,
                    <span>
                        ansehen <br />
                        <sub>Bisherige Ergebnisse</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.RESULTS_TAB,
                display: !!projectData.flagTabResults,
                content: <ProjectResultsTab project={projectData} />,
            },
            {
                id: "tab-participate",
                label: toLabel(
                    projectData.titleTabParticipate,
                    <span>
                        mitmachen <br />
                        <sub>Beteiligung</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.PARTICIPATE_TAB,
                display: !!projectData.flagTabParticipate,
                content: (
                    <ProjectParticipateTab
                        project={projectData}
                        mandantKey={mandantKey}
                        actualUser={actualUser}
                        history={history}
                    />
                ),
            },
            {
                id: "tab-consulting",
                label: toLabel(
                    projectData.titleTabConsulting,
                    <span>
                        mitmachen <br />
                        <sub>Beratung</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.CONSULTING_TAB,
                display: !!projectData.flagTabConsulting,
                content: (
                    <ProjectConsultingTab
                        project={projectData}
                        mandantKey={mandantKey}
                        actualUser={actualUser}
                    />
                ),
            },
            {
                id: "tab-team",
                label: toLabel(
                    projectData.titleTabTeam,
                    <span>
                        kontaktieren
                        <sub>
                            <br />
                            Projektteam
                        </sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.TEAM_TAB,
                display: !!projectData.flagTabTeam,
                content: <ProjectTeamTab project={projectData} />,
            },
            {
                id: "tab-campaign",
                label: toLabel(
                    projectData.titleTabCampaign,
                    <span>
                        Termine <br />
                        <sub>Buchungssystem</sub>
                    </span>
                ),
                tabKey: ProjectTabEnum.CAMPAIGN_TAB,
                display: !!projectData.flagTabCampaign && !!campaignData,
                content: (
                    <>
                        {campaignData && (
                            <ProjectCampaignTab
                                mandant={mandant}
                                campaign={campaignData}
                            />
                        )}
                    </>
                ),
            },
        ];
    };

    const showPoll =
        ProjectTabEnum.PARTICIPATE_TAB === activeTab &&
        projectData.flagTabParticipate;

    return (
        <div className="portal-section">
            <Helmet>
                <title>{projectData.title}</title>
            </Helmet>

            <Header mandant={mandant} history={history} />
            {projectLoaded || false ? (
                <>
                    {" "}
                    {ProjectTabEnum.ABOUT_TAB === activeTab && (
                        <iframe
                            title="timelinejs"
                            src={
                                API_URL_STATIC +
                                "timeline/timeline.html#m=" +
                                mandant.key +
                                "&i=" +
                                projectData.id +
                                "&a=" +
                                encodeURI(API_URL_STATIC)
                            }
                            style={{
                                width: "100%",
                                height: "600px",
                                border: "none",
                            }}
                        />
                    )}
                    {ProjectTabEnum.ABOUT_TAB !== activeTab && (
                        <ImageHeader
                            isBig={mandantKey === "kernen"}
                            image={
                                projectData.imageHeader
                                    ? projectData.imageHeader
                                    : mandant.headerImage
                            }
                        />
                    )}
                    <div className="portal-section-body-position">
                        <div className="App-main" style={fitStyle()}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12} sm={12} md={12} lg={9}>
                                    <div className="App-data-container">
                                        {projectData.flagEnableProjectCms &&
                                            customMenu !== undefined && (
                                                <WebsiteMenu
                                                    isMobileSize={isMobileSize}
                                                    mandant={mandant}
                                                    customMenu={customMenu}
                                                    projectData={projectData}
                                                    pageKey={pageKey}
                                                    tabKey={tabKey}
                                                />
                                            )}

                                        <ProjectDataContent
                                            mandant={mandant}
                                            isMobileSize={isMobileSize}
                                            projectData={projectData}
                                            getTabs={getTabs}
                                            hideTabHeader={
                                                !!projectData.flagEnableProjectCms
                                            }
                                            activeTab={activeTab}
                                            handleTabChange={handleTabChange}
                                            pageKey={pageKey}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                    {showPoll && (
                                        <Grid
                                            item
                                            xs={12}
                                            className="gap-bottom"
                                        >
                                            <PollComponent
                                                project={projectData}
                                                mandantKey={mandantKey}
                                                signedUser={actualUser}
                                            />
                                        </Grid>
                                    )}

                                    {!showPoll && (
                                        <Grid
                                            item
                                            xs={12}
                                            className="gap-bottom"
                                        >
                                            <Paper
                                                className="App-sidebar"
                                                variant="outlined"
                                                square
                                            >
                                                <h2 className="App-sidebar-h2">
                                                    Karte
                                                </h2>
                                                <MapPicker data={projectData} />
                                            </Paper>
                                        </Grid>
                                    )}

                                    <ProjectDataSidebarMapper
                                        projectData={projectData}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </>
            ) : (
                <div className="App-main" style={fitStyle()}>
                    <LinearProgress color="secondary" />
                </div>
            )}

            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        mandantTheme: state.mandant.mandantTheme,
        actualUser: state.User.userData,
    };
};

export default connect(mapStateToProps)(ProjectData);
