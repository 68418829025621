import "./DeadlineListingTab.css";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, LinearProgress, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import React, { useState } from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import DeleteDialog from "../../../../components/deleteDialog/DeleteDialog";
import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import Notify from "../../../../components/notify/Notify";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import { Format } from "../../../../config/config";
import IDeadlineList from "../../../../types/IDeadlineList";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import InitialDeadlineData from "../../../../types/initialData/InitialDeadlineData";
import InitialDocumentData from "../../../../types/initialData/InitialDocumentData";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../../types/ISnackbarState";
import {
    createDeadlineRequest,
    deleteDeadlineRequest,
    updateDeadlineRequest,
} from "../../../../xhr/DeadlineRequests";

type DeadlineListingProps = {
    projectID: number;
    deadlines: IDeadlineList[];
    onUpdate: () => void;
    mandant: IMandant;
};

const DeadlineListingTab: React.FC<DeadlineListingProps> = (
    props: DeadlineListingProps
) => {
    const { projectID, deadlines } = props;

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    // const [loading, setLoading] = React.useState<boolean>(false);
    const [IsLoading, setisLoading] = React.useState<boolean>(false);

    const [deadlineState, setDeadLineState] =
        useState<IDeadlineList[]>(deadlines);
    const [deadlineLocation, setDeadlineLocation] = React.useState<any>([
        12, 50,
    ]);
    const [deadlineImage, setDeadlineImage] = React.useState<IDocument>({
        ...InitialDocumentData,
    });
    const [zoom, setZoom] = React.useState<any>(4);

    React.useEffect(() => {
        setDeadLineState(deadlines);
    }, [deadlines]);
    const [editDeadline, setEditDeadline] = useState<IDeadlineList>({
        ...InitialDeadlineData,
        deadlineDate: moment().format(Format.dateTimeEn),
    });
    // handle edit deadline click
    const handleEdit = (row: any) => {
        //props.onUpdate();
        setEditDeadline({ ...row });
        setDeadlineImage(row.image);
        setDeadlineLocation([row.latitude, row.longitude]);
        setZoom(row.zoom);
    };
    // handle delete dewadline
    const deleteHandler = (row: IDeadlineList) => {
        setisLoading(true);
        deleteDeadlineRequest(props.mandant.key, row.id)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Ereignis wurde gelöscht",
                    type: "success",
                });
                setEditDeadline({ ...InitialDeadlineData });
                setDeadlineImage({ ...InitialDocumentData });

                setisLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Es ist ein Fehler beim Löschen des Ereignis aufgetreten",
                    type: "error",
                });
                setisLoading(false);
            });
        const newDeadlineList = deadlineState.filter(
            (elem: any) => elem.id !== row.id
        );

        setDeadLineState(newDeadlineList);
    };
    //handle save create deadline
    const handleSaveDeadline = (e: any) => {
        e.preventDefault();
        setisLoading(true);

        if (editDeadline.id === 0) {
            const { deadlineDate } = editDeadline;
            createDeadlineRequest(props.mandant.key, {
                ...editDeadline,
                project: { id: projectID },
                latitude: deadlineLocation[0],
                longitude: deadlineLocation[1],
                zoom: zoom,
                deadlineDate: deadlineDate.substring(
                    0,
                    deadlineDate.length - 3
                ),
                image: deadlineImage,
            })
                .then((response) => {
                    setDeadLineState([...deadlineState, response.data]); // im updating the local state here
                    setEditDeadline({ ...InitialDeadlineData });
                    setDeadlineImage({ ...InitialDocumentData });
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Ereignis wurde erfolgreich gespeichert",
                        type: "success",
                    });
                    setisLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Es ist ein Fehler beim Speichern des Ereignis aufgetreten",
                        type: "error",
                    });
                    setisLoading(false);
                });
        }

        //will update
        else {
            updateDeadlineRequest(
                {
                    ...editDeadline,
                    latitude: deadlineLocation[0],
                    longitude: deadlineLocation[1],
                    zoom,
                    image: deadlineImage,
                    project: { id: projectID },
                },
                props.mandant.key
            )
                .then(() => {
                    setEditDeadline({ ...InitialDeadlineData });
                    setDeadlineImage({ ...InitialDocumentData });

                    props.onUpdate();
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Ereignis wurde erfolgreich erstellt",
                        type: "success",
                    });
                    setisLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Es ist ein Fehler beim Speichern des Ereignis aufgetreten",
                        type: "error",
                    });
                    setisLoading(false);
                });
        }
    };
    //handle reset deadline
    const handleResetDeadline = () => {
        setEditDeadline({ ...InitialDeadlineData });
        setDeadlineImage({ ...InitialDocumentData });
    };

    //handle deadline title change
    const handleChangeTitle = (e: any) => {
        setEditDeadline({
            ...editDeadline,
            title: e.target.value,
        });
    };

    //handle deadlinecontent change
    const handleContentChange = (editorState: any) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        setEditDeadline({
            ...editDeadline,
            content: markup,
        });
    };
    //deadlineDate change
    const handleDeadlineDate = (date: any) => {
        setEditDeadline({
            ...editDeadline,
            deadlineDate: moment(date).format(Format.dateTimeEn),
        });
    };
    const handleEditLocation = (lng: number, lat: number, zooomm: number) => {
        setZoom(zooomm);

        setDeadlineLocation([lat, lng]);
    };
    const handleImageChange = (image: IDocument, attrKey: string) => {
        // const newData = { ...editDeadline, [attrKey]: image };
        setDeadlineImage(image);
    };
    return (
        <>
            {IsLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            <Paper variant="outlined" className={"main-paper"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} xl={9}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ereignisse</TableCell>
                                        <TableCell>Titel</TableCell>
                                        <TableCell>
                                            Beschreibung&nbsp;(g)
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: "120px",
                                                minWidth: "120px",
                                                maxWidth: "120px",
                                            }}
                                        >
                                            Menü
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {deadlineState.length > 0 ? (
                                    <TableBody>
                                        {deadlineState
                                            .sort((a, b) => {
                                                const firstdate = new Date(
                                                    a.deadlineDate
                                                );
                                                const seconddate = new Date(
                                                    b.deadlineDate
                                                );
                                                return (
                                                    firstdate.getTime() -
                                                    seconddate.getTime()
                                                );
                                            })
                                            .map((row: any) => (
                                                <TableRow key={row.id}>
                                                    <TableCell scope="row">
                                                        {row.deadlineDate}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        <HtmlContent
                                                            content={
                                                                row.content
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "120px",
                                                            minWidth: "120px",
                                                            maxWidth: "120px",
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                handleEdit(row)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <DeleteDialog
                                                            deleteHandler={
                                                                deleteHandler
                                                            }
                                                            deadline={row}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                ) : (
                                    <></>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={4} xl={3}>
                        <Paper variant="outlined" className={"main-paper"}>
                            <Typography variant={"h3"} className="gap-bottom">
                                {editDeadline.id ? (
                                    <>Ereignis editieren</>
                                ) : (
                                    <>Ereignis erstellen</>
                                )}
                            </Typography>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <DateTimePicker
                                    label="Zeitpunkt Ereignis *"
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    format={Format.dateInpTime}
                                    value={
                                        editDeadline.deadlineDate
                                            ? new Date(
                                                  editDeadline.deadlineDate
                                              )
                                            : null
                                    }
                                    onChange={handleDeadlineDate}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <AlarmIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <TextField
                                label="Titel *"
                                fullWidth
                                value={
                                    editDeadline.title ? editDeadline.title : ""
                                }
                                onChange={handleChangeTitle}
                                variant="outlined"
                            />
                            <TextEdit
                                handleChange={handleContentChange}
                                title="Kurzbeschreibung *"
                                defaultValue={editDeadline}
                            />
                            <ImageUpload
                                errorState={false}
                                onChange={handleImageChange}
                                docData={deadlineImage}
                                name={"image "}
                                label={"Image "}
                                defaultFilename="image.jpg"
                            />

                            <Button
                                onClick={handleSaveDeadline}
                                color="primary"
                                startIcon={
                                    editDeadline.id ? <SaveIcon /> : <AddIcon />
                                }
                            >
                                {editDeadline.id ? (
                                    <>Speichern</>
                                ) : (
                                    <>Ereignis Erstellen</>
                                )}
                            </Button>
                            <Button
                                onClick={handleResetDeadline}
                                color="secondary"
                                startIcon={<CancelIcon />}
                            >
                                Abbrechen
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Paper>
        </>
    );
};

export default DeadlineListingTab;
