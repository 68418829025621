import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";

import IMandant from "../../../../types/IMandant";

export interface IMandantColorSchemeTabProps {
    mandantData: IMandant;
    onChange: (event: any) => void;
}

const MandantColorSchemeTab = (props: IMandantColorSchemeTabProps) => {
    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant="h3" className="main-header">
                            Basic Variablen
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="primaryColor"
                                    label="primary-color"
                                    fullWidth
                                    value={props.mandantData.primaryColor}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="gap"
                                    label="gap"
                                    fullWidth
                                    value={props.mandantData.gap}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    name="secondaryColor"
                                    label="secondary-color"
                                    fullWidth
                                    value={props.mandantData.secondaryColor}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant="h3" className="main-header">
                            Button Variablen
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="buttonTextColor"
                                    label="button-text-color"
                                    fullWidth
                                    value={props.mandantData.buttonTextColor}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="buttonBackground"
                                    label="button-background"
                                    fullWidth
                                    value={props.mandantData.buttonBackground}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="buttonBorder"
                                    label="button-border"
                                    fullWidth
                                    value={props.mandantData.buttonBorder}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className="gap-top-1">
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant="h3" className="main-header">
                            Header Variablen
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH1FontFamily"
                                    label="header-h1-font-family"
                                    fullWidth
                                    value={props.mandantData.headerH1FontFamily}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH2H3FontFamily"
                                    label="header-h2-h3-font-family"
                                    fullWidth
                                    value={
                                        props.mandantData.headerH2H3FontFamily
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH1Color"
                                    label="header-h1-color"
                                    fullWidth
                                    value={props.mandantData.headerH1Color}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="headerH1Weight"
                                    label="header-h1-weight"
                                    fullWidth
                                    value={props.mandantData.headerH1Weight}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH1Size"
                                    label="header-h1-size"
                                    fullWidth
                                    value={props.mandantData.headerH1Size}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH2Color"
                                    label="header-h2-color"
                                    fullWidth
                                    value={props.mandantData.headerH2Color}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="headerH2Weight"
                                    label="header-h2-weight"
                                    fullWidth
                                    value={props.mandantData.headerH2Weight}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH2Size"
                                    label="header-h2-size"
                                    fullWidth
                                    value={props.mandantData.headerH2Size}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH3Color"
                                    label="header-h3-color"
                                    fullWidth
                                    value={props.mandantData.headerH3Color}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="headerH3Weight"
                                    label="header-h3-weight"
                                    fullWidth
                                    value={props.mandantData.headerH3Weight}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="headerH3Size"
                                    label="header-h3-size"
                                    fullWidth
                                    value={props.mandantData.headerH3Size}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant="h3" className="main-header">
                            Text Variablen
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="textColor"
                                    label="text-color"
                                    fullWidth
                                    value={props.mandantData.textColor}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="textFontFamily"
                                    label="text-font-family"
                                    fullWidth
                                    value={props.mandantData.textFontFamily}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="textColorSmaller"
                                    label="text-color-smaller"
                                    fullWidth
                                    value={props.mandantData.textColorSmaller}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="colorLink"
                                    label="color-link"
                                    fullWidth
                                    value={props.mandantData.colorLink}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="textFontSize"
                                    label="text-font-size"
                                    fullWidth
                                    value={props.mandantData.textFontSize}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="textFontWeight"
                                    label="text-font-weight"
                                    fullWidth
                                    value={props.mandantData.textFontWeight}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="textFontSizeSmaller"
                                    label="text-font-size-smaller"
                                    fullWidth
                                    value={
                                        props.mandantData.textFontSizeSmaller
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="colorLinkHover"
                                    label="color-link-hover"
                                    fullWidth
                                    value={props.mandantData.colorLinkHover}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className="gap-top-1">
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant="h3" className="main-header">
                            Footer Variablen
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="textColorFooter1"
                                    label="text-color-footer1"
                                    fullWidth
                                    value={props.mandantData.textColorFooter1}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="linkColorFooter1"
                                    label="link-color-footer1"
                                    fullWidth
                                    value={props.mandantData.linkColorFooter1}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="textColorFooter2"
                                    label="text-color-footer2"
                                    fullWidth
                                    value={props.mandantData.textColorFooter2}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                                <TextField
                                    name="linkColorFooter2"
                                    label="link-color-footer2"
                                    fullWidth
                                    value={props.mandantData.linkColorFooter2}
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="backgroundColorFooter1"
                                    label="background-color-footer1"
                                    fullWidth
                                    value={
                                        props.mandantData.backgroundColorFooter1
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="linkColorFooter1Hover"
                                    label="link-color-footer1-hover"
                                    fullWidth
                                    value={
                                        props.mandantData.linkColorFooter1Hover
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="backgroundColorFooter2"
                                    label="background-color-footer2"
                                    fullWidth
                                    value={
                                        props.mandantData.backgroundColorFooter2
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />

                                <TextField
                                    name="linkColorFooter2Hover"
                                    label="link-color-footer2-hover"
                                    fullWidth
                                    value={
                                        props.mandantData.linkColorFooter2Hover
                                    }
                                    onChange={props.onChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MandantColorSchemeTab;
