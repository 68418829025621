import Paper from "@material-ui/core/Paper";
import React from "react";

import SimpleEntry, {
    ISimpleEntryParams,
} from "../../../../components/sidebar/Element/SimpeEntry";

interface IProjectDataSidebarParams {
    deadlines: ISimpleEntryParams[];
    events: ISimpleEntryParams[];
}

const ProjectDataSidebar = (props: IProjectDataSidebarParams) => {
    if (props.deadlines.length === 0) {
        return <></>;
    }

    return (
        <Paper className="App-sidebar" variant="outlined" square>
            {/*
            <h2 className="App-sidebar-h2">Aktuelles</h2>

            {props.events.length === 0 && (
                <p>Im Moment gibt es keine anstehenden Veranstaltungen.</p>
            )}
            {props.events.length > 0 && (
                <>
                    {props.events.map(
                        (event: ISimpleEntryParams, index: number) => {
                            return <SimpleEntry key={index} {...event} />;
                        }
                    )}
                </>
            )*/}

            {props.deadlines.length > 0 && (
                <>
                    <h2 className="App-sidebar-h2">Kommende Fristen</h2>
                    <>
                        {props.deadlines.map(
                            (deadline: ISimpleEntryParams, index: number) => {
                                return (
                                    <SimpleEntry key={index} {...deadline} />
                                );
                            }
                        )}
                    </>
                </>
            )}
        </Paper>
    );
};

export default ProjectDataSidebar;
