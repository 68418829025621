import "./ResetPassword.css";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconVpnKey from "@material-ui/icons/VpnKey";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Helmet } from "react-helmet";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import CmsImage from "../../../components/cms/CmsImage";
import ImageHeader from "../../../components/layout/ImageHeader";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import { updateUserdata } from "../../../store/userStore/userAction/UserAction";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { resetPasswordRequest } from "../../../xhr/UserRequests";

type IFormError = {
    token?: string;
    password?: string;
    passwordCheck?: string;
};

export type IResetPassword = {
    token?: string;
    password?: string;
    passwordCheck?: string;
};

type ILoginMapStateProps = {
    mandant: IMandant;
};

type ILoginDispatchProps = {
    dispatchUpdateUser: (userData: IUser) => void;
};

type IOwnProps = {
    history: any;
};

type ILoginParams = ILoginMapStateProps & ILoginDispatchProps & IOwnProps;

const ResetPassword = (props: ILoginParams) => {
    const { mandant } = props;

    const { mandantKey, token } = useParams<{
        mandantKey: string;
        token: string;
    }>();
    const history = useHistory();
    const [formData, setFormData] = React.useState<any>({
        token: token ? token : "",
    });

    const [errorState, setErrorState] = React.useState<IFormError>({});
    const [loading, setLoading] = React.useState<boolean>(false);

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const handleChange = (e: any) => {
        const newData = { ...formData, [e.target.name]: e.target.value };

        // @ts-ignore
        errorState["" + e.target.name] = null;
        setErrorState({ ...errorState });
        setFormData(newData);
    };

    const handleClickReset = () => {
        setLoading(true);

        resetPasswordRequest(mandantKey, formData)
            .then(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Ihr Passwort wurde geändert.",
                    type: "success",
                });
                setLoading(false);
                setFormData({});
                setErrorState({});

                history.push(`/${mandantKey}/anmelden`);
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Die Anfrage konnte nicht bearbeitet werden.",
                    type: "error",
                });
                setLoading(false);
                setErrorState({});
            });
    };

    return (
        <div className="portal-section">
            <Helmet>
                <title>Passwort zurücksetzen</title>
            </Helmet>

            <Header mandant={mandant} history={history} />

            <ImageHeader image={mandant.headerImage} />

            <Container maxWidth="md">
                <Grid container>
                    <Card
                        variant="outlined"
                        className="resetpwd-form-container gap-bottom"
                    >
                        <CmsImage
                            image={mandant.logoImage}
                            className="login-logo gap-top"
                        />
                        <CardContent className="resetpwd-form-content gap-bottom">
                            <Grid item xs={12} md={12}>
                                <div className="gap-top">
                                    <Typography className="gap-bottom">
                                        Hier können Sie sich ein neues Passwort
                                        setzen.
                                    </Typography>

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Token"
                                        variant="outlined"
                                        className="public-input-text"
                                        value={formData.token}
                                        type="text"
                                        onChange={handleChange}
                                        name="token"
                                    />

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Neues Passwort"
                                        variant="outlined"
                                        className="public-input-text"
                                        onChange={handleChange}
                                        name="password"
                                        type="password"
                                    />

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Passwort wiederholen"
                                        variant="outlined"
                                        className="public-input-text"
                                        type="password"
                                        onChange={handleChange}
                                        name="passwordCheck"
                                    />

                                    {loading && <CircularProgress />}
                                    {!loading && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            onClick={handleClickReset}
                                            className="button--primary"
                                            startIcon={<IconVpnKey />}
                                        >
                                            Passwort ändern
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};
/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    ILoginMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};
/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    ILoginDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<IApplicationRootState, {}, Action>) => {
    return {
        dispatchUpdateUser: (userData: IUser) =>
            dispatch(updateUserdata(userData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
