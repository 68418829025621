import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { logoutError, logoutSuccess } from "../userAction/UserAction";

const LogoutAction = (): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {
            //const response = await logoutRequest();
            //can make status check here
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutError(err.text));
        }
    };
};

export default LogoutAction;
