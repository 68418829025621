import "./ProjectForm.css";

import {
    Breadcrumbs,
    Button,
    LinearProgress,
    Link,
    Toolbar,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import arrayMove from "array-move";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import { dateStateFormat } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IDocument from "../../../types/IDocument";
import IEventFull from "../../../types/IEventFull";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import InitialProjectFullData from "../../../types/initialData/InitialProjectFullData";
import InitialProjectGalleryElementData from "../../../types/initialData/InitialProjectGalleryElementData";
import InitialProjectResultElementData from "../../../types/initialData/InitialProjectResultElementData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IProjectFull from "../../../types/IProjectFull";
import IProjectGalleryImage from "../../../types/IProjectGalleryImage";
import IProjectResult from "../../../types/IProjectResult";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import { getIds } from "../../../utils/getIds";
import {
    deleteEventRequest,
    switchEventPublishFlagRequest,
} from "../../../xhr/EventRequests";
import {
    adminGetSingleProjectRequest,
    createProjectRequest,
    loadProjectAttachmentsRequest,
    loadProjectProjectRequest,
    sortProjectFilesRequest,
    sortProjectImagesRequest,
    sortProjectResultsRequest,
    switchProjectPublishFlagRequest,
    updateProjectFileRequest,
    updateProjectGalleryRequest,
    updateProjectRequest,
    updateProjectResultsRequest,
} from "../../../xhr/ProjectRequests";
import { sortProjectTeamRequest } from "../../../xhr/TeamRequests";
import {
    IProjectValidationState,
    requiredProjectDataFilled,
} from "./projectDataValidation";
import ConsultingTab from "./tabs/ConsultingTab";
import DeadlineListingTab from "./tabs/DeadlineListingTab";
import EventListingTab from "./tabs/EventListingTab";
import ParticipationDescTab from "./tabs/ParticipationDescTab";
import ProjectDataTab from "./tabs/ProjectDataTab";
import ProjectFileTab from "./tabs/ProjectFileTab";
import ProjectGalleryTab from "./tabs/ProjectGalleryTab";
import ProjectMenuTab from "./tabs/ProjectMenuTab";
import ProjectResultsTab from "./tabs/ProjectResultsTab";
import ProjectTeamTab from "./tabs/ProjectTeamTab";
import ProjectTextTab from "./tabs/ProjectTextTab";

interface ILocalImageGallery {
    [key: string]: IProjectGalleryImage[];
}

interface IStateProps {
    mandant: IMandant;
}
interface IDispatchProps {
    ShowProgress: () => void;
}

interface IOwnProps {
    mandantKey: string;
    match: any;
    loading: boolean;
}

interface IMenuTabEditFormProps
    extends IOwnProps,
        IStateProps,
        IDispatchProps {}

const ProjectForm = (props: IMenuTabEditFormProps) => {
    const history = useHistory();
    const confirm = useConfirm();
    const urlParams = useParams<{ id: string; mandantkey: string }>();
    const { mandantKey } = props;

    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isEditForm, setIsEditForm] = React.useState<boolean>(
        !isNaN(props.match.params.id)
    );

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [projectData, setProjectData] = React.useState<IProjectFull>({
        ...InitialProjectFullData,
    });

    const [projectZoom, setProjectZoom] = React.useState<number>(0);

    const [errorState, setErrorState] = React.useState<IProjectValidationState>(
        { hasError: false }
    );

    /**
     * Gallery states
     */
    const [images, setImages] = React.useState<ILocalImageGallery>({});
    const [projectResults, setProjectResults] = React.useState<
        IProjectResult[]
    >([]);

    const [galleryLoading, setGalleryLoading] = React.useState<boolean>(false);
    /* load project data to state */
    const loadProjectToState = (projectId: string) => {
        setLoading(true);
        adminGetSingleProjectRequest(mandantKey, projectId)
            .then((response) => {
                setIsEditForm(true);
                setProjectZoom(response.data.zoom);
                setProjectData(response.data);

                loadProjectAttachmentsRequest(
                    mandantKey,
                    props.match.params.id
                ).then((res) => {
                    images.attachments = res.data ? [...res.data] : [];
                    images.default = response.data.images
                        ? [...response.data.images]
                        : [];

                    setImages(deepCopy<ILocalImageGallery>(images));
                });

                loadProjectProjectRequest(
                    mandantKey,
                    props.match.params.id
                ).then((res) => {
                    setProjectResults(
                        deepCopy<IProjectResult[]>(
                            res.data ? [...res.data] : []
                        )
                    );
                });

                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
            });
        setLoading(false);
    };
    /* load files attachments to state */

    /**
     * @param oldIndex
     * @param newIndex
     * @param type
     */
    const handleOnImagesOrderChange = (
        oldIndex: number,
        newIndex: number,
        type: string
    ) => {
        const arry = arrayMove(images[type], oldIndex, newIndex);

        setImages({
            ...images,
            [type]: arry,
        });
    };

    /**
     *
     */
    const handleOnImagesOrderSave = () => {
        setLoading(true);

        sortProjectImagesRequest(
            mandantKey,
            getIds(images.default),
            projectData.id
        )
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    default: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (isEditForm) {
            loadProjectToState(props.match.params.id);
        }
    }, [isEditForm]);

    /**
     *
     */
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    /**
     *
     */
    const handleProjectSave = () => {
        const theValidation = requiredProjectDataFilled(projectData);

        setErrorState(theValidation);

        if (theValidation.hasError) {
            return;
        }

        setLoading(true);

        if (isEditForm) {
            updateProjectRequest(mandantKey, {
                ...projectData,
                zoom: projectZoom,
            })
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Projektdaten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        } else {
            createProjectRequest(mandantKey, projectData)
                .then((response: any) => {
                    const url =
                        "/" +
                        mandantKey +
                        RoutesEnum.ADMIN_PROJECT +
                        response.data.id;
                    history.push(url);

                    setIsEditForm(true);
                    loadProjectToState(response.data.id);

                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Erstellen des Projektes ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        }
    };

    /**
     * @param sortedlistTeam
     */
    const updateProjectParticipantOrder = (sortedlistTeam: any) => {
        setLoading(true);

        sortProjectTeamRequest(mandantKey, sortedlistTeam, projectData.id)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setProjectData({
                    ...projectData,
                    team: response.data,
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /**
     */
    const onEventEditClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        history.push(
            "/" +
                props.mandant.key +
                RoutesEnum.ADMIN_PROJECT +
                urlParams?.id +
                RoutesEnum.ADMIN_SUB_EVENT +
                event.currentTarget.dataset.id
        );
    };

    /**
     */
    const onEventCreateProjectClick = () => {
        history.push(
            "/" +
                props.mandant.key +
                RoutesEnum.ADMIN_PROJECT +
                urlParams?.id +
                RoutesEnum.ADMIN_SUB_EVENT +
                "create"
        );
    };

    /**
     * @param event
     */
    const onEventDeleteClick = (event: IEventFull) => {
        confirm({
            description: "Möchten Sie diese Veranstaltung wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                setLoading(true);

                deleteEventRequest(event.id, mandantKey)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die Veranstaltung wurde gelöscht.",
                            type: "success",
                        });

                        projectData.events = projectData.events.filter(
                            (checkEvent: IEventList): boolean => {
                                return event.id !== checkEvent.id;
                            }
                        );

                        setProjectData(deepCopy<IProjectFull>(projectData));

                        setLoading(false);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Löschen der Veranstaltung ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                        setLoading(false);
                    });
            })
            .catch(() => {
                return;
            });
    };

    const onEventPublishClick = (event: IEventList) => {
        switchEventPublishFlagRequest(event.id, mandantKey)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: event.flagIsPublished
                        ? "Die Veranstaltung wurde depubliziert."
                        : "Die Veranstaltung wurde publiziert.",
                    type: "success",
                });

                // it's a reference. should work
                event.flagIsPublished = !event.flagIsPublished;
                setProjectData(deepCopy<IProjectFull>(projectData));
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Publizieren der Veranstaltung is ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    /*
      Handle generic change
     */
    const handleTextChange = (event: any) => {
        const newData = {
            ...projectData,
            [event.target.name]: event.target.value,
        };
        setProjectData(newData);
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...projectData, [attrKey]: image };
        setProjectData(newData);
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...projectData, [name]: markup };
        setProjectData(newData);
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...projectData,
            [event.target.name]: event.target.checked,
        };

        setProjectData(newData);
    };

    const handleProjectEndChange = (date: Date) => {
        setProjectData({
            ...projectData,
            theEnd: dateStateFormat(date),
        });
    };
    const handleProjectStartChange = (date: Date) => {
        setProjectData({
            ...projectData,
            theStart: dateStateFormat(date),
        });
    };

    const handleProjectPublishFlagRequest = () => {
        setLoading(true);

        switchProjectPublishFlagRequest(projectData.id, props.mandant.key)
            .then(() => {
                const message = projectData.flagPublished
                    ? "Das Projekt wurde offline genommen"
                    : "Das Projekt wurde publiziert.";
                setSnackBarState({
                    isOpen: true,
                    message,
                    type: "success",
                });

                setProjectData({
                    ...projectData,
                    flagPublished: !projectData.flagPublished,
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Die aktualisierte Projektliste konnte nicht geladen werden",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /*
     * Functions for the gallery tab
     */

    const handleGalleryUpload = (
        type: string,
        image: IProjectGalleryImage,
        index: number
    ): void => {
        images[type][index] = image;
        setImages(deepCopy<ILocalImageGallery>(images));
    };

    const handleGalleryDelete = (type: string, index: number): void => {
        confirm({
            description:
                "Bitte bestätigen sie, dass sie das Bild löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Bild aus der Galerie löschen",
        }).then(() => {
            images[type].splice(index, 1);
            setImages(deepCopy<ILocalImageGallery>(images));
        });
    };

    const handleGalleryItemReset = (
        type: string,
        index: number,
        imageId: number
    ): void => {
        const oldImage = projectData.images.find(
            (checkImg: IProjectGalleryImage) => {
                return checkImg.id === imageId;
            }
        );

        // will always exist
        if (oldImage) {
            images[type][index] = oldImage;
        }

        setImages(deepCopy<ILocalImageGallery>(images));
    };

    const handleGalleryCreateImage = (type: string): void => {
        const newImage = { ...InitialProjectGalleryElementData, type };

        if (images[type].length) {
            images[type] = [newImage].concat(images[type]);
        } else {
            images[type] = [newImage];
        }

        setImages(deepCopy<ILocalImageGallery>(images));
    };

    //updateProjectGalleryRequest

    const handleSaveImages = (type: string): void => {
        setGalleryLoading(true);
        updateProjectGalleryRequest(
            mandantKey,
            projectData.id,
            type,
            images[type]
        )
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });

                setProjectData({
                    ...projectData,
                    images: [...response.data],
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    /**
     *
     * @param type
     */
    const handleSaveFiles = (type: string): void => {
        setGalleryLoading(true);

        updateProjectFileRequest(mandantKey, projectData.id, type, images[type])
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });
                setProjectData({
                    ...projectData,
                    images: [...response.data],
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                //setLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    // Project Result

    /**
     *
     */
    const handleProjectResultCreate = (): void => {
        const newProjectResult = { ...InitialProjectResultElementData };
        projectResults.push(newProjectResult);
        setProjectResults(deepCopy<IProjectResult[]>(projectResults));
    };

    /**
     *
     */
    const handleSaveProjectResults = (): void => {
        setGalleryLoading(true);

        updateProjectResultsRequest(mandantKey, projectData.id, projectResults)
            .then((response: any) => {
                setProjectResults(deepCopy<IProjectResult[]>(response.data));

                setProjectData({
                    ...projectData,
                    projectResults: deepCopy<IProjectResult[]>(response.data),
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Ergebnisse wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim speichern der Ergebnisse ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    /**
     * @param oldIndex
     * @param newIndex
     */
    const handleOnProjectResultOrderChange = (
        oldIndex: number,
        newIndex: number
    ) => {
        const array = arrayMove(projectResults, oldIndex, newIndex);
        setProjectResults(deepCopy<IProjectResult[]>(array));
    };

    /**
     *
     */
    const handleOnProjectResultsOrderSave = () => {
        setLoading(true);

        sortProjectResultsRequest(
            mandantKey,
            getIds(projectResults),
            projectData.id
        )
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    default: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    const handleProjectResultItemReset = (
        index: number,
        resultId: number
    ): void => {
        const oldResult = projectData.projectResults.find(
            (checkRes: IProjectResult) => {
                return checkRes.id === resultId;
            }
        );

        // will always exist
        if (oldResult) {
            projectResults[index] = oldResult;
        }

        setProjectResults(deepCopy<IProjectResult[]>(projectResults));
    };

    const handleProjectResultUpload = (
        projectResult: IProjectResult,
        index: number
    ): void => {
        projectResults[index] = projectResult;
        setProjectResults(deepCopy<IProjectResult[]>(projectResults));
    };

    const handleProjectResultDelete = (index: number): void => {
        confirm({
            description:
                "Bitte bestätigen sie, dass sie das Ergebnis löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Ergebnis aus der Liste löschen",
        }).then(() => {
            projectResults.splice(index, 1);
            setProjectResults(deepCopy<IProjectResult[]>(projectResults));
        });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }
    /* handle backend save files order */
    const handleOnFileOrderSave = (type: string) => {
        setLoading(true);

        sortProjectFilesRequest(
            mandantKey,
            getIds(images[type]),
            projectData.id
        )
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    ...images,
                    [type]: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /**
     * @param zoom
     */
    const changeStateZoom = (zoom: number) => {
        setProjectZoom(zoom);
    };
    /* update location state */
    /**
     *
     * @param latitude
     * @param longitude
     * @param zoom
     */
    const handleProjectLocationChange = (
        latitude: number,
        longitude: number,
        zoom: number
    ) => {
        const newData = {
            ...projectData,
            latitude,
            longitude,
            zoom,
        };
        setProjectData(deepCopy(newData));
    };

    return (
        <>
            {loading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}
            {projectData.id === 0 && !isNaN(props.match.params.id) ? (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            ) : (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            color="inherit"
                            href={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                            data-url={
                                "/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD
                            }
                            onClick={handleBread}
                        >
                            Projekte verwalten
                        </Link>
                        <Typography color="textPrimary">
                            {isEditForm ? (
                                <>{projectData.title}</>
                            ) : (
                                <>Ein neues Projekt erstellen</>
                            )}
                        </Typography>
                    </Breadcrumbs>

                    <Toolbar disableGutters>
                        <Button
                            color="primary"
                            onClick={handleProjectSave}
                            startIcon={<SaveIcon />}
                        >
                            Projekt Speichern
                        </Button>
                        {projectData.id > 0 ? (
                            <Button
                                color="secondary"
                                onClick={handleProjectPublishFlagRequest}
                                startIcon={
                                    projectData.flagPublished ? (
                                        <IconUnPublish />
                                    ) : (
                                        <IconPublish />
                                    )
                                }
                            >
                                {projectData.flagPublished
                                    ? "Projekt depublizieren"
                                    : "Projekt publizieren"}
                            </Button>
                        ) : (
                            <> </>
                        )}
                    </Toolbar>

                    <TabContainer
                        isAdmin={true}
                        tabs={[
                            {
                                id: "tab-project-data",
                                label: "Projekt Daten",
                                display: true,
                                tabKey: 1,
                                content: (
                                    <ProjectDataTab
                                        onProjectLocationChange={
                                            handleProjectLocationChange
                                        }
                                        handleZoomChange={changeStateZoom}
                                        projectData={{
                                            ...projectData,
                                            zoom: projectZoom,
                                        }}
                                        mandantKey={props.mandantKey}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onCheckboxChange={handleCheckboxChange}
                                        onEditStart={handleProjectStartChange}
                                        onEditEnd={handleProjectEndChange}
                                        errorState={errorState}
                                    />
                                ),
                            },
                            {
                                id: "tab-menu",
                                label: "Projekt Menu",
                                display: !!projectData.flagEnableProjectCms,
                                tabKey: 2,
                                content: (
                                    <ProjectMenuTab
                                        mandantKey={mandantKey}
                                        projectData={projectData}
                                        setSnackBarState={setSnackBarState}
                                    />
                                ),
                            },
                            {
                                id: "tab-texts",
                                label: "Texte",
                                display: true,
                                tabKey: 4,
                                content: (
                                    <ProjectTextTab
                                        projectData={projectData}
                                        onChangeWysiwyg={handleWysiwygChange}
                                    />
                                ),
                            },
                            {
                                id: "tab-participate",
                                label: "Mitmachen",
                                display: !!projectData.flagTabParticipate,
                                tabKey: 5,
                                content: (
                                    <ParticipationDescTab
                                        projectData={projectData}
                                        onChangeWysiwyg={handleWysiwygChange}
                                    />
                                ),
                            },
                            {
                                id: "tab-consulting",
                                label: "Beratung",
                                display: !!projectData.flagTabConsulting,
                                tabKey: 6,
                                content: (
                                    <ConsultingTab
                                        projectData={projectData}
                                        onImageChange={handleImageChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                    />
                                ),
                            },
                            {
                                id: "tab-events",
                                label: "Veranstaltungen",
                                display: isEditForm,
                                tabKey: 7,
                                content: (
                                    <EventListingTab
                                        fallbackImage={
                                            props.mandant.defaultMenuImage
                                        }
                                        events={projectData.events}
                                        onPublishClick={onEventPublishClick}
                                        onCreateProjectClick={
                                            onEventCreateProjectClick
                                        }
                                        onEditClick={onEventEditClick}
                                        onDeleteClick={onEventDeleteClick}
                                    />
                                ),
                            },
                            {
                                id: "tab-project-results",
                                label: "Projekt Ergebnisse",
                                display: isEditForm,
                                tabKey: 8,
                                content: (
                                    <>
                                        <ProjectResultsTab
                                            projectResults={projectResults}
                                            label="Projekt Ergebnisse"
                                            onUpload={handleProjectResultUpload}
                                            onDelete={handleProjectResultDelete}
                                            onReset={
                                                handleProjectResultItemReset
                                            }
                                            onSave={handleSaveProjectResults}
                                            onImageCreate={
                                                handleProjectResultCreate
                                            }
                                            loadingGallery={galleryLoading}
                                            onSort={
                                                handleOnProjectResultOrderChange
                                            }
                                            handleSortSave={
                                                handleOnProjectResultsOrderSave
                                            }
                                        />
                                    </>
                                ),
                            },
                            {
                                id: "tab-image-gallery",
                                label: "Bilder Gallery",
                                display: isEditForm,
                                tabKey: 9,
                                content: (
                                    <>
                                        {images.default && (
                                            <ProjectGalleryTab
                                                images={images.default}
                                                label="Bilder Gallery"
                                                type="default"
                                                onUpload={handleGalleryUpload}
                                                onDelete={handleGalleryDelete}
                                                onResetImage={
                                                    handleGalleryItemReset
                                                }
                                                onSave={handleSaveImages}
                                                onImageCreate={
                                                    handleGalleryCreateImage
                                                }
                                                loadingGallery={galleryLoading}
                                                onSort={
                                                    handleOnImagesOrderChange
                                                }
                                                handleImagesSortSave={
                                                    handleOnImagesOrderSave
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            },
                            {
                                id: "tab-downloads",
                                label: "Datei Downloads",
                                display: isEditForm,
                                tabKey: 10,
                                content: (
                                    <>
                                        {images.attachments && (
                                            <ProjectFileTab
                                                images={images.attachments}
                                                label="Datei Downloads"
                                                loadingGallery={galleryLoading}
                                                type="attachments"
                                                onUpload={handleGalleryUpload}
                                                onDelete={handleGalleryDelete}
                                                onResetImage={
                                                    handleGalleryItemReset
                                                }
                                                onSave={handleSaveFiles}
                                                onImageCreate={
                                                    handleGalleryCreateImage
                                                }
                                                onSort={
                                                    handleOnImagesOrderChange
                                                }
                                                handleImagesSortSave={
                                                    handleOnFileOrderSave
                                                }
                                            />
                                        )}
                                    </>
                                ),
                            },
                            {
                                id: "tab-deadline-events",
                                label: "Ereignisse",
                                display: isEditForm,
                                tabKey: 11,
                                content: (
                                    <DeadlineListingTab
                                        deadlines={projectData.deadlines}
                                        mandant={props.mandant}
                                        projectID={projectData.id}
                                        onUpdate={() => {
                                            loadProjectToState(
                                                "" + projectData.id
                                            );
                                        }}
                                    />
                                ),
                            },
                            {
                                id: "tab-team",
                                label: "Team",
                                display: isEditForm,
                                tabKey: 12,
                                content: (
                                    <ProjectTeamTab
                                        project={projectData}
                                        mandantKey={mandantKey}
                                        onChangeWysiwyg={handleWysiwygChange}
                                        onRefreshProject={() => {
                                            loadProjectToState(
                                                "" + projectData.id
                                            );
                                        }}
                                        onUpdateParticipants={
                                            updateProjectParticipantOrder
                                        }
                                    />
                                ),
                            },
                        ]}
                        activeTab={activeTab}
                        ariaLabel={"Project Edit Form"}
                        idKey={"Project admin"}
                        handleChange={handleChange}
                    />

                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </>
            )}
        </>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IStateProps,
    {},
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        loading: state.User.loading,
    };
};
export default connect(mapStateToProps)(ProjectForm);
