import { AxiosResponse } from "axios";

import {
    ADMIN_CMS_PAGE,
    ADMIN_CMS_PAGE_SELECTBOX,
    PORTAL_LOAD_CMS_PAGE,
} from "../enums/Endpoints";
import ICmsPage, { ICmsPageChild, ICmsPageSyncBody } from "../types/ICmsPage";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISyncCmsPage from "./interface/ISyncCmsPage";

/**
 * Project list
 */
const loadPublicCmsPage = (mandant: string, pageKey: string): Promise<any> => {
    return getData<ISyncCmsPage>(PORTAL_LOAD_CMS_PAGE + pageKey, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadPublicCmsPageByProjectKey = (
    mandant: string,
    projectKey: string,
    pageKey: string
): Promise<any> => {
    return getData<ISyncCmsPage>(
        PORTAL_LOAD_CMS_PAGE + projectKey + "/" + pageKey,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

const loadCmsPageListRequest = (mandant: string): Promise<any> => {
    return getData<ISyncCmsPage>(ADMIN_CMS_PAGE, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadCmsPageProjectSelectboxRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<ICmsPageChild[]>> => {
    return getData<ICmsPageChild[]>(ADMIN_CMS_PAGE_SELECTBOX + projectId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadCmsPageRequest = (mandant: string, pageId: number): Promise<any> => {
    return getData<ISyncCmsPage>(ADMIN_CMS_PAGE + pageId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const createCmsPageRequest = (
    mandant: string,
    pageData: ICmsPageSyncBody
): Promise<any> => {
    return postData<ICmsPageSyncBody, ICmsPage>(ADMIN_CMS_PAGE, pageData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const updateCmsPageRequest = (
    mandant: string,
    pageId: number,
    pageData: ICmsPageSyncBody
): Promise<any> => {
    return putData<ICmsPageSyncBody, ICmsPage>(
        ADMIN_CMS_PAGE,
        pageId,
        pageData,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

const deleteCmsPageRequest = (
    mandant: string,
    pageId: number | string
): Promise<any> => {
    return deleteData<ISyncCmsPage>(ADMIN_CMS_PAGE, pageId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export {
    loadPublicCmsPage,
    loadCmsPageListRequest,
    loadCmsPageRequest,
    deleteCmsPageRequest,
    createCmsPageRequest,
    updateCmsPageRequest,
    loadCmsPageProjectSelectboxRequest,
    loadPublicCmsPageByProjectKey,
};
