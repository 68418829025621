import ICreateUser from "../ICreateUser";

const InitialCreateUserData: ICreateUser = {
    id: 0,
    salutation: "",
    lastname: "",
    firstname: "",
    nickName: "",
    street: "",
    streetNo: "",
    zip: "",
    city: "",
    email: "",
    displayTitle: "",
    password: "",
    passwordCheck: "",
};
export default InitialCreateUserData;
