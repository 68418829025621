import "./eventData.css";

import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import download from "downloadjs";
import ical from "ical-generator";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useParams } from "react-router-dom";

import AccordionUi from "../../../components/accordion/Accordion";
import ImageHeader from "../../../components/layout/ImageHeader";
import MapPicker from "../../../components/olMap/MapPicker";
import TabContainer, {
    ITab,
} from "../../../components/tabContainer/TabContainer";
import { AccordionMinWidth, Format } from "../../../config/config";
import useWindowSize from "../../../customhooks/useWindowSize";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IEventFull from "../../../types/IEventFull";
import IMandant from "../../../types/IMandant";
import InitialEventFullData from "../../../types/initialData/InitialEventFullData";
import IProjectFull from "../../../types/IProjectFull";
import ITheme from "../../../types/ITheme";
import { loadPublicEventData } from "../../../xhr/EventRequests";
import EventDataSidebar from "./EventDataSidebar";
import AboutTab from "./tabs/AboutTab";
import CommentTab from "./tabs/CommentTab";
import ReportTab from "./tabs/ReportTab";

interface IMapStateProps {
    mandant: IMandant;
    mandantTheme: ITheme;
    actualUser: IUser;
}

interface IOwnProps {
    history: any;
}

interface IEventDataProps extends IMapStateProps, IOwnProps {}

const EventData = (props: IEventDataProps) => {
    const WindowWidth = useWindowSize();
    const { mandantKey, id }: { mandantKey: string; id: string } = useParams();
    const [eventDataState, setEventData] = React.useState<IEventFull>({
        ...InitialEventFullData,
    });
    const { mandant, history, actualUser } = props;
    const [activeTab, setActiveTab] = React.useState(1);

    React.useEffect(() => {
        loadPublicEventData(mandantKey, id).then((result) => {
            setEventData(result.data);
            return Promise.resolve();
        });
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, tabIdx: number) => {
        setActiveTab(tabIdx);
    };

    if (!eventDataState?.id) {
        return <></>;
    }

    const onClickProject = (event: React.SyntheticEvent<any>) => {
        history.push(
            "/" +
                mandant.key +
                RoutesEnum.PORTAL_PROJECT +
                "/" +
                event.currentTarget.dataset.id
        );
    };

    const project: IProjectFull = eventDataState.project as IProjectFull;
    const handleClickDownloadEvent = () => {
        const cal = ical({ name: eventDataState.title });
        cal.createEvent({
            start: eventDataState.theStart
                ? new Date(eventDataState.theStart)
                : "",
            end: eventDataState.theEnd ? new Date(eventDataState.theEnd) : "",
            summary: "" + eventDataState.shortDesc,
            description: "" + eventDataState.shortDesc,
            location:
                eventDataState.locationName + " " + eventDataState.locationUrl,
        });
        const dataTodownload = cal.toString();
        download(
            dataTodownload,
            eventDataState.title + ".ics",
            "text/calendar"
        );
    };

    const showBericht =
        !!eventDataState.descriptionAfter ||
        !!eventDataState.imagesAfter.length;

    const getTabs = (): ITab[] => {
        return [
            {
                id: "tab-event-report",
                label: "Veranstaltungsbericht",
                tabKey: 1,
                display: showBericht,
                content: <ReportTab event={eventDataState} />,
            },
            {
                id: "tab-event-content",
                label: "Über die Veranstaltung",
                tabKey: 2,
                display: !showBericht,
                content: <AboutTab event={eventDataState} />,
            },
            {
                id: "tab-comments",
                label: "Kommentare",
                tabKey: 3,
                display: false,
                content: (
                    <CommentTab
                        event={eventDataState}
                        user={actualUser}
                        mandantKey={mandantKey}
                    />
                ),
            },
        ];
    };
    const fitStyle = () => {
        if (WindowWidth > AccordionMinWidth) {
            return {};
        } else {
            return { margin: "2px" };
        }
    };

    return (
        <div className="portal-section">
            <Helmet>
                <title>Veranstaltung: {eventDataState.title}</title>
            </Helmet>

            <Header mandant={mandant} history={history} />

            <ImageHeader
                isBig={mandant.key === "kernen"}
                image={
                    eventDataState.imageHeader
                        ? eventDataState.imageHeader
                        : mandant.headerImage
                }
            />
            <div className="portal-section-body-position">
                <div className={"App-main"} style={fitStyle()}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} sm={12} md={8} lg={9}>
                            <div className="App-data-container">
                                <Typography
                                    className="App-data-header"
                                    gutterBottom
                                    variant="h2"
                                >
                                    {eventDataState.title}
                                </Typography>
                                <Grid container>
                                    <Typography
                                        className="App-data-header2"
                                        gutterBottom
                                        variant="h3"
                                    >
                                        ab:{" "}
                                        {moment(eventDataState.theStart).format(
                                            Format.dateTime
                                        )}{" "}
                                        bis:{" "}
                                        {moment(eventDataState.theEnd).format(
                                            /*  */
                                            "HH:mm"
                                        )}
                                    </Typography>
                                    <div title="Klicken Sie hier zum Herunterladen">
                                        <CalendarTodayIcon
                                            className="calendar-button"
                                            color="primary"
                                            onClick={handleClickDownloadEvent}
                                            fontSize="small"
                                        />
                                    </div>
                                </Grid>

                                <Typography
                                    className="App-data-header2 clickable"
                                    gutterBottom
                                    variant="h3"
                                    onClick={onClickProject}
                                    data-id={project.id}
                                >
                                    Zurück zum Projekt: {project.title}
                                </Typography>
                                {WindowWidth > AccordionMinWidth ? (
                                    <TabContainer
                                        tabs={getTabs()}
                                        activeTab={activeTab}
                                        ariaLabel={"Public Event Data"}
                                        idKey={"event Data"}
                                        handleChange={handleChange}
                                    />
                                ) : (
                                    <AccordionUi tabs={getTabs()} />
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Grid item xs={12} className="gap-bottom">
                                <Paper
                                    className="App-sidebar"
                                    variant="outlined"
                                    square
                                >
                                    <h2 className="App-sidebar-h2">Karte</h2>
                                    <MapPicker data={eventDataState} />
                                </Paper>
                            </Grid>
                            <EventDataSidebar
                                participants={eventDataState.participants}
                                history={props.history}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        mandantTheme: state.mandant.mandantTheme,
        actualUser: state.User.userData as IUser,
    };
};

export default connect(mapStateToProps)(EventData);
