import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isStillAuthenticated } from "../../utils";

const PrivateRoute: React.FC<{
    component: any;
    path: string;
    exact?: boolean;
    mandantKey: string;
}> = (props) => {
    const condition = isStillAuthenticated(props.mandantKey);

    const redirectUrl = "/" + props.mandantKey + "/datenverwaltung/login";

    return condition ? (
        <Route
            path={props.path}
            exact={props.exact}
            render={(renderProps: any) => (
                <props.component
                    {...renderProps}
                    mandantKey={props.mandantKey}
                />
            )}
        />
    ) : (
        <Redirect to={redirectUrl} />
    );
};
export default PrivateRoute;
