import { AxiosResponse } from "axios";

import {
    ADMIN_LOAD_PROJECT_SELECTBOX,
    ADMIN_PROJECT,
    ADMIN_PROJECT_MENU,
    ADMIN_PUBLISH_PROJECT,
    ADMIN_SORT_IMAGES_DEFAULT,
    PORTAL_LOAD_PROJECT_SELECTBOX,
    PORTAL_LOAD_PROJECT_SELECTBOX_ALL,
    PORTAL_PROJECT,
    PORTAL_PROJECT_MENU,
    PORTAL_PROJECT_TEAM,
    PROJECT_RESULTS_SORT,
    UPLOADS_ATTACHEMENTS_SORT,
} from "../enums/Endpoints";
import {
    IMenuEntry,
    IProjectMenu,
} from "../masks/admin/projectForm/tabs/ProjectMenuTab";
import IId from "../types/IId";
import IProjectFull from "../types/IProjectFull";
import IProjectGalleryImage from "../types/IProjectGalleryImage";
import IProjectResult from "../types/IProjectResult";
import ITeam from "../types/ITeam";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISelectOption from "./interface/ISelectOption";
import ISyncProjectFull from "./interface/ISyncProjectFull";
import ISyncProjectList from "./interface/ISyncProjectList";

/**
 * Public Project list
 */

const loadPublicProjectListRequest = (mandant: string): Promise<any> => {
    return getData<ISyncProjectList>(PORTAL_PROJECT, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/**
 * get the project selectbox list
 * @param mandant
 */
const loadAdminProjectSelectboxRequest = (mandant: string): Promise<any> => {
    return getData<ISelectOption[]>(ADMIN_LOAD_PROJECT_SELECTBOX, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Public Project selectBox list
 */

const loadPublicProjectSelectboxRequest = (mandant: string): Promise<any> => {
    return getData<ISyncProjectList>(PORTAL_LOAD_PROJECT_SELECTBOX, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadProjectAllListRequest = (mandant: string): Promise<any> => {
    return getData<ISyncProjectList>(PORTAL_LOAD_PROJECT_SELECTBOX_ALL, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/* Push project public flag */
const switchProjectPublishFlagRequest = (
    projectId: number,
    mandant: string
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PUBLISH_PROJECT,
        projectId,
        {},
        { mandant, withLang: true, isPublic: false }
    );
};
/*  loadPublicProjectData*/
const loadPublicProjectDataRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<ISyncProjectFull>> => {
    return getData<ISyncProjectFull>(PORTAL_PROJECT + projectId, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};
/* loadAdminProjects */

const loadProjectsRequest = (mandant: string): Promise<any> => {
    return getData<ISyncProjectFull>(ADMIN_PROJECT, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadProjectAttachmentsRequest = (
    mandant: string,
    projectId: string
): Promise<any> => {
    return getData<any>(`${ADMIN_PROJECT}${projectId}/uploads/attachments/`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/* adminGetSingleProject */
const adminGetSingleProjectRequest = (
    mandant: string,
    id: string
): Promise<any> => {
    return getData<ISyncProjectFull>(`${ADMIN_PROJECT}${id}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/*  adminadminDeleteSingleProject*/

const deleteProjectRequest = (id: number, mandant: string): Promise<any> => {
    return deleteData<ISyncProjectFull>(ADMIN_PROJECT, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/* UPDATE PROJECT */
const updateProjectRequest = (mandant: string, project: any): Promise<any> => {
    return putData<any, string>(ADMIN_PROJECT, project.id, project, {
        mandant,
        withLang: true,
        isPublic: false,
    });
};

const createProjectRequest = (
    mandant: string,
    project: IProjectFull
): Promise<any> => {
    return postData<any, any>(ADMIN_PROJECT, project, {
        mandant,
        withLang: true,
        isPublic: false,
    });
};

const updateProjectGalleryRequest = (
    mandant: string,
    projectId: number,
    type: string,
    images: IProjectGalleryImage[]
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT + projectId + "/gallery/",
        type,
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
const sortProjectImagesRequest = (
    mandant: string,
    images: any,
    projectId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT,
        projectId + ADMIN_SORT_IMAGES_DEFAULT,
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

const updateProjectFileRequest = (
    mandant: string,
    projectId: number,
    type: string,
    images: IProjectGalleryImage[]
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT + projectId + "/uploads/",
        "attachments/",
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};
const sortProjectFilesRequest = (
    mandant: string,
    images: any,
    projectId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT,
        projectId + UPLOADS_ATTACHEMENTS_SORT,
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

const loadProjectProjectRequest = (
    mandant: string,
    projectId: string
): Promise<any> => {
    return getData<any>(`${ADMIN_PROJECT}${projectId}/results/`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const updateProjectResultsRequest = (
    mandant: string,
    projectId: number,
    projectResults: IProjectResult[]
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT + projectId + "/results/",
        "",
        projectResults,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

const sortProjectResultsRequest = (
    mandant: string,
    images: any,
    projectId: number
): Promise<any> => {
    return putData<any, string>(
        ADMIN_PROJECT,
        projectId + PROJECT_RESULTS_SORT,
        images,
        {
            mandant,
            withLang: true,
            isPublic: false,
        }
    );
};

const loadProjectMenuRequest = (
    mandant: string,
    projectId: string
): Promise<any> => {
    return getData<any>(`${ADMIN_PROJECT_MENU}${projectId}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadPublicProjectMenuRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<IProjectMenu>> => {
    return getData<IProjectMenu>(`${PORTAL_PROJECT_MENU}${projectId}`, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

export interface IProjectMenuSync {
    id: string;
    project: IId;
    content: IMenuEntry[];
}

const createProjectMenuRequest = (
    mandant: string,
    menuData: IProjectMenu
): Promise<AxiosResponse<IProjectMenu>> => {
    return postData<IProjectMenuSync, IProjectMenu>(
        `${ADMIN_PROJECT_MENU}`,
        {
            ...menuData.data,
            content: menuData.content,
        },
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

const updateProjectMenuRequest = (
    mandant: string,
    menuData: IProjectMenu
): Promise<AxiosResponse<IProjectMenu>> => {
    return putData<IProjectMenuSync, IProjectMenu>(
        `${ADMIN_PROJECT_MENU}`,
        menuData.data.id,
        {
            ...menuData.data,
            content: menuData.content,
        },
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

const loadPublicProjectTeamListRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<{ [key: string]: ITeam }>> => {
    return getData<{ [key: string]: ITeam }>(
        `${PORTAL_PROJECT_TEAM}${projectId}`,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

export {
    loadPublicProjectListRequest,
    loadPublicProjectDataRequest,
    loadPublicProjectSelectboxRequest,
    loadProjectsRequest,
    adminGetSingleProjectRequest,
    updateProjectRequest,
    deleteProjectRequest,
    switchProjectPublishFlagRequest,
    createProjectRequest,
    updateProjectGalleryRequest,
    loadProjectAllListRequest,
    sortProjectImagesRequest,
    loadProjectAttachmentsRequest,
    updateProjectFileRequest,
    sortProjectFilesRequest,
    loadAdminProjectSelectboxRequest,
    loadProjectProjectRequest,
    updateProjectResultsRequest,
    sortProjectResultsRequest,
    loadProjectMenuRequest,
    createProjectMenuRequest,
    updateProjectMenuRequest,
    loadPublicProjectMenuRequest,
    loadPublicProjectTeamListRequest,
};
