import ICampaignRoom from "../appointment/ICampaignRoom";

const InitialCampaignRoom: ICampaignRoom = {
    id: 0,
    campaign: { id: 0 },
    name: "",
    assignedName: "",
    assignedEmail: "",
};
export default InitialCampaignRoom;
