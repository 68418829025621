import React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import LoadMandantAction from "../../store/mandantStore/LoadMandant";
import LoadProjectSelectbox from "../../store/projectSelectbox/LoadProjectSelectbox";
import IApplicationRootState from "../../store/StoreTypes";
import IUser from "../../store/userStore/interfaces/IUser";
import IMandant from "../../types/IMandant";
import { tokenRefreshRequest } from "../../xhr/UserRequests";

interface IMapStateProps {
    mandant: IMandant;
    user: IUser | null;
}

interface IMapDispatchProps {
    handleLoadMandant: (mandantKey: string) => void;
}

interface IOwnProps {
    mandantKey: string;
}

interface IMandantProviderProps
    extends IMapStateProps,
        IMapDispatchProps,
        IOwnProps {}

/** component */

const MandantProvider: React.FunctionComponent<IMandantProviderProps> = (
    props
) => {
    const { mandantKey, handleLoadMandant } = props;
    React.useEffect(() => {
        handleLoadMandant(mandantKey);

        const interval = setInterval(() => {
            const oldToken = localStorage.getItem("jwt-" + mandantKey) || "";

            const tokenTime = new Date(
                localStorage.getItem("expiresAt-" + mandantKey) || ""
            );
            const tokenTimeUtc = new Date(
                Date.UTC(
                    tokenTime.getFullYear(),
                    tokenTime.getMonth(),
                    tokenTime.getDate(),
                    tokenTime.getHours(),
                    tokenTime.getMinutes(),
                    tokenTime.getSeconds()
                )
            );

            const limitTime = new Date().getTime() + 300000; // 300000 milliseconds = 5 minutes

            if (tokenTimeUtc.getTime() < limitTime) {
                tokenRefreshRequest(mandantKey, { token: oldToken })
                    .then((response) => {
                        localStorage.setItem(
                            "jwt-" + mandantKey,
                            response.data.token
                        );
                        localStorage.setItem(
                            "expiresAt-" + mandantKey,
                            response.data.expiresAt
                        );
                    })
                    .catch((err: any) => {
                        if (err?.response?.status === 401) {
                            clearInterval(interval);
                        }
                    });
            }
        }, 15000);
        return () => clearInterval(interval);
    }, [handleLoadMandant]);

    return <></>;
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        user: state.User.userData,
    };
};
/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        handleLoadMandant: (mandantKey: string) => {
            if (mandantKey === "") {
                return Promise.resolve();
            }

            dispatch(LoadProjectSelectbox(mandantKey));
            return dispatch(LoadMandantAction(mandantKey));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MandantProvider);
