import Paper from "@material-ui/core/Paper";
import React from "react";

import PersonTile from "../../../components/personTile/PersonTile";
import ITeam from "../../../types/ITeam";

interface IProjectDataSidebarParams {
    history: any;
    participants: ITeam[];
}

const EventDataSidebar = (props: IProjectDataSidebarParams) => {
    if (props.participants.length === 0) {
        return <></>;
    }

    const hasAvatar: boolean =
        props.participants.filter((team: ITeam) => {
            // eslint-disable-next-line eqeqeq
            return team.image?.url != null;
        }).length > 0;

    return (
        <>
            <Paper className="App-sidebar" variant="outlined" square>
                <h2 className="App-sidebar-h2">Referent*innen</h2>
                {props.participants.map((participant: ITeam, index: number) => {
                    return (
                        <PersonTile
                            hasAvatar={hasAvatar}
                            key={index}
                            {...participant}
                            minData={true}
                        />
                    );
                })}
            </Paper>
        </>
    );
};

export default EventDataSidebar;
