import { Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import { API_URL_STATIC } from "../../../../config/config";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectConsultingTabProps {
    project: IProjectFull;
    mandantKey: string;
    actualUser: IUser | null;
}

function ProjectConsultingTab(props: IProjectConsultingTabProps) {
    const { project } = props;

    return (
        <>
            <Typography gutterBottom variant="h2" className="gap-top">
                Beratung
            </Typography>
            <HtmlContent
                className="content-block"
                content={project.consultingText}
            />

            {project?.imageConsulting?.url && (
                <>
                    <img
                        src={API_URL_STATIC + project?.imageConsulting?.url}
                        alt={project?.imageConsulting?.description}
                        style={{ maxWidth: "100%" }}
                    />
                </>
            )}
        </>
    );
}

export default ProjectConsultingTab;
