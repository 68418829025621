import {
    Checkbox,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";

import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IMandant from "../../../../types/IMandant";

export interface IMandantInfoTabProps {
    mandantData: IMandant;
    onChangeWysiwyg: (event: any, name: string) => void;
    onChange: (event: any) => void;
    onCheckboxChange: (event: any) => void;
}

const MandantInfoTab = (props: IMandantInfoTabProps) => {
    const { mandantData, onChangeWysiwyg, onCheckboxChange } = props;

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header-with-gap">
                        Login Seite
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mandantData.flagDisabledLogin}
                                    onChange={onCheckboxChange}
                                    name="flagDisabledLogin"
                                />
                            }
                            label="Ist der Login für Anwender deaktiviert?"
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mandantData.flagSimpleLogin}
                                    onChange={onCheckboxChange}
                                    name="flagSimpleLogin"
                                />
                            }
                            label="Soll die einfache Loginseite angezeigt werden?"
                        />
                    </FormGroup>

                    <TextEdit
                        handleChange={(event: any) => {
                            onChangeWysiwyg(event, "textRegisterDescription");
                        }}
                        title="Beschreibung Login Regeln"
                        defaultValue={
                            mandantData.textRegisterDescription || " "
                        }
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <TextField
                        name="textRegisterConfirm"
                        label="Bestätigungstext Anmelden"
                        fullWidth
                        multiline={true}
                        value={props.mandantData.textRegisterConfirm}
                        onChange={props.onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MandantInfoTab;
