import { Alert } from "@material-ui/lab";
import React from "react";

import Comments from "../../../../components/comment/Comments";
import ProposalMap from "../../../../components/olMap/proposal/ProposalMap";
import RoutesEnum from "../../../../routes/RoutesEnum";
import IUser from "../../../../store/userStore/interfaces/IUser";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectParticipateTabProps {
    project: IProjectFull;
    mandantKey: string;
    actualUser: IUser | null;
    history: any;
}

function ProjectParticipateTab(props: IProjectParticipateTabProps) {
    const { project, mandantKey, actualUser, history } = props;

    const onClickAnmelden = () => {
        history.push("/" + mandantKey + RoutesEnum.PORTAL_LOGIN);
    };

    return (
        <>
            {!actualUser && (
                <Alert severity="info" className="gap-top">
                    Willkommen auf der &quot;mitmachen&quot; Seite. Um selbst
                    Kommentare und Vorschläge zu erstellen{" "}
                    <a onClick={onClickAnmelden} className="clickable">
                        melden Sie sich bitte zuerst im Portal an.
                    </a>
                </Alert>
            )}

            {project.flagMapComments && (
                <ProposalMap project={project} signedUser={actualUser} />
            )}

            {project.flagCommentsOpen && (
                <div className="project-comments">
                    <Comments
                        project={project}
                        projectId={project.id}
                        projectRoles={project.roles}
                        mandantKey={mandantKey}
                        user={actualUser}
                    />
                </div>
            )}
        </>
    );
}

export default ProjectParticipateTab;
