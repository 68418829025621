import "./imageUpload.css";

import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Fab from "@material-ui/core/Fab";
import { AddPhotoAlternateOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";
import InitialDocumentData from "../../types/initialData/InitialDocumentData";

interface IImageUploadProps {
    name: string;
    label: string;
    docData: IDocument;
    defaultFilename: string;
    onChange: (image: IDocument, key: string) => void;
    errorState?: boolean;
}

const ImageUpload = (props: IImageUploadProps) => {
    const { docData, onChange, errorState } = props;

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // const url =

        reader.onloadend = () => {
            onChange(
                {
                    ...docData,
                    data: reader.result,
                    name: file.name,
                    size: file.size,
                },
                props.name
            );
        };
    };

    const handleResetClick = () => {
        onChange(
            {
                ...InitialDocumentData,
                id: docData.id,
                type: docData.type,
                key: docData.key,
            },
            props.name
        );
    };

    const getImageSrc = (image: IDocument) => {
        if (image?.data) {
            return image.data;
        }
        if (image?.url) {
            return API_URL_STATIC + image.url;
        }

        return null;
    };

    const handleChange = (event: any) => {
        const newData = { ...docData, [event.target.name]: event.target.value };
        onChange(newData, props.name);
    };

    const imageSource = getImageSrc(docData);

    return (
        <Card variant="outlined" className="upload-image-card">
            <CardActionArea disableRipple={true}>
                <CardMedia title={props.label}>
                    <></>
                </CardMedia>
                <CardContent>
                    {errorState ? (
                        <Typography variant="h3" style={{ color: "red" }}>
                            {props.label}
                        </Typography>
                    ) : (
                        <Typography variant="h3">{props.label}</Typography>
                    )}

                    <input
                        accept="image/*"
                        id={"contained-button-file" + props.name}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleUploadClick}
                    />
                    <label htmlFor={"contained-button-file" + props.name}>
                        {imageSource && (
                            <img src={imageSource} alt={"fresh uploaded"} />
                        )}
                        {!imageSource && (
                            <Fab component="span">
                                <AddPhotoAlternateOutlined />
                            </Fab>
                        )}
                    </label>
                    {imageSource && (
                        <>
                            <TextField
                                name="name"
                                label="Bildname"
                                fullWidth
                                value={
                                    docData?.name
                                        ? docData?.name
                                        : props.defaultFilename
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="description"
                                label="Beschreibung"
                                className={"description-input"}
                                fullWidth
                                value={
                                    docData?.description
                                        ? docData?.description
                                        : ""
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="copyright"
                                label="Copyright *"
                                fullWidth
                                value={
                                    docData?.copyright ? docData?.copyright : ""
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </>
                    )}
                </CardContent>
            </CardActionArea>
            {imageSource && (
                <CardActions>
                    <Button
                        onClick={handleResetClick}
                        color="secondary"
                        startIcon={<CancelIcon />}
                    >
                        Bild entfernen
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default ImageUpload;
