import { LinearProgress, Toolbar } from "@material-ui/core";
import React from "react";

import ICmsPage from "../../../../types/ICmsPage";
import IDocument from "../../../../types/IDocument";
import InitialCmsPageData from "../../../../types/initialData/InitialCmsPageData";
import ITeam from "../../../../types/ITeam";
import {
    loadPublicCmsPage,
    loadPublicCmsPageByProjectKey,
} from "../../../../xhr/CmsPageRequests";
import CmsPageRenderer from "./CmsPageRenderer";

interface ICmsPageLoaderPropsProps {
    mandantKey: string;
    pageKey: string;
    projectKey?: string;
}

const CmsPageLoader = (props: ICmsPageLoaderPropsProps) => {
    const { mandantKey, pageKey, projectKey } = props;

    const [pageData, setPageData] = React.useState<ICmsPage>({
        ...InitialCmsPageData,
    });
    const [images, setImages] = React.useState<{ [key: string]: IDocument }>(
        {}
    );
    const [uploads, setUploads] = React.useState<{ [key: string]: IDocument }>(
        {}
    );
    const [teams, setTeams] = React.useState<{ [key: string]: ITeam }>({});

    const [lastPageKey, setLastPageKey] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const loadPageToState = () => {
        setLoading(true);
        setLastPageKey(mandantKey + "-/-" + pageKey);

        if (projectKey) {
            loadPublicCmsPageByProjectKey(mandantKey, projectKey, pageKey)
                .then((result: any) => {
                    setPageData({
                        data: result.data.data,
                        elements: result.data.elements,
                    });

                    setImages(result.data.images);
                    setUploads(result.data.uploads);
                    setTeams(result.data.teams);

                    setLoading(false);
                    return Promise.resolve();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            loadPublicCmsPage(mandantKey, pageKey)
                .then((result: any) => {
                    setPageData({
                        data: result.data.data,
                        elements: result.data.elements,
                    });

                    setImages(result.data.images);
                    setUploads(result.data.uploads);
                    setTeams({});

                    setLoading(false);
                    return Promise.resolve();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    React.useEffect(() => {
        loadPageToState();
    }, [mandantKey, pageKey, projectKey]);

    // team tile as grid optimized for display in mobile
    // image
    // document
    // gallery

    if (lastPageKey !== mandantKey + "-/-" + pageKey) {
        setLastPageKey(mandantKey + "-/-" + pageKey);
        loadPageToState();
    }

    if (loading) {
        return (
            <Toolbar disableGutters={true}>
                <LinearProgress color="secondary" />
            </Toolbar>
        );
    }

    return (
        <CmsPageRenderer
            pageData={pageData}
            images={images}
            uploads={uploads}
            teams={teams}
        />
    );
};

export default CmsPageLoader;
