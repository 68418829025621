import { Grid, Typography } from "@material-ui/core";
import React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import logo from "../../assets/images/logo.png";
import CmsImage from "../../components/cms/CmsImage";
import SwipableDrawer from "../../components/SwipableDrawer/SwipableDrawer";
import { AccordionMinWidth } from "../../config/config";
import useWindowSize from "../../customhooks/useWindowSize";
import RoutesEnum from "../../routes/RoutesEnum";
import IApplicationRootState from "../../store/StoreTypes";
import LogoutAction from "../../store/userStore/asyncActions/LogoutAction";
import IHeaderEntry from "../../types/IHeaderEntry";
import IMandant from "../../types/IMandant";
import ISelectOption from "../../xhr/interface/ISelectOption";

interface IPortalHeaderMapStateProps {
    mandant: IMandant;
    userData: any;
    projectData: ISelectOption[];
}

interface IPortalHeaderMapDispatchProps {
    handleLogout: () => void;
}

interface IOwnProps {
    history: any;
    mandant: IMandant;
}
interface IPortalHeaderParams
    extends IPortalHeaderMapStateProps,
        IPortalHeaderMapDispatchProps,
        IOwnProps {}

const PortalHeader = (props: IPortalHeaderParams) => {
    const { history, mandant, handleLogout } = props;
    let { userData } = props;
    const { mandantKey }: { mandantKey: string } = useParams();

    // userdata check if it matches the mandant
    if (userData?.mandant?.key && userData?.mandant?.key !== mandantKey) {
        userData = null;
    }

    /*
    const isMobile = useMediaQuery({
        query: "(min-device-width: 970px)",
    });
     */
    const WindowWidth = useWindowSize();

    const linkToProjects = () => {
        history.push("/" + mandant.key + RoutesEnum.PORTAL_PROJECT_LIST);
    };

    const linkToContact = () => {
        if (mandant.flagDisableContact) {
            history.push(
                "/" +
                    mandant.key +
                    RoutesEnum.PORTAL_PROJECT +
                    "/" +
                    props.projectData[0].id +
                    "#team"
            );
        } else {
            history.push("/" + mandant.key + RoutesEnum.PORTAL_CONTACT);
        }
    };

    const linkToLogin = () => {
        history.push("/" + mandant.key + RoutesEnum.PORTAL_LOGIN);
    };
    const LogoutHandler = () => {
        handleLogout();
        linkToProjects();
    };

    const entries: IHeaderEntry[] = [
        {
            label: "Start",
            onClick: linkToProjects,
        },
        {
            label: "Kontakt",
            onClick: linkToContact,
        },
        {
            label: userData ? "Logout" : "ANMELDEN",
            onClick: userData ? LogoutHandler : linkToLogin,
        },
    ];
    const notMobileView = () => {
        return (
            <Grid item xs={5} md={5} lg={7}>
                <Grid container justifyContent="flex-start">
                    <nav className="App-header-menu">
                        <ul>
                            <li
                                className="App-header-menu--entry"
                                onClick={linkToProjects}
                            >
                                Start
                            </li>
                            <li
                                className="App-header-menu--entry"
                                onClick={linkToContact}
                            >
                                Kontakt
                            </li>
                            {!mandant.flagDisabledLogin && (
                                <>
                                    {userData && (
                                        <li
                                            className="App-header-menu--entry"
                                            onClick={LogoutHandler}
                                        >
                                            Logout
                                        </li>
                                    )}
                                    {!userData && (
                                        <li className="App-header-menu--entry">
                                            <button
                                                onClick={linkToLogin}
                                                className="App-header-button--button button--primary"
                                            >
                                                Anmelden
                                            </button>
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                    </nav>
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            <Grid container>
                <Grid
                    container
                    className={WindowWidth > 530 ? "App-header" : ""}
                >
                    <Grid item xs={2} md={2} lg={1}>
                        <div>
                            <a href={mandant.urlWebsite} target="__blank">
                                <CmsImage
                                    className="App-header-logo-customer"
                                    image={mandant.logoImage}
                                />
                            </a>
                        </div>
                    </Grid>
                    {WindowWidth > AccordionMinWidth ? (
                        notMobileView()
                    ) : (
                        <SwipableDrawer entries={entries} />
                    )}
                    <Grid item xs={5} md={4} lg={4}>
                        <Grid
                            direction={
                                WindowWidth > 530 ? "row" : "column-reverse"
                            }
                            container
                            justifyContent={
                                WindowWidth > 530 ? "flex-end" : "center"
                            }
                            alignItems="center"
                        >
                            <Grid item xs={4} md={6}>
                                {!mandant.flagDisabledLogin && (
                                    <Grid container justifyContent="flex-end">
                                        <div>
                                            {userData && (
                                                <Typography
                                                    className="App-header-button--button"
                                                    onClick={linkToLogin}
                                                >
                                                    {userData.firstname +
                                                        " " +
                                                        userData.lastname}
                                                </Typography>
                                            )}
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={8} md={6}>
                                <Grid container justifyContent="flex-end">
                                    <div>
                                        <a
                                            href={"https://steg.de"}
                                            target="__blank"
                                        >
                                            <img src={logo} alt="Logo" />
                                        </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid className={isMobile ? "App-header" : ""} container>
                <Grid item xs={2}>
                    <div>
                        <a href={mandant.urlWebsite} target="__blank">
                            <CmsImage
                                className="App-header-logo-customer"
                                image={mandant.logoImage}
                            />
                        </a>
                    </div>
                </Grid>
                {WindowWidth > AccordionMinWidth ? (
                    notMobileView()
                ) : (
                    <SwipableDrawer entries={entries} />
                )}
                <Grid item xs={6} sm={5}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <div className="App-header-button--container">
                                {userData && (
                                    <Typography className="App-header-button--button ">
                                        {userData.firstname +
                                            " " +
                                            userData.lastname}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div className="App-header-icon">
                                <a href={"https://steg.de"} target="__blank">
                                    <img src={logo} alt="Logo" />
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IPortalHeaderMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        userData: state.User.userData,
        projectData: state.projectData.projectData,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IPortalHeaderMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        handleLogout: () => dispatch(LogoutAction()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PortalHeader);
