import React from "react";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";

interface ICmsImageProps {
    image: IDocument;
    className?: string;
}

const CmsImage = (props: ICmsImageProps) => {
    const { className } = props;
    const { url, description } = props.image;

    return (
        <img
            className={className}
            src={API_URL_STATIC + url}
            alt={description}
        />
    );
};

export default CmsImage;
