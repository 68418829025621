import { Grid, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";

import stegLogo from "../../assets/images/logo.png";
import { API_URL_STATIC } from "../../config/config";
import IApplicationRootState from "../../store/StoreTypes";
import IMandant from "../../types/IMandant";

interface IAdminHeaderMapStateProps {
    mandant: IMandant;
    activeUser: any;
}

interface IOwnProps {}
interface IAdminHeaderParams extends IAdminHeaderMapStateProps, IOwnProps {}

const AdminHeader = (props: IAdminHeaderParams) => {
    const { mandant, activeUser } = props;

    const mandantKey = props.mandant.key;

    return (
        <AppBar
            position="fixed"
            style={{
                zIndex: 1201,
                background: "#00b5e5",
            }}
        >
            <Toolbar>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-start">
                            {mandantKey === "stegadmin" && (
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        maxWidth: "150px",
                                        marginRight: "8px",
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: "150px",
                                            maxHeight: "70px",
                                            marginRight: "10px",
                                            marginTop: "8px",
                                        }}
                                        alt="loading"
                                        src={stegLogo}
                                    />
                                </Grid>
                            )}
                            {mandantKey !== "stegadmin" && (
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        maxWidth: "100px",
                                        marginRight: "8px",
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: "100px",
                                            maxHeight: "50px",
                                            marginRight: "10px",
                                            marginTop: "8px",
                                            display: "inline-block",
                                        }}
                                        src={
                                            API_URL_STATIC +
                                            mandant.logoImage.url
                                        }
                                        alt="loading"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={8}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: "left",
                                        marginTop: "16px",
                                    }}
                                >
                                    {mandantKey === "stegadmin" &&
                                        "Kundenverwaltung"}
                                    {mandantKey !== "stegadmin" &&
                                        "Bürgerbeteiligungs Plattform / " +
                                            mandant.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={7}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: "right",
                                        marginTop: "16px",
                                    }}
                                >
                                    Willkommen {activeUser?.firstname}{" "}
                                    {activeUser?.lastname}
                                </Typography>
                            </Grid>

                            {mandantKey !== "stegadmin" && (
                                <Grid item xs={2} style={{ maxWidth: "150px" }}>
                                    <img
                                        style={{
                                            maxHeight: "50px",
                                            maxWidth: "150px",
                                            marginLeft: "10px",
                                            marginTop: "8px",
                                        }}
                                        alt="Steg Logo"
                                        src={stegLogo}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IAdminHeaderMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.User.userData,
    };
};

export default React.memo(connect(mapStateToProps)(AdminHeader));
