import ICmsPage from "../ICmsPage";

const InitialCmsPageData: ICmsPage = {
    data: {
        id: 0,
        urlKey: "",
        title: "",
        flagPublished: false,
    },
    elements: [],
};

export default InitialCmsPageData;
