import IPollCampaignList from "../../types/IPollCampaignList";
import IPollPoint from "../../types/IPollPoint";

interface IResult {
    [key: string]: number;
}
export const incrementElseresult = (voteCompaign: IPollCampaignList) => {
    const emtyResult: IResult = {};
    if (voteCompaign.flagShowResults) {
        const newResults: IResult = { ...voteCompaign.results };

        if (newResults.else) {
            newResults.else++;
            return newResults;
        }
        if (!newResults.else) {
            newResults.else = 1;
            return newResults;
        }
    }
    return emtyResult;
};
export const updateResult = (
    voteCompaign: IPollCampaignList,
    option: IPollPoint
) => {
    const emtyResult: IResult = {};
    if (voteCompaign.flagShowResults) {
        const newResults: IResult = { ...voteCompaign.results };

        newResults[option.id]++;
        return newResults;
    }
    return emtyResult;
};
