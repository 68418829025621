import {
    Breadcrumbs,
    Button,
    Checkbox,
    Divider,
    Grid,
    InputLabel,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import TextEdit from "../../../components/wysiwyg/TextEdit";
import RoutesEnum from "../../../routes/RoutesEnum";
import InitialPollCampaignData from "../../../types/initialData/InitialPollCampaignData";
import InitialPollPoint from "../../../types/initialData/InitialPollPoint";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IPollCampaign from "../../../types/IPollCampaign";
import IPollPoint from "../../../types/IPollPoint";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import {
    createPollCampaignRequest,
    loadAdminSingleCampaignRequest,
    updatePollCampaignRequest,
} from "../../../xhr/PollCampaign";
import { loadAdminProjectSelectboxRequest } from "../../../xhr/ProjectRequests";

const PollCampaignForm = () => {
    const history = useHistory();

    const { mandantKey, id }: { mandantKey: string; id: any } = useParams<{
        mandantKey: string;
        id: any;
    }>();

    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(id));
    const [nextQuestion, setNextQuestion] = React.useState<IPollPoint>({
        ...InitialPollPoint,
    });

    const [editIndex, setEditIndex] = React.useState<number | null>(null);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    /**
     * For the Project List
     */
    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    /**
     * The form data
     */
    const [campaignData, setCampaignData] = React.useState<IPollCampaign>({
        ...InitialPollCampaignData,
    });

    /**
     * Snackkbar related states
     */
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    /**
     *
     */
    React.useEffect(() => {
        if (isEditForm) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadDataFromServer(id);
        } else {
            // if we create an event we need at least the project
            setCampaignData({
                ...InitialPollCampaignData,
                id: "create",
            });
        }
    }, [id]);

    /**
     *
     */
    React.useEffect(() => {
        loadAdminProjectSelectboxRequest(mandantKey).then((response) => {
            setProjectList(response.data);
        });
    }, [mandantKey]);

    /**
     */
    const loadDataFromServer = (campaignId: number) => {
        setIsLoading(true);

        loadAdminSingleCampaignRequest(mandantKey, campaignId)
            .then((response) => {
                setCampaignData(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Seite ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handleSave = () => {
        setIsLoading(true);
        if (isEditForm) {
            updatePollCampaignRequest(mandantKey, campaignData)
                .then((response: any) => {
                    setCampaignData(response.data);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Umfrage wurde erfolgreich gespeichert",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    //setLoading(false);
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Speichern der Umfrage ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            createPollCampaignRequest(mandantKey, campaignData)
                .then((response: any) => {
                    //setLoading(false);
                    setCampaignData(response.data);
                    setSnackBarState({
                        isOpen: true,
                        message: "Die Umfrage wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    setIsLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message:
                            "Beim Erstellen der Umfrage ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    /**
     */
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    /**
     */
    const handleSavePoint = () => {
        if (editIndex !== null) {
            campaignData.points[editIndex] = { ...nextQuestion };

            setSnackBarState({
                isOpen: true,
                message: "Die Frage wurde editiert.",
                type: "success",
            });
        } else {
            setSnackBarState({
                isOpen: true,
                message: "Eine neue Frage wurde hinzugefügt.",
                type: "success",
            });

            campaignData.points.push({ ...nextQuestion });
        }

        setCampaignData(deepCopy<IPollCampaign>(campaignData as IPollCampaign));
        setNextQuestion({ ...InitialPollPoint });
        setEditIndex(null);
    };

    /**
     * @param event
     */
    const handleChangeNextPoint = (event: any) => {
        const newQuestionData = {
            ...nextQuestion,
            [event.target.name]: event.target.value,
        };
        setNextQuestion(newQuestionData);
    };

    /**
     * @param event
     */
    const deleteCampaignPoint = (event: any) => {
        const deletePos = event.currentTarget.dataset.pos;

        campaignData.points.splice(deletePos, 1);
        setCampaignData(deepCopy<IPollCampaign>(campaignData as IPollCampaign));
    };

    /**
     * @param event
     */
    const setPointToEdit = (event: any) => {
        const nextEditIndex = event.currentTarget.dataset.pos;
        setEditIndex(nextEditIndex);
        setNextQuestion({ ...campaignData.points[nextEditIndex] });
    };

    /*
    Handle generic change
    */
    const handleTextChange = (event: any) => {
        const newCampaignData = {
            ...campaignData,
            [event.target.name]: event.target.value,
        };
        setCampaignData(newCampaignData);
    };

    /**
     * @param event
     */
    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...campaignData,
            [event.target.name]: event.target.checked,
        };
        setCampaignData(newData);
    };

    /**
     * @param editorState
     * @param name
     */
    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...campaignData, [name]: markup };
        setCampaignData(newData);
    };

    /**
     * @param event
     */
    const changeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCampaignData({
            ...(campaignData as IPollCampaign),
            project: { id: event.target.value as number },
        });
    };

    /**
     * @param event
     */
    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    if (!campaignData.id) {
        return <></>;
    }

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    data-url={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Link
                    color="inherit"
                    href={"/" + mandantKey + RoutesEnum.ADMIN_POLL_CAMPAIGN}
                    data-url={"/" + mandantKey + RoutesEnum.ADMIN_POLL_CAMPAIGN}
                    onClick={handleBread}
                >
                    Liste der Umfragen
                </Link>
                <Typography color="textPrimary">
                    {isEditForm && <>Umfrage: {campaignData.title}</>}
                    {!isEditForm && <>Eine neue Umfrage erstellen</>}
                </Typography>
            </Breadcrumbs>

            {isLoading && (
                <LinearProgress
                    color={"primary"}
                    style={{ marginBottom: "16px" }}
                />
            )}

            <Grid container className="gap-bottom">
                <Grid item xs={12}>
                    <Button onClick={handleSave} color={"primary"}>
                        Umfrage speichern
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6} className="gap-bottom">
                    <TextField
                        variant="outlined"
                        label="Seiten Titel"
                        name={"title"}
                        value={campaignData.title ? campaignData.title : ""}
                        onChange={handleTextChange}
                        fullWidth
                        required={true}
                    />

                    <FormGroup className="gap-bottom">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(
                                        campaignData.flagShowResults
                                    )}
                                    onChange={handleCheckboxChange}
                                    name="flagShowResults"
                                />
                            }
                            label="Ergebnisse sollen direkt angezeigt werden"
                        />
                    </FormGroup>

                    <InputLabel id="project">Projekt</InputLabel>
                    <Select
                        placeholder="Projekt"
                        value={
                            campaignData.project?.id
                                ? campaignData.project?.id
                                : ""
                        }
                        id={"project"}
                        fullWidth={true}
                        onChange={changeProject}
                        variant="outlined"
                    >
                        <MenuItem key="empty" value="">
                            <em>Bitte Projekt wählen</em>
                        </MenuItem>
                        {projectList.map((project: ISelectOption) => {
                            return (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.label}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <TextEdit
                        handleChange={(editorState) => {
                            handleWysiwygChange(editorState, "description");
                        }}
                        title="Beschreibung"
                        defaultValue={campaignData.description}
                        gapBottom={true}
                    />

                    <TextEdit
                        handleChange={(editorState) => {
                            handleWysiwygChange(editorState, "thankYouText");
                        }}
                        title="Danke Text"
                        defaultValue={campaignData.thankYouText}
                        gapBottom={true}
                    />
                </Grid>

                <Grid item xs={6} className={"gap-bottom"}>
                    <TextField
                        variant="outlined"
                        label="Die Frage"
                        name={"title"}
                        value={nextQuestion.title ? nextQuestion.title : ""}
                        onChange={handleChangeNextPoint}
                        fullWidth
                        required={true}
                    />

                    <Button color={"primary"} onClick={handleSavePoint}>
                        {editIndex !== null
                            ? "Frage editieren"
                            : "Frage hinzufügen"}
                    </Button>

                    <List>
                        {campaignData.points.map(
                            (point: IPollPoint, index: number) => {
                                return (
                                    <>
                                        <Divider />
                                        <ListItem
                                            key={"element-" + index}
                                            className={
                                                editIndex === index
                                                    ? "is-selected"
                                                    : ""
                                            }
                                        >
                                            <ListItemText
                                                primary={point.title}
                                            />
                                            <ListItemIcon
                                                data-pos={index}
                                                onClick={setPointToEdit}
                                            >
                                                <EditIcon />
                                            </ListItemIcon>
                                            {!editIndex !== null && (
                                                <ListItemIcon
                                                    data-pos={index}
                                                    onClick={
                                                        deleteCampaignPoint
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                            )}
                                        </ListItem>
                                    </>
                                );
                            }
                        )}
                        <Divider />
                    </List>
                </Grid>
            </Grid>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </Paper>
    );
};
export default PollCampaignForm;
