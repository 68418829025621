import { Divider, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import { getExtension } from "../../utils/checkFileAndDecideIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    })
);
interface IFolderListProps {
    files: any[];
    fromEventTab?: boolean;
}

export default function FolderList(props: IFolderListProps) {
    const classes = useStyles();
    const { files } = props;

    if (!files?.length) {
        return <></>;
    }

    return (
        <>
            <Typography gutterBottom variant="h2" className="gap-top">
                Hier finden Sie weiterführende Dokumente
            </Typography>
            <List className={classes.root}>
                <Divider />

                {files.map((elem) => {
                    const isPdf = getExtension(elem.document.name) === "pdf";

                    return (
                        <div key={`file-${elem.document.url}`}>
                            <ListItem>
                                <ListItemAvatar>
                                    <a
                                        href={
                                            API_URL_STATIC + elem.document.url
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <Avatar>
                                            {isPdf ? (
                                                <PictureAsPdfIcon />
                                            ) : (
                                                <SlideshowIcon />
                                            )}
                                        </Avatar>
                                    </a>
                                </ListItemAvatar>
                                <ListItemText primary={elem.document.name} />
                            </ListItem>
                            <Divider />
                        </div>
                    );
                })}
            </List>
        </>
    );
}
