import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Email, Phone } from "@material-ui/icons";
import React from "react";

import AvatarImg from "../../assets/images/user.svg";
import { API_URL_STATIC } from "../../config/config";
import ITeam from "../../types/ITeam";

interface IPersonTileParams extends ITeam {
    editLabel?: string;
    minData: boolean;
    hasAvatar: boolean;
}

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginRight: "20px",
        marginBottom: "20px",
    },
    media: {
        height: 160,
    },
});

const PersonTile = (props: IPersonTileParams) => {
    const classes = useStyles();

    const { hasAvatar } = props;

    const renderContent = () => {
        return (
            <>
                <Typography gutterBottom variant="h3" component="h3">
                    {props.displayTitle}
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="h3">
                    {props.company}
                </Typography>
                <Typography
                    variant="body1"
                    color="textPrimary"
                    component="p"
                    align="left"
                >
                    {props.salutation} {props.name}
                    {!props.minData && (
                        <>
                            {" "}
                            <br />
                            {props.street}
                            <br />
                            {props.zip} {props.city}
                        </>
                    )}
                </Typography>

                {props.phone && (
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid item style={{ minWidth: "45px;" }}>
                            <Icon>
                                <Phone />
                            </Icon>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                component="p"
                                align="left"
                            >
                                <a href={"tel:" + props.email?.trim()}>
                                    {props.phone?.trim()}
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                {props.email && (
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid item style={{ minWidth: "45px;" }}>
                            <Icon>
                                <Email />
                            </Icon>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                component="p"
                                align="left"
                            >
                                <a href={"mailto:" + props.email?.trim()}>
                                    {props.email?.trim()}
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    return (
        <Card
            variant="outlined"
            className={classes.root}
            style={{ height: "100%" }}
        >
            <CardActionArea style={{ height: "100%" }}>
                <CardContent style={{ height: "100%" }}>
                    {hasAvatar && (
                        <Grid container>
                            <Grid item xs={12} md={4} lg={3}>
                                <Avatar
                                    alt={props?.name ? props.name : "Avatar"}
                                    style={{ height: "70px", width: "70px" }}
                                    src={
                                        props?.image?.url
                                            ? API_URL_STATIC + props?.image?.url
                                            : AvatarImg
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                {renderContent()}
                            </Grid>
                        </Grid>
                    )}
                    {!hasAvatar && renderContent()}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default PersonTile;
