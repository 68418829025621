import { AxiosResponse } from "axios";

import {
    ADMIN_APPOINTMENT,
    ADMIN_APPOINTMENT_SINGLE,
    ADMIN_CAMPAIGN_PARTICIPANT,
    ADMIN_CAMPAIGN_PARTICIPANTS,
    PORTAL_APPOINTMENT,
} from "../enums/Endpoints";
import ICampaignAppointmentList from "../types/appointment/ICampaignAppointmentList";
import ICampaignParticipantList from "../types/appointment/ICampaignParticipantList";
import IPortalAppointment from "../types/appointment/IPortalAppointment";
import IPortalBookingAppointment from "../types/appointment/IPortalBookingAppointment";
import IParticipantImport from "../types/IParticipantImport";
import dateToTime from "../utils/form/dateToTime";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";

export const loadPublicAppointmentDataById = (
    mandant: string,
    appointmentId: string | number
): Promise<AxiosResponse<IPortalAppointment>> => {
    return getData<IPortalAppointment>(
        `${PORTAL_APPOINTMENT}${appointmentId}`,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

export const loadAppointmentListRequest = (
    mandant: string,
    campaignId: string | number
): Promise<AxiosResponse<ICampaignAppointmentList[]>> => {
    return getData<ICampaignAppointmentList[]>(
        `${ADMIN_APPOINTMENT_SINGLE}/campaign-appointments/${campaignId}`,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

export const loadParticipantListRequest = (
    mandant: string,
    campaignId: string | number
): Promise<AxiosResponse<ICampaignParticipantList[]>> => {
    return getData<ICampaignParticipantList[]>(
        `${ADMIN_CAMPAIGN_PARTICIPANTS}${campaignId}`,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

export const createParticipantListRequest = (
    mandant: string,
    body: any
): Promise<AxiosResponse<ICampaignParticipantList[]>> => {
    return postData<IParticipantImport, ICampaignParticipantList[]>(
        `${ADMIN_APPOINTMENT_SINGLE}/import-participants/`,
        body,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

export const createAppointmentBookingRequest = (
    mandant: string,
    appointment: Partial<IPortalBookingAppointment>
): Promise<any> => {
    return putData<Partial<IPortalBookingAppointment>, any>(
        `${ADMIN_APPOINTMENT_SINGLE}/booking/`,
        appointment.id + "",
        appointment,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

export const updateAppointmentRequest = (
    appointment: ICampaignAppointmentList,
    mandant: string
): Promise<any> => {
    const formatedAppointment = {
        ...appointment,
        bookingTime: dateToTime(appointment.bookingTime),
        startDate: appointment.startDate.substring(
            0,
            appointment.startDate.length - 3
        ),
    };
    return putData(
        ADMIN_APPOINTMENT,
        appointment.id + "",
        formatedAppointment,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

export const deleteAppointmentRequest = (
    mandant: string,
    id: string
): Promise<any> => {
    return deleteData(ADMIN_APPOINTMENT, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export const deleteParticipantRequest = (
    mandant: string,
    id: string
): Promise<any> => {
    return deleteData(ADMIN_CAMPAIGN_PARTICIPANT, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
