import { AxiosResponse } from "axios";

import {
    PORTAL_COMMENT,
    PORTAL_EVENT,
    PORTAL_PROJECT,
} from "../enums/Endpoints";
import IPortalComment from "../types/IPortalComment";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISyncPortalComment from "./interface/ISyncPortalComment";

/**
 * Project list
 */
export const loadProjectCommentsRequest = (
    mandant: string,
    projectId: number
): Promise<AxiosResponse<ISyncPortalComment[]>> => {
    return getData<ISyncPortalComment[]>(
        PORTAL_PROJECT + projectId + "/" + PORTAL_COMMENT,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

/**
 * Event list
 */
export const loadEventCommentsRequest = (
    mandant: string,
    eventId: number
): Promise<AxiosResponse<ISyncPortalComment[]>> => {
    return getData<ISyncPortalComment[]>(
        PORTAL_EVENT + eventId + "/" + PORTAL_COMMENT,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

/**
 * Kommentare
 */
export const loadCommentList = (
    mandant: string
): Promise<AxiosResponse<ISyncPortalComment[]>> => {
    return getData<ISyncPortalComment[]>(PORTAL_COMMENT, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Create a new comment
 */
export const createCommentRequest = (
    mandant: string,
    commentData: IPortalComment
): Promise<AxiosResponse<IPortalComment>> => {
    return postData<IPortalComment, IPortalComment>(
        PORTAL_COMMENT,
        commentData,
        {
            mandant,
            isPublic: true,
            withLang: true,
        }
    );
};

/**
 * Update the comment
 */
export const updateCommentRequest = (
    mandant: string,
    commentId: number,
    commentData: IPortalComment
): Promise<AxiosResponse<IPortalComment>> => {
    return putData<IPortalComment, IPortalComment>(
        PORTAL_COMMENT,
        commentId,
        commentData,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

/**
 * Delete the comment request
 */
export const deleteCommentRequest = (
    mandant: string,
    commentId: number
): Promise<AxiosResponse<any>> => {
    return deleteData<any>(PORTAL_COMMENT, commentId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
