import moment from "moment";
import React from "react";

import { ISimpleEntryParams } from "../../../../components/sidebar/Element/SimpeEntry";
import { Format } from "../../../../config/config";
import IDeadlineList from "../../../../types/IDeadlineList";
import IProjectFull from "../../../../types/IProjectFull";
import ProjectDataSidebar from "./ProjectDataSidebar";

interface IProjectDataSidebarMapperProps {
    projectData: IProjectFull;
}

const ProjectDataSidebarMapper = (props: IProjectDataSidebarMapperProps) => {
    const { projectData } = props;

    return (
        <ProjectDataSidebar
            deadlines={projectData.deadlines
                .filter((check: IDeadlineList) => {
                    const deadlineDate = new Date(
                        new Date(check.deadlineDate).getTime() +
                            60 * 60 * 24 * 1000
                    ).getTime();

                    const today = new Date().getTime();

                    return deadlineDate > today;
                })
                .sort((a: IDeadlineList, b: IDeadlineList) => {
                    const firstdate = new Date(a.deadlineDate);
                    const seconddate = new Date(b.deadlineDate);

                    if (firstdate.getTime() < seconddate.getTime()) {
                        return -1;
                    }

                    if (firstdate.getTime() > seconddate.getTime()) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, 3)
                .map((deadline: IDeadlineList): ISimpleEntryParams => {
                    return {
                        id: deadline.id,
                        subTitle: moment(deadline.deadlineDate).format(
                            Format.dateTime
                        ),
                        title: deadline.title,
                        description: deadline.content,
                    };
                })}
            events={[]}
        />
    );
};

export default ProjectDataSidebarMapper;
