//import "./assets/theme.css";
import "./App.css";

import cssVars from "css-vars-ponyfill";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import * as router from "react-router-dom";

import favIcon from "./assets/favicons/favicon.gif";
import { API_URL_STATIC } from "./config/config";
import Routes from "./routes/Routes";
import IApplicationRootState from "./store/StoreTypes";
import IMandant from "./types/IMandant";

// enable inet explorer cssVariables
cssVars({
    // Options...
});

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {
    mandantKey: string;
}

interface IAppPros extends IOwnProps, IMapStateProps {}

function App(props: IAppPros) {
    const { mandant } = props;

    return (
        <div className="App">
            <Helmet>
                <link
                    rel="icon"
                    type="image/png"
                    href={
                        mandant.favIcon?.url
                            ? API_URL_STATIC + mandant.favIcon?.url
                            : favIcon
                    }
                    sizes="16x16"
                />
            </Helmet>

            <router.BrowserRouter>
                <router.Switch>
                    {Routes.map((route: any) => (
                        <router.Route
                            key={route.name}
                            path={route.path}
                            render={(renderProps: any) => (
                                <route.component
                                    {...renderProps}
                                    mandantKey={props.mandantKey}
                                />
                            )}
                        />
                    ))}
                </router.Switch>
            </router.BrowserRouter>
        </div>
    );
}

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(App);
