import IProjectFull from "../IProjectFull";
import InitialDocumentData from "./InitialDocumentData";

const InitialProjectFullData: IProjectFull = {
    attachments: [],
    id: 0,
    title: "",
    shortTitle: "",
    urlKey: "",
    imageMenu: { ...InitialDocumentData },
    imageHeader: { ...InitialDocumentData },
    imageMap: { ...InitialDocumentData },
    imageConsulting: { ...InitialDocumentData },
    theStart: "",
    theEnd: "",
    description: "",
    motivation: "",
    teamText: "",
    consultingText: "",
    mapProposalText: "",
    commmentRulesText: "",
    shortDesc: "",
    projectResults: [],
    images: [],
    deadlines: [],
    events: [],
    team: [],
    flagMyProject: false,
    flagArchive: false,
    flagCommentsOpen: true,
    flagPublished: false,
    flagPolls: false,
    longitude: 0,
    latitude: 0,
    zoom: 2,
    roles: [],
    flagCommentsReadonly: true,
    flagCommentsRegistered: true,
    flagMapCommentsReadonly: true,

    flagTabStart: true,
    titleTabStart: "Projekt/Startseite",
    flagTabInfo: true,
    titleTabInfo: "informieren/Projektvorstellung",
    flagTabEvents: true,
    titleTabEvents: "teilnehmen/Veranstaltungen",
    flagTabResults: true,
    titleTabResults: "ansehen/Bisherige Ergebnisse",
    flagTabParticipate: true,
    titleTabParticipate: "mitmachen/Beteiligung",
    flagTabConsulting: false,
    titleTabConsulting: "mitmachen/Beratung",
    flagTabTeam: true,
    titleTabTeam: "kontaktieren/Projektteam",
    flagTabCampaign: true,
    titleTabCampaign: "kampagne/Projektkampagnen",
    flagMapComments: false,
    flagShowTitle: true,
    flagEnableProjectCms: false,
};
export default InitialProjectFullData;
