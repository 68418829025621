import "./SwipableDrawer.css";

import { Grid, IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import React from "react";

import useWindowSize from "../../customhooks/useWindowSize";
import IHeaderEntry from "../../types/IHeaderEntry";

interface ISwipableDrawerProps {
    entries: IHeaderEntry[];
}

const useStyles = makeStyles({
    list: {},
});

export default function SwipableDrawer(props: ISwipableDrawerProps) {
    const { entries } = props;
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });
    const WindowWidth = useWindowSize();

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, left: open });
        };

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            /*  onClick={toggleDrawer(false)} */
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <Divider />
                {entries.map((entry, index) => (
                    <ListItem button key={entry.label} onClick={entry.onClick}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={entry.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <Grid item xs={5} lg={6} md={5}>
            <Grid
                container
                justifyContent={WindowWidth > 430 ? "center" : "flex-end"}
                style={{ margin: "auto" }}
                alignItems="center"
            >
                <div>
                    <React.Fragment>
                        <div onClick={toggleDrawer(true)}>
                            <IconButton>
                                <MenuIcon />
                            </IconButton>
                            Menu
                        </div>
                        <SwipeableDrawer
                            anchor={"left"}
                            open={state.left}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                        >
                            {list()}
                        </SwipeableDrawer>
                    </React.Fragment>
                </div>
            </Grid>
        </Grid>
    );
}
