import "./WebsiteMenu.css";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import { useHistory } from "react-router-dom";

import {
    IMenuEntry,
    IProjectMenu,
} from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import ProjectDrawer from "../../masks/public/projectData/components/ProjectDrawer";
import IMandant from "../../types/IMandant";
import IProjectFull from "../../types/IProjectFull";

interface IWebsiteMenuProps {
    mandant: IMandant;
    customMenu: IProjectMenu;
    projectData: IProjectFull;
    pageKey?: string;
    tabKey?: string;
    isMobileSize: boolean;
}

const WebsiteMenu = (props: IWebsiteMenuProps) => {
    const history = useHistory();
    const { customMenu, mandant, projectData, pageKey, tabKey, isMobileSize } =
        props;

    if (!customMenu) {
        return <></>;
    }

    if (isMobileSize) {
        return (
            <ProjectDrawer
                entries={customMenu.content}
                mandantKey={mandant.key}
                projectKey={projectData.urlKey}
                pageKey={pageKey}
                tabKey={tabKey}
            />
        );
    }

    const getLinkActionUrl = (menuEntry: IMenuEntry): string => {
        if (menuEntry.type === "page") {
            return (
                "/" +
                mandant.key +
                "/" +
                projectData.urlKey +
                "/seite/" +
                menuEntry.url
            );
        } else {
            return (
                "/" +
                mandant.key +
                "/" +
                projectData.urlKey +
                "/" +
                menuEntry.url
            );
        }
    };

    const checkIfIsActive = (
        menuEntry: IMenuEntry,
        checkKids: boolean
    ): boolean => {
        if (menuEntry.type === "tab") {
            if (tabKey === menuEntry.url) {
                return true;
            }
        } else {
            if (pageKey === menuEntry.url) {
                return true;
            }
        }

        if (!checkKids) {
            return false;
        }

        if (menuEntry.children.length === 0) {
            return false;
        }

        for (const child of menuEntry.children) {
            if (child.type === "tab") {
                if (tabKey === child.url) {
                    return true;
                }
            } else {
                if (pageKey === child.url) {
                    return true;
                }
            }
        }

        return false;
    };

    return (
        <nav className="website-menu">
            <ul>
                {customMenu.content.map((menuEntry, index) => {
                    const linkPath = getLinkActionUrl(menuEntry);

                    return (
                        <li
                            key={index}
                            className={
                                checkIfIsActive(menuEntry, true)
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href={linkPath}
                                onClick={(event) => {
                                    event.preventDefault();
                                    history.push(linkPath);
                                }}
                            >
                                {menuEntry.label}{" "}
                                {menuEntry.children.length > 0 && (
                                    <div className="menu-opener-icon">
                                        <ArrowDropDownIcon />
                                    </div>
                                )}
                            </a>
                            {menuEntry.children.length > 0 && (
                                <ul>
                                    {menuEntry.children.map(
                                        (subMenuEntry, subMenuIndex) => {
                                            const linkSubPath =
                                                getLinkActionUrl(subMenuEntry);

                                            return (
                                                <li
                                                    key={
                                                        index +
                                                        "-" +
                                                        subMenuIndex
                                                    }
                                                    className={
                                                        checkIfIsActive(
                                                            subMenuEntry,
                                                            false
                                                        )
                                                            ? "is-active"
                                                            : ""
                                                    }
                                                >
                                                    <a
                                                        href={linkSubPath}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            history.push(
                                                                linkSubPath
                                                            );
                                                        }}
                                                    >
                                                        {subMenuEntry.label}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default WebsiteMenu;
