import Axios from "axios";

import { API_URL_STATIC } from "../config/config";
import { ADMIN_DEADLINE, PORTAL_DEADLINE } from "../enums/Endpoints";
import IDeadlineList from "../types/IDeadlineList";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISyncProjectList from "./interface/ISyncProjectList";

export const loadPublicDeadlineList = (mandant: string): Promise<any> => {
    return getData<ISyncProjectList>(PORTAL_DEADLINE, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

export const dummydeadLinesgetter = (mandantKey: number): Promise<any> => {
    return Axios.get(`${API_URL_STATIC}api/${mandantKey}/de/project/deadline`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(
                "jwt-" + mandantKey
            )}`,
        },
    });
};
export const createDeadlineRequest = (
    mandant: string,
    deadline: any
): Promise<any> => {
    return postData<string, any>(ADMIN_DEADLINE, deadline, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
export const updateDeadlineRequest = (
    deadline: IDeadlineList,
    mandant: string
): Promise<any> => {
    const formatedDeadline = {
        ...deadline,
        deadlineDate: {
            date: deadline.deadlineDate.substring(
                0,
                deadline.deadlineDate.length - 3
            ),
        },
    };
    return putData(ADMIN_DEADLINE, deadline.id + "", formatedDeadline, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
export const deleteDeadlineRequest = (
    mandant: string,
    id: number
): Promise<any> => {
    return deleteData(ADMIN_DEADLINE, id, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
