import { Grid, Typography } from "@material-ui/core";
import React from "react";

import ICmsPageElement from "../../../../../types/ICmsPageElement";

interface IProps {
    key: string;
    pageFragment: ICmsPageElement;
}

const CmsPageFragmentHeader1 = (props: IProps) => {
    const { key, pageFragment } = props;

    return (
        <Grid item key={key} xs={12}>
            <Typography variant="h1">{pageFragment.content}</Typography>
        </Grid>
    );
};

export default CmsPageFragmentHeader1;
