import { Breadcrumbs, Button, Grid, Link, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import RoutesEnum from "../../../routes/RoutesEnum";
import LoadMandantAction from "../../../store/mandantStore/LoadMandant";
import IApplicationRootState from "../../../store/StoreTypes";
import IDocument from "../../../types/IDocument";
import IMandant from "../../../types/IMandant";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialMandantData from "../../../types/initialData/InitialMandantData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import {
    createMandantRequest,
    loadMandantSingleRequest,
    resetAdminPasswortRequest,
    saveMandantRequest,
} from "../../../xhr/MandantRequests";
import ChangeAdminPasswordModal, {
    IChangeAdminPasswordForm,
} from "./modal/ChangeAdminPasswordModal";
import MandantColorSchemeTab from "./tabs/MandantColorSchemeTab";
import MandantDataTab from "./tabs/MandantDataTab";
import MandantInfoTab from "./tabs/MandantInfoTab";
import MandantTextTab from "./tabs/MandantTextTab";

interface IMapStateProps {
    mandant: IMandant;
}

interface IMapDispatchProps {
    handleLoadMandant: (mandantKey: string) => void;
}

interface IOwnProps {
    mandantKey: string;
}

interface IUserListingParams
    extends IMapStateProps,
        IMapDispatchProps,
        IOwnProps {}

const MandantForm = (props: IUserListingParams) => {
    const history = useHistory();
    const params = useParams<{
        id: string;
    }>();
    const mandantId = params.id;
    const { mandant, handleLoadMandant } = props;
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [changeModalIsOpen, setChangeModalIsOpen] =
        React.useState<boolean>(false);

    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [mandantData, setMandantData] = React.useState<IMandant>({
        ...mandant,
    });

    //

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const closeChangeModal = () => {
        setChangeModalIsOpen(false);
    };

    React.useEffect(() => {
        if (mandantId) {
            if (mandantId === "create") {
                setMandantData({ ...InitialMandantData });
            } else {
                loadMandantSingleRequest(mandant.key, mandantId)
                    .then((response: any) => {
                        const mandantFormData = response.data;
                        if (!mandantFormData.favIcon) {
                            mandantFormData.favIcon = {
                                ...InitialDocumentData,
                            };
                        }

                        setMandantData(mandantFormData);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Laden der Daten ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            }
        } else {
            if (!mandant.favIcon) {
                mandant.favIcon = { ...InitialDocumentData };
            }

            setMandantData({ ...mandant });
        }
    }, [mandant, mandantId]);

    const resetMyChangesAction = () => {
        setMandantData({
            ...mandant,
        });
    };

    const setChangeModalPasswordOpen = () => {
        setChangeModalIsOpen(true);
    };

    const handleTabChange = (event: any, newTab: number) => {
        setActiveTab(newTab);
    };

    const handleChange = (event: any) => {
        const newData = {
            ...mandantData,
            [event.target.name]: event.target.value,
        };
        setMandantData(newData);
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...mandantData,
            [event.target.name]: event.target.checked,
        };

        setMandantData(newData);
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...mandantData, [attrKey]: image };
        setMandantData(newData);
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(
            editorState.getCurrentContent()
        );
        const markup = draftToHtml(rawContentState);
        const newData = { ...mandantData, [name]: markup };
        setMandantData(newData);
    };

    const handleSaveClick = () => {
        setIsLoading(true);

        if (mandantId === "create") {
            createMandantRequest(mandant.key, mandantData)
                .then((response: any) => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Portal wurde erfolgreich erstellt",
                        type: "success",
                    });

                    setIsLoading(false);

                    history.push(
                        "/" +
                            mandant.key +
                            RoutesEnum.ADMIN_PORTAL +
                            response.data.id
                    );
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Fehler beim Speichern der Portaldaten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            saveMandantRequest(mandant.key, mandantData, mandantData.id)
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Portal wurde erfolgreich gespeichert",
                        type: "success",
                    });
                    setIsLoading(false);

                    handleLoadMandant(mandant.key);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Fehler beim Speichern der Portaldaten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const resetAdminPasswortAction = (formData: IChangeAdminPasswordForm) => {
        setIsLoading(true);

        resetAdminPasswortRequest(mandant.key, formData)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Das Adminpasswort des Portals wurde zurück gesetzt.",
                    type: "success",
                });

                setIsLoading(false);
                setChangeModalIsOpen(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim zurücksetzen des Adminpasswort.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const isStegAdmin = mandant.key === "stegadmin";

    if (!mandant.id) {
        return <></>;
    }

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {!isStegAdmin && (
                    <Link
                        color="inherit"
                        href={"/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD}
                        data-url={
                            "/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD
                        }
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                )}
                {isStegAdmin && (
                    <Link
                        color="inherit"
                        href={"/" + mandant.key + RoutesEnum.ADMIN_PORTAL_LIST}
                        data-url={
                            "/" + mandant.key + RoutesEnum.ADMIN_PORTAL_LIST
                        }
                        onClick={handleBread}
                    >
                        Portal Übersicht
                    </Link>
                )}

                <Typography color="textPrimary">
                    {mandantId === "create"
                        ? "Portal erstellen"
                        : "Portal: " + mandantData.name}
                </Typography>
            </Breadcrumbs>

            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        onClick={handleSaveClick}
                        color="primary"
                        startIcon={<SaveIcon />}
                    >
                        Daten Speichern
                    </Button>

                    <Button
                        onClick={resetMyChangesAction}
                        color="secondary"
                        startIcon={<CancelIcon />}
                    >
                        Meine Änderungen zurücksetzen
                    </Button>

                    <Button
                        onClick={setChangeModalPasswordOpen}
                        color="primary"
                        startIcon={<VpnKeyIcon />}
                    >
                        Portaladmin zurücksezten
                    </Button>
                </Grid>
            </Grid>

            <TabContainer
                isAdmin={true}
                tabs={[
                    {
                        id: "tab-portal-data",
                        label: "Portal Daten",
                        display: true,
                        tabKey: 1,
                        content: (
                            <MandantDataTab
                                mandantData={mandantData}
                                onChange={handleChange}
                                onImageChange={handleImageChange}
                            />
                        ),
                    },
                    {
                        id: "tab-texts",
                        label: "Texte",
                        display: true,
                        tabKey: 2,
                        content: (
                            <MandantTextTab
                                mandantData={mandantData}
                                onChange={handleChange}
                                onCheckboxChange={handleCheckboxChange}
                                onChangeWysiwyg={handleWysiwygChange}
                            />
                        ),
                    },
                    {
                        id: "tab-login-page",
                        label: "Login Seite",
                        display: true,
                        tabKey: 3,
                        content: (
                            <MandantInfoTab
                                mandantData={mandantData}
                                onChange={handleChange}
                                onCheckboxChange={handleCheckboxChange}
                                onChangeWysiwyg={handleWysiwygChange}
                            />
                        ),
                    },
                    {
                        id: "tab-colorscheme",
                        label: "Farbschema",
                        display: true,
                        tabKey: 4,
                        content: (
                            <MandantColorSchemeTab
                                mandantData={mandantData}
                                onChange={handleChange}
                            />
                        ),
                    },
                ]}
                activeTab={activeTab}
                ariaLabel={"Mandant Settings"}
                idKey={"madant"}
                handleChange={handleTabChange}
            />

            {changeModalIsOpen && (
                <ChangeAdminPasswordModal
                    isOpen={changeModalIsOpen}
                    setClose={closeChangeModal}
                    activeMandant={mandantData.key}
                    resetAdminPasswortAction={resetAdminPasswortAction}
                />
            )}

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    IMapDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<{}, {}, Action>) => {
    return {
        handleLoadMandant: (mandantKey: string) => {
            return dispatch(LoadMandantAction(mandantKey));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MandantForm);
