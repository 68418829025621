import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import React from "react";

import { API_URL_STATIC, Format } from "../../../config/config";
import IDocument from "../../../types/IDocument";
import IEventList from "../../../types/IEventList";

interface IEventTileParams extends IEventList {
    onLinkClick: (event: any) => void;
    onDeletelick?: (event: any) => void;
    onPublishClick?: (event: any) => void;
    fallbackImage: IDocument;
    editLabel?: string;
    fromPublicMask?: boolean;
    onDownloadClick?: (event: any) => void;
}

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginRight: "20px",
        marginBottom: "20px",
        height: "100%",
    },
    media: {
        height: 160,
    },
});

const EventTile = (props: IEventTileParams) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
                variant="outlined"
                square
                className={classes.root}
                data-id={props.id}
                onClick={
                    props.onLinkClick && !props.editLabel
                        ? props.onLinkClick
                        : () => null
                }
            >
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={
                            API_URL_STATIC +
                            (props.imageMenu
                                ? props.imageMenu.url
                                : props.fallbackImage.url)
                        }
                        title={
                            props.imageMenu
                                ? props.imageMenu.description
                                : props.fallbackImage.description
                        }
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="h3">
                            {props.title}
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="subtitle1"
                            component="h3"
                        >
                            Ab: {moment(props.theStart).format(Format.dateTime)}{" "}
                            Bis: {moment(props.theEnd).format("HH:mm")}{" "}
                            <a
                                href={props.locationUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {props.locationName}
                            </a>
                            {props.fromPublicMask && (
                                <CalendarTodayIcon
                                    data-id={props.id}
                                    color="primary"
                                    onClick={props.onDownloadClick}
                                    fontSize="small"
                                />
                            )}
                            {props.project && (
                                <>
                                    <br />
                                    Projekt: {props.project?.title}
                                </>
                            )}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            align="left"
                        >
                            {props.shortDesc}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {!props.editLabel && (
                        <Button
                            data-id={props.id}
                            size="small"
                            color="primary"
                            onClick={props.onLinkClick}
                        >
                            Mehr lesen...
                        </Button>
                    )}

                    {props.editLabel && (
                        <IconButton
                            data-id={props.id}
                            onClick={props.onLinkClick}
                            title={props.editLabel}
                        >
                            <IconEdit />
                        </IconButton>
                    )}
                    {props.onPublishClick && (
                        <IconButton
                            data-id={props.id}
                            onClick={props.onPublishClick}
                            title={
                                props.flagIsPublished
                                    ? "Zurücknehmen"
                                    : "Veröffentlichen"
                            }
                        >
                            {props.flagIsPublished ? (
                                <IconUnPublish />
                            ) : (
                                <IconPublish />
                            )}
                        </IconButton>
                    )}
                    {props.onDeletelick && (
                        <IconButton
                            data-id={props.id}
                            color="secondary"
                            title="Löschen"
                            onClick={props.onDeletelick}
                        >
                            <IconDelete />
                        </IconButton>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
};

export default EventTile;
