import IUser from "../store/userStore/interfaces/IUser";

export default function userHasRoles(
    user: IUser | null,
    roles: string[]
): boolean {
    if (!user) {
        return false;
    }

    return !!user.roles.find((role: string) => {
        return roles.includes(role);
    });
}
