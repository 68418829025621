import "./DashboardContainer.css";

import React from "react";
import * as router from "react-router-dom";

import PrivateRoute from "../../../routes/privateRoute/PrivateRoute";
import PublicRoute from "../../../routes/PublicRoute/PublicRoute";
import RoutesEnum from "../../../routes/RoutesEnum";
import AdminRoutesContainer from "../adminRoutesContainer/AdminRoutesContainer";
import LoginContainer from "../loginContainer/LoginContainer";

type IDashboardContainerProps = {
    mandantKey: string;
};

const DashboardContainer = (props: IDashboardContainerProps) => {
    const { url } = router.useRouteMatch();

    return (
        <div>
            <router.Switch>
                <PrivateRoute
                    path={"/:mandantKey" + RoutesEnum.ADMIN_HOME}
                    mandantKey={props.mandantKey}
                    component={AdminRoutesContainer}
                />
                <PublicRoute
                    path={url + "/login"}
                    mandantKey={props.mandantKey}
                    component={LoginContainer}
                />
            </router.Switch>
        </div>
    );
};

export default DashboardContainer;
