import { Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImmageGallery from "../../../../components/imageGallery/ImageGallery";

interface IAboutProps {
    event: any;
}

function ReportTab(props: IAboutProps) {
    const { event } = props;

    return (
        <>
            {!!event.descriptionAfter && (
                <>
                    <HtmlContent
                        className="content-block"
                        content={event.descriptionAfter}
                    />
                </>
            )}
            <FolderList files={event.attachments} fromEventTab={true} />

            {!!event.imagesAfter.length && (
                <>
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Bildergalerie
                    </Typography>
                    <Typography variant="body1">
                        Hier sehen Sie eine Auswahl von Bildern der
                        Veranstaltung.
                    </Typography>
                    <ImmageGallery images={event.imagesAfter} />
                </>
            )}
        </>
    );
}

export default ReportTab;
