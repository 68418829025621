import IPollCampaignList from "../IPollCampaignList";

const InitialPollCompaignData: IPollCampaignList = {
    id: 0,
    project: { id: 0, title: "" },
    title: "",
    description: "",
    flagPublished: false,
    flagShowResults: false,
    flagName: 0,
    flagEmail: 0,
    creationDate: "",
    points: [],
};
export default InitialPollCompaignData;
