import { Grid, Paper } from "@material-ui/core";
import React from "react";

import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IProjectFull from "../../../../types/IProjectFull";

type IParticipationDescTabProps = {
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
};

const ParticipationDescTab = (props: IParticipationDescTabProps) => {
    const { projectData, onChangeWysiwyg } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "mapProposalText");
                        }}
                        title="Beschreibung Mitmachen"
                        defaultValue={
                            props.projectData.mapProposalText
                                ? projectData.mapProposalText
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "commmentRulesText");
                        }}
                        title="Kommentar Regeln"
                        defaultValue={
                            projectData.commmentRulesText
                                ? projectData.commmentRulesText
                                : ""
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ParticipationDescTab;
