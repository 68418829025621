import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectStartTabProps {
    project: IProjectFull;
}

function ProjectStartTab(props: IProjectStartTabProps) {
    const { project } = props;

    return (
        <HtmlContent className="content-block" content={project.motivation} />
    );
}

export default ProjectStartTab;
