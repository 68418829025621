import { Grid } from "@material-ui/core";
import React from "react";

import EventTile from "../../../../components/project/eventTile/EventTile";
import RoutesEnum from "../../../../routes/RoutesEnum";
import IEventList from "../../../../types/IEventList";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectEventsTabProps {
    project: IProjectFull;
    mandant: IMandant;
    history: any;
}

function ProjectEventsTab(props: IProjectEventsTabProps) {
    const { project, mandant, history } = props;

    const linkToEvent = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        history.push(
            `/${mandant.key}${RoutesEnum.PORTAL_EVENT}/${event.currentTarget.dataset.id}`
        );
    };

    return (
        <Grid container spacing={2} className="gap-top">
            {project.events
                .sort((a: any, b: any) => {
                    const firstdate = new Date(a.theStart);
                    const seconddate = new Date(b.theStart);
                    return firstdate.getTime() - seconddate.getTime();
                })
                .map((event: IEventList, index) => (
                    <EventTile
                        key={`event-${index}`}
                        {...event}
                        fallbackImage={mandant.defaultMenuImage}
                        onLinkClick={linkToEvent}
                    />
                ))}
        </Grid>
    );
}

export default ProjectEventsTab;
