import { Button, Grid, Paper } from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import React from "react";

import EventTile from "../../../../components/project/eventTile/EventTile";
import IDocument from "../../../../types/IDocument";
import IEventList from "../../../../types/IEventList";

type IOwnProps = {
    events: IEventList[];
    onEditClick: (event: any) => void;
    onPublishClick: (event: any) => void;
    onDeleteClick: (event: any) => void;
    onCreateProjectClick: (event: any) => void;
    fallbackImage: IDocument;
};

type IEventContainerProps = IOwnProps;

const EventListingTab = (props: IEventContainerProps) => {
    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        onClick={props.onCreateProjectClick}
                        color="primary"
                        startIcon={<IconAdd />}
                    >
                        Eine neue Veranstaltung anlegen
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {props.events
                    .sort((a: any, b: any) => {
                        const firstdate = new Date(a.theStart);
                        const seconddate = new Date(b.theStart);
                        return firstdate.getTime() - seconddate.getTime();
                    })
                    .map((event: IEventList, index) => (
                        <EventTile
                            key={`event-${index}`}
                            {...event}
                            fallbackImage={props.fallbackImage}
                            onLinkClick={props.onEditClick}
                            onPublishClick={() => props.onPublishClick(event)}
                            onDeletelick={() => props.onDeleteClick(event)}
                            editLabel="Bearbeiten"
                        />
                    ))}
            </Grid>
        </Paper>
    );
};

export default EventListingTab;
