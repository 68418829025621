import React from "react";
import CookieConsent from "react-cookie-consent";

function CookiesConsent() {
    return (
        <CookieConsent
            acceptOnScroll={true}
            location="top"
            buttonText="Ich stimme zu"
            cookieName="noCookieCookie"
            style={{ color: "white", background: "#00b5e2" }}
            buttonStyle={{ color: "#inherit", fontSize: "13px" }}
            expires={150}
        >
            Diese Website verwendet Cookies – nähere Informationen dazu und zu
            Ihren Rechten als Benutzer finden Sie in unserer
            Datenschutzerklärung am Ende der Seite. Klicken Sie auf „Ich stimme
            zu“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu
            können.
        </CookieConsent>
    );
}

export default CookiesConsent;
