import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ical from "ical-generator";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useParams } from "react-router-dom";

import ImageHeader from "../../../components/layout/ImageHeader";
import EventTile from "../../../components/project/eventTile/EventTile";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import { loadPublicEventList } from "../../../xhr/EventRequests";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require("js-file-download");
type IMapStateProps = {
    mandant: IMandant;
};

type IMapDispatchProps = {};

type IOwnProps = {
    history: any;
};

type IEventListParams = IMapStateProps & IMapDispatchProps & IOwnProps;

const EventList = (props: IEventListParams) => {
    const { history, mandant } = props;
    const { mandantKey }: { mandantKey: string } = useParams();
    const [eventList, setEventList] = React.useState<IEventList[]>([]);

    React.useEffect(() => {
        const loadEvent = () => {
            loadPublicEventList(mandantKey).then((result: any) => {
                setEventList(result.data);
                return Promise.resolve();
            });
        };
        loadEvent();
    }, []);

    const linkToEvent = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        history.push(
            `/${mandant.key}${RoutesEnum.PORTAL_EVENT}/${event.currentTarget.dataset.id}`
        );
    };

    // if we have only one project we direct jump into the project
    if (eventList.length === 1) {
        history.push(
            "/" + mandantKey + RoutesEnum.PORTAL_EVENT + "/" + eventList[0].id
        );
    }

    // if we have only one project we direct jump into the project
    /* if (eventList.length === 1) {
        history.push(
            "/" + mandantKey + RoutesEnum.PORTAL_EVENT + "/" + eventList[0].id
        );
    } */
    const handleDownloadClick = (e: any, event: any) => {
        const cal = ical({ name: "my first iCal" });
        cal.createEvent({
            start: new Date(event.theStart),
            end: new Date(event.theEnd),
            summary: event.shortDesc,
            description: event.description,
            location: event.location,
        });

        const dataTodownload = cal.toString();
        fileDownload(dataTodownload, `${event.title}.ics`);
        // download(dataTodownload, "Event Calendar.ics");
    };
    return (
        <div className="portal-section">
            <Helmet>
                <title>Die Liste der aktuellen Veranstaltungen</title>
            </Helmet>

            <Header mandant={mandant} history={history} />
            <ImageHeader image={mandant.headerImage} />

            <div className="portal-section-body-position">
                <div className="App-main-list">
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper
                                className="App-list-container-body"
                                variant="outlined"
                                square
                            >
                                <h2 className="App-list-container-header">
                                    Die Liste der aktuellen Veranstaltungen
                                </h2>

                                {eventList.length === 0 && (
                                    <>
                                        <p>
                                            Im Moment gibt es keine anstehenden
                                            Veranstaltungen.
                                        </p>
                                    </>
                                )}
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {eventList.length > 0 &&
                                        eventList.map(
                                            (
                                                event: IEventList,
                                                index: number
                                            ) => {
                                                return (
                                                    <EventTile
                                                        key={index}
                                                        fallbackImage={
                                                            mandant.defaultMenuImage
                                                        }
                                                        onLinkClick={
                                                            linkToEvent
                                                        }
                                                        {...event}
                                                        onDownloadClick={(e) =>
                                                            handleDownloadClick(
                                                                e,
                                                                event
                                                            )
                                                        }
                                                        fromPublicMask
                                                    />
                                                );
                                            }
                                        )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(EventList);
