import "./ForgotPassword.css";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconVpnKey from "@material-ui/icons/VpnKey";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import CmsImage from "../../../components/cms/CmsImage";
import ImageHeader from "../../../components/layout/ImageHeader";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";
import toInputString from "../../../utils/form/toInputString";
import validateEmail from "../../../utils/form/validateEmail";
import { demandResetPasswordRequest } from "../../../xhr/UserRequests";

type IFormError = {
    nickName?: string;
    email?: string;
};

export type IPasswordResetRequest = {
    nickName?: string;
    email?: string;
};

type ILoginMapStateProps = {
    mandant: IMandant;
};

type ILoginDispatchProps = {};

type IOwnProps = {
    history: any;
};

type ILoginParams = ILoginMapStateProps & ILoginDispatchProps & IOwnProps;

const ForgotPassword = (props: ILoginParams) => {
    const { mandant } = props;

    const { mandantKey } = useParams<{ mandantKey: string }>();
    const history = useHistory();

    const [formData, setFormData] = React.useState<IPasswordResetRequest>({});
    const [errorState, setErrorState] = React.useState<IFormError>({});
    const [loading, setLoading] = React.useState<boolean>(false);

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const handleChange = (e: any) => {
        const newData = { ...formData, [e.target.name]: [e.target.value] };

        // @ts-ignore
        errorState["" + e.target.name] = null;
        setErrorState({ ...errorState });
        setFormData(newData);
    };

    const handleClickReset = () => {
        const newErrorState: IFormError = {};
        let hasError = false;

        if (stringIsEmpty(formData.nickName) && stringIsEmpty(formData.email)) {
            hasError = true;
            newErrorState.email = "Bitte eine E-Mail angeben,";
            newErrorState.nickName = "oder einen Anzeigenamen.";
        } else if (
            !stringIsEmpty(formData.email) &&
            !validateEmail("" + formData.email)
        ) {
            hasError = true;
            newErrorState.email = "Bitte eine korrekte E-Mail angeben.";
        }

        setErrorState(newErrorState);

        if (hasError) {
            return;
        }

        setLoading(true);

        demandResetPasswordRequest(mandantKey, formData)
            .then(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Die Anfrage zum zurücksetzen wurde verschickt",
                    type: "success",
                });
                setLoading(false);
                setFormData({});
                setErrorState({});

                history.push(`/${mandantKey}/passwort_zuruecksetzen`);
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    message: "Die Anfrage konnte nicht bearbeitet werden.",
                    type: "error",
                });
                setLoading(false);
                setErrorState({});
            });
    };

    return (
        <div className="portal-section">
            <Helmet>
                <title>Passwort vergessen</title>
            </Helmet>

            <Header mandant={mandant} history={history} />

            <ImageHeader image={mandant.headerImage} />

            <Container maxWidth="md">
                <Grid container>
                    <Card
                        variant="outlined"
                        className="resetpwd-form-container gap-bottom"
                    >
                        <CmsImage
                            image={mandant.logoImage}
                            className="login-logo gap-top"
                        />
                        <CardContent className="resetpwd-form-content">
                            <Grid item xs={12} md={12}>
                                <div className="gap-top">
                                    <Typography className="gap-bottom">
                                        Bitte geben Sie ihre E-Mail oder Ihren
                                        Anzeigenamen an.
                                    </Typography>

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="E-Mail Adresse"
                                        variant="outlined"
                                        className="public-input-text"
                                        type="text"
                                        onChange={handleChange}
                                        error={!!errorState.email}
                                        helperText={toInputString(
                                            errorState.email
                                        )}
                                        name="email"
                                    />

                                    <TextField
                                        required
                                        id="outlined-basic"
                                        label="Anzeigename"
                                        variant="outlined"
                                        className="public-input-text"
                                        onChange={handleChange}
                                        error={!!errorState.nickName}
                                        helperText={toInputString(
                                            errorState.nickName
                                        )}
                                        name="nickName"
                                    />

                                    {loading && <CircularProgress />}
                                    {!loading && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            onClick={handleClickReset}
                                            className="button--primary"
                                            startIcon={<IconVpnKey />}
                                        >
                                            Passwort zurücksetzen
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};
/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    ILoginMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(ForgotPassword);
