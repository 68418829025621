import "./styles.css";

import { Button, Grid, TextField } from "@material-ui/core";
import React from "react";

import IElseVote from "../../types/IElseVote";
import InitialElseVote from "../../types/initialData/InitialElseVote";
import IPollCampaignList from "../../types/IPollCampaignList";
import HtmlContent from "../cms/HtmlContent";
import {
    IErrorState,
    calculatePointPercentage,
    calculateSum,
    userAllowedToVote,
    validateMailAndName,
} from "./tool";
import UserData from "./UserData";

interface IOwnProps {
    poll: IPollCampaignList;
    onNewOptionVote: (newOption: IElseVote) => void;
    handleVote: (option: string) => void;
    signedUser: any;
    onEmptyVote: () => void;
    readOnly: boolean;
}

interface IVote {
    option: string;
    voted: boolean;
    question: string;
}

interface IPollParams extends IOwnProps {}

function Poll(props: IPollParams) {
    const {
        poll,
        onNewOptionVote,
        handleVote,
        signedUser,
        onEmptyVote,
        readOnly,
    } = props;
    const [currentVote, setCurrentVote] = React.useState<IVote>({
        option: "",
        voted: false,
        question: "",
    });
    /* clicked else state */
    const [clickedElse, setClickedElse] = React.useState<boolean>(false);
    /* error state */

    const [errorState, setErrorState] = React.useState<IErrorState>({
        hasNoError: true,
        emailIsValid: true,
        nameIsValid: true,
    });
    /* results votes sum */
    const [allVotes, setAllVotes] = React.useState<number>(0);
    /* elseVote State */
    const [elseVote, setElseVote] = React.useState<IElseVote>(
        signedUser
            ? {
                  nameOfPerson: signedUser.firstanme,
                  emailOfPerson: signedUser.email,
                  textElse: "",
              }
            : {
                  ...InitialElseVote,
              }
    );

    /* set Poll vote function  */
    const setPollVote = (answer: string) => {
        const newVote = {
            option: answer,
            voted: true,
            question: poll.title,
        };
        setCurrentVote(newVote);
    };
    /*  function to read the vote from storage */
    const getStoragePolls = () =>
        JSON.parse(localStorage.getItem("react-polls") || "[]");
    /* effect to check if user already voted */
    React.useEffect(() => {
        const storage = getStoragePolls();
        const answer = storage.filter((elem: any) => {
            return elem.question === poll.title;
        });
        if (answer.length) {
            setPollVote(answer[0].option);
        }
        if (poll.flagShowResults) {
            const sum = calculateSum(poll.results);
            setAllVotes(parseInt(sum));
        }
    }, [poll]);

    /* handle new option change */
    const handleElseVoteChange = (e: any) => {
        setElseVote({ ...elseVote, [e.target.name]: e.target.value });
    };
    /* handle vote  */
    const vote = (answer: string) => {
        if (readOnly) {
            return;
        }

        setClickedElse(false);
        const storage = getStoragePolls();
        storage.push({
            url: window.location.href,
            question: poll.title,
            option: answer,
        });
        localStorage.setItem("react-polls", JSON.stringify(storage));

        setPollVote(answer);
        handleVote(answer);
    };

    /* handle vote submit */
    const handleVoteSubmit = () => {
        const errorData = validateMailAndName(poll, elseVote);
        setErrorState(errorData);
        if (!errorData.hasNoError) {
            onEmptyVote();
            return;
        }
        if (elseVote.textElse === "") {
            onEmptyVote();
            return;
        }

        setClickedElse(false);

        const storage = getStoragePolls();
        storage.push({
            url: window.location.href,
            question: poll.title,
            option: elseVote.textElse,
        });
        localStorage.setItem("react-polls", JSON.stringify(storage));

        setPollVote(elseVote.textElse);
        onNewOptionVote(elseVote);
    };

    if (currentVote.voted && !poll.flagShowResults) {
        return (
            <div>
                <HtmlContent
                    content={poll.thankYouText ? poll.thankYouText : ""}
                />
            </div>
        );
    }
    const calculateRoundedElse = () => {
        const result = poll.results?.else
            ? (poll.results.else * 100) / allVotes
            : 0;
        return result.toFixed(0);
    };
    return (
        <div>
            <article className={"poll"}>
                <ul className={"answers"}>
                    {poll.points.map((point, index) => (
                        <li key={point.id + "/" + index}>
                            {!currentVote.voted ? (
                                <button
                                    className="not-yet-voted"
                                    type="button"
                                    onClick={() => vote(point.title)}
                                >
                                    {point.title}
                                </button>
                            ) : (
                                <div className="result">
                                    <div
                                        className="fill"
                                        style={{
                                            backgroundColor: "#bbbbbb",
                                            background: "#bbbbbb",
                                            width: calculatePointPercentage(
                                                poll,
                                                point,
                                                allVotes
                                            )
                                                ? calculatePointPercentage(
                                                      poll,
                                                      point,
                                                      allVotes
                                                  ) + "%"
                                                : 0 + "%",
                                        }}
                                    />
                                    <div className={"labels"}>
                                        <span
                                            className={
                                                "answer " +
                                                (point.title ===
                                                currentVote.option
                                                    ? "vote"
                                                    : "")
                                            }
                                            style={{
                                                fontWeight:
                                                    point.title ===
                                                    currentVote.option
                                                        ? "bold"
                                                        : "inherit",
                                            }}
                                        >
                                            {point.title}
                                        </span>
                                        <span
                                            className={"answer percent"}
                                            style={{ textAlign: "right" }}
                                        >
                                            {calculatePointPercentage(
                                                poll,
                                                point,
                                                allVotes
                                            )
                                                ? calculatePointPercentage(
                                                      poll,
                                                      point,
                                                      allVotes
                                                  )
                                                : 0}
                                            %
                                        </span>
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                    {poll.flagShowResults && currentVote.voted && (
                        <li>
                            <div className={"result"}>
                                <div
                                    className={"fill"}
                                    style={{
                                        backgroundColor: "#bbbbbb",
                                        background: "#bbbbbb",
                                        width: poll.results?.else
                                            ? calculateRoundedElse() + "%"
                                            : null + "%",
                                    }}
                                />
                                <div className={"labels"}>
                                    <span className={" answer "}>
                                        Etwas anderes, und zwar
                                    </span>
                                    <span className={" answer percent"}>
                                        {calculateRoundedElse()}%
                                    </span>
                                </div>
                            </div>
                        </li>
                    )}
                    {!currentVote.voted && !clickedElse && (
                        <button
                            className="not-yet-voted"
                            type="button"
                            onClick={() => {
                                if (readOnly) {
                                    return;
                                }

                                setClickedElse(true);
                            }}
                        >
                            Etwas anderes, und zwar
                        </button>
                    )}
                    {clickedElse && (
                        <li className="not-yet-voted is-else">
                            <form onSubmit={handleVoteSubmit}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item xs={12}>
                                        {!signedUser && (
                                            <UserData
                                                errorState={errorState}
                                                requiredEmail={
                                                    userAllowedToVote(poll)
                                                        .flagEmail
                                                }
                                                requiredName={
                                                    userAllowedToVote(poll)
                                                        .flagName
                                                }
                                                email={
                                                    elseVote.emailOfPerson
                                                        ? elseVote.emailOfPerson
                                                        : ""
                                                }
                                                name={
                                                    elseVote.nameOfPerson
                                                        ? elseVote.nameOfPerson
                                                        : ""
                                                }
                                                handleChange={
                                                    handleElseVoteChange
                                                }
                                            />
                                        )}

                                        <TextField
                                            variant="outlined"
                                            label="Ihr Vorschlag"
                                            value={elseVote.textElse}
                                            onChange={handleElseVoteChange}
                                            name="textElse"
                                            fullWidth
                                            required
                                            error={
                                                elseVote.textElse === ""
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleVoteSubmit}
                                        >
                                            Abschicken
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </li>
                    )}
                </ul>
            </article>
        </div>
    );
}

export default Poll;
