import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import setMandantThemeByServer from "../../utils/setMandantThemeByServer";
import { loadMandantRequest } from "../../xhr/MandantRequests";
import { loadMandantError, loadMandantSuccess } from "./MandantAction";

const LoadMandant = (mandantKey: string): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {
            const mandantRequest = await loadMandantRequest(mandantKey);
            //can make status check here

            setMandantThemeByServer(mandantRequest.data);

            dispatch(loadMandantSuccess(mandantRequest.data));
        } catch (err) {
            dispatch(loadMandantError(err.text));
        }
    };
};

export default LoadMandant;
