/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from "connected-react-router";

import history from "../utils/history";
import languageProviderReducer from "./languageStore/LanguageReducer/LanguageReducer";
import MandantReducer from "./mandantStore/reducer/MandantReducer";
import ProjectSelectboxReducer from "./projectSelectbox/reducer/ProjectSelectboxReducer";
import UserReducer from "./userStore/userReducer/UserReducer";

/**
 * main reducer
 */
export default function createReducer(injectedReducers: any = {}) {
    return {
        User: UserReducer,
        mandant: MandantReducer,
        language: languageProviderReducer,
        projectData: ProjectSelectboxReducer,
        router: connectRouter(history),
        ...injectedReducers,
    };
}
