import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { API_URL_STATIC } from "../../../config/config";
import IProjectResult from "../../../types/IProjectResult";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";

interface IEventTileParams extends IProjectResult {}

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginRight: "20px",
        marginBottom: "20px",
        height: "100%",
    },
    media: {
        height: 160,
    },
});

const ResultTile = (props: IEventTileParams) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
                variant="outlined"
                className={classes.root}
                data-id={props.id}
            >
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={API_URL_STATIC + props.previewImage.url}
                        title={props.title}
                        style={{ height: "220px" }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="h3">
                            {props.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            align="left"
                        >
                            {props.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <a
                        href={API_URL_STATIC + props.document.url}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {!stringIsEmpty(props.linkTitel)
                            ? props.linkTitel
                            : "Das Dokument öffnen"}
                    </a>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default ResultTile;
