import {
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import { ICmsPageData } from "../../../types/ICmsPage";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import {
    deleteCmsPageRequest,
    loadCmsPageListRequest,
} from "../../../xhr/CmsPageRequests";

interface IMapStateProps {
    mandant: IMandant;
}

interface IMapDispatchProps {}

interface IOwnProps {
    mandantKey: string;
}

interface ICmsPageListingParams
    extends IMapStateProps,
        IMapDispatchProps,
        IOwnProps {}

const CmsPageListing = (props: ICmsPageListingParams) => {
    const history = useHistory();
    const confirm = useConfirm();

    const { mandant, mandantKey } = props;
    const [pageList, setPageList] = React.useState<ICmsPageData[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const loadPageList = () => {
        loadCmsPageListRequest(mandantKey)
            .then((result: any) => {
                setPageList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Laden der Seitendaten ist ein Fehler aufgetreten",
                    type: "error",
                });

                // fehler Beim Laden der Seiten
            });
    };

    React.useEffect(() => {
        loadPageList();
    }, []);

    if (!mandant.id) {
        return <></>;
    }

    const actionCreateNewPage = () => {
        history.push("/" + mandant.key + RoutesEnum.ADMIN_CMS_PAGE + "create");
    };

    const actionEditPage = (event: any) => {
        history.push(
            "/" +
                mandant.key +
                RoutesEnum.ADMIN_CMS_PAGE +
                event.currentTarget.dataset.id
        );
    };

    const actionDeletePage = (event: React.MouseEvent<HTMLButtonElement>) => {
        const deleteId = "" + event.currentTarget.dataset.id;

        confirm({
            description: "Möchten sie diese Seite wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                deleteCmsPageRequest(mandantKey, deleteId)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die Seite wurde gelöscht.",
                            type: "success",
                        });
                        loadPageList();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Löschen der Seite ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                return;
            });
    };

    function handleBread(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    data-url={"/" + mandantKey + RoutesEnum.ADMIN_DASHBOARD}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">
                    Liste der CMS Seiten
                </Typography>
            </Breadcrumbs>

            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        color="primary"
                        onClick={actionCreateNewPage}
                        startIcon={<IconAdd />}
                    >
                        Einen neuen CMS Seite anlegen
                    </Button>
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Seiten Titel</TableCell>
                            <TableCell title="Über diese Bezeichnung kann die Seite im Browser aufgerufen werden.">
                                URL Schlüssel
                            </TableCell>
                            <TableCell title="Das Projekt dem die Seite angehängt ist">
                                Projekt
                            </TableCell>
                            <TableCell style={{ width: "100px" }}>
                                Menu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageList.map((page: ICmsPageData, index: number) => {
                            return (
                                <TableRow key={"user" + index}>
                                    <TableCell>{page.title}</TableCell>
                                    <TableCell>{page.project?.title}</TableCell>
                                    <TableCell>{page.urlKey}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            component={"span"}
                                            data-id={page.id}
                                            onClick={actionEditPage}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            component={"span"}
                                            data-id={page.id}
                                            onClick={actionDeletePage}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(CmsPageListing);
