import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Select,
    TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";
import deLocal from "date-fns/locale/de";
import { EditorState } from "draft-js";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import { Format } from "../../../../config/config";
import ICampaign from "../../../../types/appointment/ICampaign";
import ICampaignRoom from "../../../../types/appointment/ICampaignRoom";
import IDocument from "../../../../types/IDocument";
import { formatBytes } from "../../../../utils/bytesConverter";
import ISelectOption from "../../../../xhr/interface/ISelectOption";
import { ICampaignValidationState } from "../validations/campaignDataValidation";

interface ICampaignDataFormProps {
    projectList: ISelectOption[];
    campaignData: ICampaign;
    editIndex: number | null;
    errorState: ICampaignValidationState;
    nextRoom: ICampaignRoom;
    handleCampaignDateChange: (date: Date | null, type: string) => void;
    setRoomToEdit: (event: any) => void;
    handleTextChange: (event: any) => void;
    changeProject: (event: any) => void;
    deleteCampaignPoint: (event: any) => void;
    handleImageChange: (image: IDocument, attrKey: string) => void;
    handleChangeNextRoom: (event: any) => void;
    handleSaveRoom: (event: any) => void;
    handleCheckboxChange: (event: any) => void;
    handleUploadClick: (event: any) => void;
    handleEmptyUploadClick: (event: any) => void;
    handleMailTextChange: (
        key: string,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    handleMailWysiwygChange: (
        key: string,
        field: string,
        editorState: EditorState
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            overflowWrap: "break-word",
            "& div:nth-child(1)": {
                width: "20%",
            },
            "& div:nth-child(2)": {
                width: "25%",
            },
            "& div:nth-child(3)": {
                width: "45%",
            },
            "& div:nth-child(4)": {
                width: "5%",
            },
            "& div:nth-child(5)": {
                width: "5%",
            },
        },
    })
);

const CampaignDataTab = (props: ICampaignDataFormProps) => {
    const {
        handleTextChange,
        handleCampaignDateChange,
        handleChangeNextRoom,
        deleteCampaignPoint,
        handleSaveRoom,
        handleImageChange,
        setRoomToEdit,
        changeProject,
        campaignData,
        projectList,
        nextRoom,
        editIndex,
        handleCheckboxChange,
        handleUploadClick,
        handleEmptyUploadClick,
        handleMailTextChange,
        handleMailWysiwygChange,
    } = props;
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2} className="gap-top">
                <Grid item xs={6} className="gap-bottom">
                    <Typography variant="h2" className="main-header gap-bottom">
                        Stammdaten
                    </Typography>

                    <TextField
                        variant="outlined"
                        label="Titel"
                        name={"title"}
                        id="campaign-title"
                        value={campaignData.title ? campaignData.title : ""}
                        onChange={handleTextChange}
                        fullWidth
                        required={true}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={campaignData.isActive}
                                onChange={handleCheckboxChange}
                                name="isActive"
                            />
                        }
                        className="gap-bottom"
                        label="Die Kampagne ist aktiv"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={campaignData.isPublicAvailable}
                                onChange={handleCheckboxChange}
                                name="isPublicAvailable"
                            />
                        }
                        className="gap-bottom"
                        label="Die Kampagne ist öffentlich"
                    />

                    <TextField
                        variant="outlined"
                        label="Veranstaltungsort"
                        name={"location"}
                        value={
                            campaignData.location ? campaignData.location : ""
                        }
                        onChange={handleTextChange}
                        fullWidth
                    />
                    <TextField
                        variant="outlined"
                        label="Link zum Veranstaltungsort"
                        name={"locationLink"}
                        value={
                            campaignData.locationLink
                                ? campaignData.locationLink
                                : ""
                        }
                        onChange={handleTextChange}
                        fullWidth
                    />
                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel id="project-select-label">
                            Projekt
                        </InputLabel>
                        <Select
                            labelId="project-select-label"
                            value={
                                campaignData.project?.id
                                    ? campaignData.project?.id
                                    : ""
                            }
                            onChange={changeProject}
                            label="Projekt"
                            error={props.errorState.projectId}
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte Projekt wählen</em>
                            </MenuItem>
                            {projectList.map(
                                (project: ISelectOption, index: number) => {
                                    return (
                                        <MenuItem
                                            key={"project-" + index}
                                            value={project.id}
                                        >
                                            {project.label}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        name="description"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={campaignData.description}
                        onChange={handleTextChange}
                        variant="outlined"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    name="startDate"
                                    margin="normal"
                                    id="campaign-end-date"
                                    variant="inline"
                                    label="Start"
                                    required={true}
                                    fullWidth
                                    format={Format.dateInp}
                                    value={
                                        campaignData.startDate
                                            ? new Date(campaignData.startDate)
                                            : null
                                    }
                                    onChange={(date: Date | null) =>
                                        handleCampaignDateChange(
                                            date,
                                            "startDate"
                                        )
                                    }
                                    KeyboardButtonProps={{
                                        "aria-label": "Startdatum anpassen",
                                    }}
                                    error={props.errorState.startDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    name="endDate"
                                    margin="normal"
                                    inputVariant="outlined"
                                    id="campaign-end-date"
                                    variant="inline"
                                    label="Ende"
                                    fullWidth
                                    required={true}
                                    format={Format.dateInp}
                                    value={
                                        campaignData.endDate
                                            ? new Date(campaignData.endDate)
                                            : null
                                    }
                                    onChange={(date: Date | null) =>
                                        handleCampaignDateChange(
                                            date,
                                            "endDate"
                                        )
                                    }
                                    KeyboardButtonProps={{
                                        "aria-label": "Enddatum anpassen",
                                    }}
                                    error={props.errorState.endDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MuiPickersUtilsProvider
                            locale={deLocal}
                            utils={DateFnsUtils}
                        >
                            <KeyboardDatePicker
                                name="booking-end-date"
                                required={true}
                                margin="normal"
                                inputVariant="outlined"
                                id="campaign-booking-end-date"
                                variant="inline"
                                label="Ende der Buchungen"
                                fullWidth
                                format={Format.dateInp}
                                value={
                                    campaignData.bookingEndDate
                                        ? new Date(campaignData.bookingEndDate)
                                        : null
                                }
                                onChange={(date: Date | null) =>
                                    handleCampaignDateChange(
                                        date,
                                        "bookingEndDate"
                                    )
                                }
                                KeyboardButtonProps={{
                                    "aria-label": "Enddatum anpassen",
                                }}
                                error={props.errorState.bookingEndDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid container spacing={2} className="gap-top">
                        <Grid item xs={12}>
                            <Typography
                                variant="h2"
                                className="main-header gap-bottom"
                            >
                                Uploads
                            </Typography>
                            <ImageUpload
                                onChange={handleImageChange}
                                docData={campaignData.image}
                                name={"campaignImage"}
                                label={"Bild zur Kampagne *"}
                                defaultFilename="Campaign.jpg"
                            />

                            <Card
                                variant="outlined"
                                className="upload-image-card"
                            >
                                <CardActionArea disableRipple={true}>
                                    <CardContent>
                                        <Typography variant="h3">
                                            Anhang
                                        </Typography>

                                        <input
                                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                            id={"campaign-data-upload"}
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={handleUploadClick}
                                        />

                                        <label htmlFor={"campaign-data-upload"}>
                                            Dokument anhängen
                                            <Fab
                                                component="span"
                                                style={{
                                                    marginTop: "16px",
                                                    marginLeft: "16px",
                                                    marginBottom: "16px",
                                                }}
                                            >
                                                <AttachmentIcon />{" "}
                                            </Fab>
                                        </label>

                                        {campaignData.document?.name && (
                                            <Fab
                                                component="span"
                                                onClick={handleEmptyUploadClick}
                                                style={{
                                                    marginTop: "16px",
                                                    marginLeft: "16px",
                                                    marginBottom: "16px",
                                                }}
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        )}

                                        {campaignData.document?.name && (
                                            <div className="gap-bottom_2">
                                                <Typography component="div">
                                                    {
                                                        campaignData.document
                                                            ?.name
                                                    }
                                                </Typography>

                                                {campaignData.document
                                                    ?.size && (
                                                    <Typography>
                                                        Dateigröße :{" "}
                                                        {formatBytes(
                                                            campaignData
                                                                .document?.size
                                                        )}
                                                    </Typography>
                                                )}
                                            </div>
                                        )}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="gap-top">
                        <Grid item xs={12}>
                            <Typography
                                variant="h2"
                                className="main-header gap-bottom"
                            >
                                Projekt E-Mails
                            </Typography>

                            <TextField
                                variant="outlined"
                                label="Verwaltungs E-Mail"
                                name="managementEmail"
                                value={campaignData.managementEmail}
                                onChange={handleTextChange}
                                fullWidth
                                required={true}
                                error={props.errorState.managementEmail}
                            />
                            <TextField
                                variant="outlined"
                                label="Hilfe E-Mail"
                                name="assistantEmail"
                                value={campaignData.assistantEmail}
                                onChange={handleTextChange}
                                fullWidth
                                required={true}
                                error={props.errorState.assistantEmail}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className={"gap-bottom"}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Räume verwalten
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={4} lg={4}>
                            <TextField
                                variant="outlined"
                                label="Raumname"
                                name={"name"}
                                value={nextRoom.name ? nextRoom.name : ""}
                                onChange={handleChangeNextRoom}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <TextField
                                variant="outlined"
                                label="Raummanager"
                                name={"assignedName"}
                                value={
                                    nextRoom.assignedName
                                        ? nextRoom.assignedName
                                        : ""
                                }
                                onChange={handleChangeNextRoom}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <TextField
                                variant="outlined"
                                label="Raummanager-email"
                                name={"assignedEmail"}
                                value={
                                    nextRoom.assignedEmail
                                        ? nextRoom.assignedEmail
                                        : ""
                                }
                                onChange={handleChangeNextRoom}
                                fullWidth
                                error={props.errorState.roomAssignedEmail}
                            />
                        </Grid>
                    </Grid>

                    <Button color={"primary"} onClick={handleSaveRoom}>
                        {editIndex !== null
                            ? "Raum editieren"
                            : "Raum hinzufügen"}
                    </Button>

                    <List className="gap-bottom">
                        {campaignData.campaignRooms.map(
                            (room: ICampaignRoom, index: number) => {
                                return (
                                    <div key={"element-" + index}>
                                        <Divider />
                                        <ListItem
                                            className={clsx(
                                                classes.list,
                                                editIndex == index
                                                    ? "selected-item"
                                                    : ""
                                            )}
                                        >
                                            <ListItemText primary={room.name} />
                                            <ListItemText
                                                primary={room.assignedName}
                                            />
                                            <ListItemText
                                                primary={room.assignedEmail}
                                            />
                                            <ListItemIcon
                                                data-pos={index}
                                                onClick={setRoomToEdit}
                                            >
                                                <EditIcon />
                                            </ListItemIcon>
                                            {!editIndex !== null && (
                                                <ListItemIcon
                                                    data-pos={index}
                                                    onClick={
                                                        deleteCampaignPoint
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                            )}
                                        </ListItem>
                                    </div>
                                );
                            }
                        )}
                        <Divider />
                    </List>

                    <Typography variant="h2" className="main-header gap-bottom">
                        Erinnerungs E-Mail
                    </Typography>
                    <TextField
                        id="reminder-mail-title"
                        variant="outlined"
                        label="Titel"
                        name="title"
                        value={
                            campaignData?.reminderMail?.title
                                ? campaignData.reminderMail.title
                                : ""
                        }
                        onChange={(event) =>
                            handleMailTextChange("reminderMail", event)
                        }
                        fullWidth
                    />
                    <TextEdit
                        handleChange={(editorState) => {
                            handleMailWysiwygChange(
                                "reminderMail",
                                "body",
                                editorState
                            );
                        }}
                        title="Inhalt"
                        defaultValue={campaignData?.reminderMail?.body}
                        gapBottom={true}
                    />
                    <TextEdit
                        handleChange={(editorState) => {
                            handleMailWysiwygChange(
                                "reminderMail",
                                "signatur",
                                editorState
                            );
                        }}
                        title="Signatur"
                        defaultValue={campaignData?.reminderMail?.signatur}
                        gapBottom={true}
                    />
                    <Typography
                        variant="h2"
                        className="main-header gap-bottom gap-top"
                    >
                        Bestätigungs E-Mail
                    </Typography>
                    <TextField
                        id="confirmation-mail-title"
                        variant="outlined"
                        label="Titel"
                        name="title"
                        value={
                            campaignData?.confirmationMail?.title
                                ? campaignData.confirmationMail.title
                                : ""
                        }
                        onChange={(event) =>
                            handleMailTextChange("confirmationMail", event)
                        }
                        fullWidth
                    />
                    <TextEdit
                        handleChange={(editorState) => {
                            handleMailWysiwygChange(
                                "confirmationMail",
                                "body",
                                editorState
                            );
                        }}
                        title="Inhalt"
                        gapBottom={true}
                        defaultValue={campaignData?.confirmationMail?.body}
                    />
                    <TextEdit
                        handleChange={(editorState) => {
                            handleMailWysiwygChange(
                                "confirmationMail",
                                "signatur",
                                editorState
                            );
                        }}
                        title="Signatur"
                        gapBottom={true}
                        defaultValue={campaignData?.confirmationMail?.signatur}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CampaignDataTab;
