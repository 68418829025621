import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { loadPublicProjectSelectboxRequest } from "../../xhr/ProjectRequests";
import {
    loadProjectSelectboxError,
    loadProjectSelectboxSuccess,
} from "./ProjectSelectboxAction";

const LoadProjectSelectbox = (
    mandantKey: string
): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {
            const projectRequest = await loadPublicProjectSelectboxRequest(
                mandantKey
            );
            //can make status check here
            dispatch(loadProjectSelectboxSuccess(projectRequest.data));
        } catch (err) {
            dispatch(loadProjectSelectboxError(err.text));
        }
    };
};

export default LoadProjectSelectbox;
