import { Divider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import * as router from "react-router-dom";

import RoleEnum from "../../enums/RoleEnum";
import IApplicationRootState from "../../store/StoreTypes";
import IMandant from "../../types/IMandant";
import userHasRoles from "../../utils/userHasRole";

interface IListMenuItems {
    menuItem: string;
    route: string;
    roles?: RoleEnum[];
    onlySteg?: boolean | null;
}

interface IAdminSideMenuMapStateProps {
    mandant: IMandant;
    activeUser: any;
}

interface IAdminSideMenuMapDispatchProps {}

interface IOwnProps {
    selectedIndex: number;
    handleLogout: () => void;
    setSelectedIndex: (index: number) => void;
    url: string;
}
interface IAdminSideMenuParams
    extends IAdminSideMenuMapStateProps,
        IAdminSideMenuMapDispatchProps,
        IOwnProps {}

const AdminSideMenu = (props: IAdminSideMenuParams) => {
    const { activeUser, selectedIndex, setSelectedIndex, handleLogout, url } =
        props;

    const mandantKey = props.mandant.key;

    const listMenuItems: IListMenuItems[] = [
        { menuItem: "Kunden Verwaltung", route: "portale", onlySteg: true },
        { menuItem: "Projekt Verwaltung", route: "projekte" },
        { menuItem: "Kommentare", route: "kommentare" },
        { menuItem: "Umfragen", route: "umfragen" },
        { menuItem: "Termin Kampagnen", route: "kampagne" },
        { menuItem: "Kommentare Karten", route: "geofeedback" },
        { menuItem: "Kontakt Anfragen", route: "feedback/" },
        {
            menuItem: "Benutzer Verwaltung",
            route: "anwender",
            roles: [RoleEnum.ROLE_ADMIN],
            onlySteg: null,
        },
        {
            menuItem: "CMS Seiten Verwaltung",
            route: "cms",
            roles: [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONTENT_MANAGER],
        },
        {
            menuItem: "Portal Einstellungen",
            route: "portal",
            roles: [RoleEnum.ROLE_ADMIN],
        },
        {
            menuItem: "Passwort ändern",
            route: "passwortAendern",
            onlySteg: null,
        },
        { menuItem: "Abmelden", route: "", onlySteg: null },
    ];

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index);
    };

    return (
        <Drawer className="drawer" variant="permanent">
            <Toolbar />
            <div className="drawerContainer">
                <List>
                    <Divider />
                    {listMenuItems.map((elem, index) => {
                        if (elem.roles) {
                            if (!userHasRoles(activeUser, elem.roles)) {
                                return null;
                            }
                        }

                        // the steg admin page
                        if (mandantKey === "stegadmin") {
                            if (elem.onlySteg === undefined) {
                                return null;
                            }
                        } else {
                            if (elem.onlySteg === true) {
                                return null;
                            }
                        }

                        return (
                            <div key={index}>
                                <router.Link
                                    key={`${elem.route}${index}`}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    to={
                                        !elem.route
                                            ? "/login"
                                            : `${url}/${elem.route}`
                                    }
                                >
                                    <ListItem
                                        button
                                        selected={selectedIndex === index}
                                        onClick={(event) => {
                                            if (elem.route) {
                                                handleListItemClick(
                                                    event,
                                                    index
                                                );
                                            } else {
                                                handleLogout();
                                            }
                                        }}
                                    >
                                        <ListItemText primary={elem.menuItem} />
                                    </ListItem>
                                </router.Link>
                                <Divider />
                            </div>
                        );
                    })}
                </List>
            </div>
        </Drawer>
    );
};
const mapStateToProps: MapStateToPropsParam<
    IAdminSideMenuMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.User.userData,
    };
};

export default connect(mapStateToProps)(AdminSideMenu);
