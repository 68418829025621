import ActionTypes from "../userAction/ActionTypesEnum";
import { AppActions, IUserState } from "./types";

export const initialState: IUserState = {
    loading: false,
    loginSuccess: false,
    emailIsValid: false,
    emailErrorMessage: "",
    passwordIsValid: false,
    passwordErrorMessage: "",
    errorMessage: "",
    userData: null,
};

function UserReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: IUserState = initialState,
    action: AppActions
): IUserState {
    switch (action.type) {
        case ActionTypes.LOGIN_PENDING: {
            return {
                ...initialState,
                loading: true,
            };
        }
        case ActionTypes.LOGIN_SUCCESS: {
            return {
                ...initialState,
                loginSuccess: true,
                userData: action.payload,
            };
        }
        case ActionTypes.UPDATE_USERDATA: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case ActionTypes.EMAIL_ERROR: {
            return {
                ...state,
                loading: false,
                emailIsValid: true,
                emailErrorMessage: action.payload,
                passwordIsValid: false,
                passwordErrorMessage: "",
            };
        }
        case ActionTypes.PASSWORD_ERROR: {
            return {
                ...state,
                loading: false,
                emailIsValid: false,
                emailErrorMessage: "",
                passwordIsValid: true,
                passwordErrorMessage: action.payload,
            };
        }
        case ActionTypes.LOGIN_ERROR: {
            return {
                ...state,
                loading: false,
                passwordIsValid: true,
                emailIsValid: true,
                loginSuccess: false,
                errorMessage: action.payload,
            };
        }
        case ActionTypes.LOGOUT_SUCCESS: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}

export default UserReducer;
