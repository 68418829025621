import React from "react";

const toLabel = (toProcess: string, fallback: any): any => {
    // eslint-disable-next-line eqeqeq
    if (toProcess == null) {
        return fallback;
    }

    if (String(toProcess).trim() === "") {
        return fallback;
    }

    const parts = toProcess.split("/");

    if (parts.length === 1) {
        return parts[0];
    }

    return (
        <span>
            {parts[0]} <br />
            <sub>{parts[1]}</sub>
        </span>
    );
};

export default toLabel;
