import { action } from "typesafe-actions";

import IUser from "../interfaces/IUser";
import ActionTypes from "./ActionTypesEnum";

export const login = (user: any) => action(ActionTypes.LOGIN, user);

export const updateUserdata = (user: IUser) =>
    action(ActionTypes.UPDATE_USERDATA, user);
export const loginSuccess = (user: any) =>
    action(ActionTypes.LOGIN_SUCCESS, user);
export const loginPending = () => action(ActionTypes.LOGIN_PENDING);
export const loginError = (error: string) =>
    action(ActionTypes.LOGIN_ERROR, error);
export const emailError = (message: string) =>
    action(ActionTypes.EMAIL_ERROR, message);
export const passwordError = (message: string) =>
    action(ActionTypes.PASSWORD_ERROR, message);
export const logout = () => action(ActionTypes.LOGOUT);
export const logoutSuccess = () => action(ActionTypes.LOGOUT_SUCCESS);
export const logoutError = (error: any) =>
    action(ActionTypes.LOGOUT_ERROR, error);
