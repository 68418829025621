import "./ProjectDataTab.css";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Divider, Grid, Paper, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import Notify from "../../../../components/notify/Notify";
import OlMap from "../../../../components/olMap/adminMap/OlMap";
import { Format } from "../../../../config/config";
import IDocument from "../../../../types/IDocument";
import InitialDocumentData from "../../../../types/initialData/InitialDocumentData";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import IProjectFull from "../../../../types/IProjectFull";
import ISnackbarState from "../../../../types/ISnackbarState";
import { IProjectValidationState } from "../projectDataValidation";

interface IProjectDataFormProps {
    mandantKey: string;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    projectData: IProjectFull;
    onEditStart: (e: any) => void;
    onEditEnd: (e: any) => void;
    errorState: IProjectValidationState;
    onProjectLocationChange: (lat: number, lng: number, zoom: number) => void;
    handleZoomChange: (zoom: number) => void;
}

const ProjectDataTab = (props: IProjectDataFormProps) => {
    const {
        projectData,
        onTextChange,
        onImageChange,
        onCheckboxChange,
        onProjectLocationChange,
        handleZoomChange,
    } = props;
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    return (
        <Paper variant="outlined" className="main-paper">
            <Typography variant="h2" className="main-header gap-bottom">
                Stammdaten
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={props.errorState.title}
                                name="title"
                                label="Title"
                                fullWidth
                                value={projectData.title}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />

                            <TextField
                                name="shortTitle"
                                label="Kurzer Name (für den Tab)"
                                fullWidth
                                value={projectData.shortTitle}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />

                            <TextField
                                error={props.errorState.urlKey}
                                name="urlKey"
                                label="Adressschlüssel"
                                fullWidth
                                value={projectData.urlKey}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    name="theStart"
                                    margin="normal"
                                    id="inp-project-start"
                                    variant="inline"
                                    label="Start"
                                    required={true}
                                    fullWidth
                                    format={Format.dateInp}
                                    value={
                                        projectData.theStart
                                            ? new Date(projectData.theStart)
                                            : null
                                    }
                                    onChange={props.onEditStart}
                                    KeyboardButtonProps={{
                                        "aria-label": "Startdatum anpassen",
                                    }}
                                    error={props.errorState.theStart}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <MuiPickersUtilsProvider
                                locale={deLocal}
                                utils={DateFnsUtils}
                            >
                                <KeyboardDatePicker
                                    name="theEnd"
                                    margin="normal"
                                    inputVariant="outlined"
                                    id="inp-project-ende"
                                    variant="inline"
                                    label="Ende"
                                    fullWidth
                                    format={Format.dateInp}
                                    value={
                                        projectData.theEnd
                                            ? new Date(projectData.theEnd)
                                            : null
                                    }
                                    onChange={props.onEditEnd}
                                    KeyboardButtonProps={{
                                        "aria-label": "Enddatum anpassen",
                                    }}
                                    error={props.errorState.theEnd}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            error={props.errorState.shortDesc}
                            name="shortDesc"
                            id="outlined-multiline-flexible"
                            label="Kurzbeschreibung"
                            fullWidth
                            multiline
                            /* rowsMax={6} */
                            value={projectData.shortDesc}
                            onChange={onTextChange}
                            variant="outlined"
                            required={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <FormLabel component="legend">
                            Anzeige Einstellungen
                        </FormLabel>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!projectData.flagShowTitle
                                            }
                                            onChange={onCheckboxChange}
                                            name="flagShowTitle"
                                        />
                                    }
                                    label="Überschrift in der Maske anzeigen"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!projectData.flagEnableProjectCms
                                            }
                                            onChange={onCheckboxChange}
                                            name="flagEnableProjectCms"
                                        />
                                    }
                                    label="Projekt CMS aktivieren"
                                />
                            </Grid>
                            {!projectData.flagEnableProjectCms && (
                                <>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabStart
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabStart"
                                                />
                                            }
                                            label="Start Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabStart"
                                            label="Start Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabStart}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabInfo
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabInfo"
                                                />
                                            }
                                            label="Info Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabInfo"
                                            label="Info Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabInfo}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabEvents
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabEvents"
                                                />
                                            }
                                            label="Veranstaltungen Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabEvents"
                                            label="Veranstaltungen Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabEvents}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabResults
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabResults"
                                                />
                                            }
                                            label="Bisherige Ergebnisse Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabResults"
                                            label="Bisherige Ergebnisse Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabResults}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabParticipate
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabParticipate"
                                                />
                                            }
                                            label="Beteiligung Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabParticipate"
                                            label="Beteiligung Tab Titel"
                                            fullWidth
                                            value={
                                                projectData.titleTabParticipate
                                            }
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabConsulting
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabConsulting"
                                                />
                                            }
                                            label="Beratung Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabConsulting"
                                            label="Beratung Tab Titel"
                                            fullWidth
                                            value={
                                                projectData.titleTabConsulting
                                            }
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabTeam
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabTeam"
                                                />
                                            }
                                            label="Team Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabTeam"
                                            label="Team Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabTeam}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!projectData.flagTabCampaign
                                                    }
                                                    onChange={onCheckboxChange}
                                                    name="flagTabCampaign"
                                                />
                                            }
                                            label="Kampagne Tab anzeigen"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <TextField
                                            name="titleTabCampaign"
                                            label="Kampagne Tab Titel"
                                            fullWidth
                                            value={projectData.titleTabCampaign}
                                            onChange={onTextChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} className="gap-top">
                        <FormLabel component="legend">
                            Kommentar Einstellungen
                        </FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagCommentsOpen}
                                        onChange={onCheckboxChange}
                                        name="flagCommentsOpen"
                                    />
                                }
                                label="Kommentare für das Projekt aktiv"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            !!projectData.flagCommentsReadonly
                                        }
                                        onChange={onCheckboxChange}
                                        name="flagCommentsReadonly"
                                    />
                                }
                                label="Kommentare auf Readonly stellen"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            !!projectData.flagCommentsRegistered
                                        }
                                        onChange={onCheckboxChange}
                                        name="flagCommentsRegistered"
                                    />
                                }
                                label="Nur registrierte Benutzer dürfen Kommentare schreiben"
                            />
                            <Divider />
                            <FormLabel component="legend" className="gap-top">
                                Mitmachen Einstellungen
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagMapComments}
                                        onChange={onCheckboxChange}
                                        name="flagMapComments"
                                    />
                                }
                                label="Mitmachen / Kartenkommentare aktivieren"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            !!projectData.flagMapCommentsReadonly
                                        }
                                        onChange={onCheckboxChange}
                                        name="flagMapCommentsReadonly"
                                    />
                                }
                                label="Mitmachen Karte ist nur Lesbar"
                            />

                            <Divider />
                            <FormLabel component="legend" className="gap-top">
                                Archiv Einstellungen
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagArchive}
                                        onChange={onCheckboxChange}
                                        name="flagArchive"
                                    />
                                }
                                label="Projekt ist Archiviert"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="map-card">
                        <Typography variant="h3">
                            Projekt auf der Karte
                        </Typography>
                        {projectData && (
                            <OlMap
                                location={[
                                    projectData.longitude || 0,
                                    projectData.latitude || 0,
                                ]}
                                zoom={projectData.zoom || 0}
                                onLocationSelected={onProjectLocationChange}
                                persistZoomChange={handleZoomChange}
                            />
                        )}
                    </Paper>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.imageMenu}
                        name={"imageMenu"}
                        label={"Bild im Menüeintrag *"}
                        defaultFilename="Menu.jpg"
                        errorState={props.errorState.imageMenu}
                    />
                    <ImageUpload
                        errorState={props.errorState.imageHeader}
                        onChange={onImageChange}
                        docData={projectData.imageHeader}
                        name={"imageHeader"}
                        label={"Kopf Bild *"}
                        defaultFilename="Header.jpg"
                    />
                    <ImageUpload
                        onChange={onImageChange}
                        docData={
                            projectData.imageMap
                                ? projectData.imageMap
                                : { ...InitialDocumentData }
                        }
                        name={"imageMap"}
                        label={"Projektkarte als Bild"}
                        defaultFilename="Map.jpg"
                    />
                </Grid>
                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Grid>
        </Paper>
    );
};

export default ProjectDataTab;
