import DashboardContainer from "../masks/admin/dashboardContainer/DashboardContainer";
import CmsPage from "../masks/public/cmsPage/CmsPage";
import Contact from "../masks/public/contact/Contact";
import EventData from "../masks/public/eventData/EventData";
import EventList from "../masks/public/eventList/EventList";
import ForgotPassword from "../masks/public/forgotPassword/ForgotPassword";
import Login from "../masks/public/login/Login";
import MandantOverview from "../masks/public/mandantOverview/MandantOverview";
import AllMap from "../masks/public/map/AllMap";
import ProjectData from "../masks/public/projectData/ProjectData";
import ProjectList from "../masks/public/projectList/ProjectList";
import ResetPassword from "../masks/public/resetPassword/ResetPassword";
import RoutesEnum from "./RoutesEnum";

const Routes = [
    {
        path: "/:mandantKey/datenverwaltung",
        name: "DashboardContainer",
        component: DashboardContainer,
    },
    {
        path: "/:mandantKey/anmelden",
        name: "publicLogin",
        component: Login,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_FORGOT_PASSWORD,
        name: "forgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_RESET_PASSWORD + "/:token",
        name: "resetPasswordWithId",
        component: ResetPassword,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_RESET_PASSWORD,
        name: "resetPassword",
        component: ResetPassword,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_PROJECT + "/:id",
        name: "projectData",
        component: ProjectData,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_PROJECT_LIST,
        name: "projectListExplicit",
        component: ProjectList,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_MAP,
        name: "publicMap",
        component: AllMap,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_EVENT + "/:id",
        name: "eventData",
        component: EventData,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_EVENT_LIST,
        name: "eventListExplicit",
        component: EventList,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_CONTACT,
        name: "kontaktForm",
        component: Contact,
    },
    {
        path: "/:mandantKey" + RoutesEnum.PORTAL_PAGE + "/:pageKey",
        name: "cmsPage",
        component: CmsPage,
    },
    {
        path: "/:mandantKey" + "/:id/seite/:pageKey",
        name: "projectDataPageByRootKey_Tab",
        component: ProjectData,
    },
    {
        path: "/:mandantKey" + "/:id/:tabKey",
        name: "projectDataByRootKey_Tab",
        component: ProjectData,
    },
    {
        path: "/:mandantKey" + "/:id",
        name: "projectDataByRootKey",
        component: ProjectData,
    },
    {
        path: "/:mandantKey/",
        name: "projectList",
        component: ProjectList,
    },
    {
        path: "/",
        name: "mandantOverview",
        component: MandantOverview,
    },
];

export default Routes;
