import { Grid, Paper } from "@material-ui/core";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IProjectFull from "../../../../types/IProjectFull";

type IConsultingTabProps = {
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
};

const ConsultingTab = (props: IConsultingTabProps) => {
    const { projectData, onChangeWysiwyg, onImageChange } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "consultingText");
                        }}
                        title="Beschreibung Beratung"
                        defaultValue={
                            props.projectData.consultingText
                                ? projectData.consultingText
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.imageConsulting}
                        name={"imageConsulting"}
                        label={"Beratungs Bild *"}
                        defaultFilename="Consulting.jpg"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ConsultingTab;
