import { TextField } from "@material-ui/core";
import React from "react";

import { IErrorState } from "./tool";

interface IOwnProps {
    requiredEmail: boolean;
    requiredName: boolean;
    handleChange: (e: any) => void;
    email: string;
    name: string;
    errorState: IErrorState;
}
interface IUserData extends IOwnProps {}
function UserData(props: IUserData) {
    const {
        email,
        name,
        handleChange,
        requiredName,
        requiredEmail,
        errorState,
    } = props;

    return (
        <>
            {requiredEmail && (
                <TextField
                    fullWidth
                    variant="outlined"
                    label=" Email "
                    value={email}
                    onChange={handleChange}
                    name="emailOfPerson"
                    error={errorState.nameIsValid}
                    required={true}
                />
            )}

            {requiredName && (
                <TextField
                    fullWidth
                    variant="outlined"
                    label=" Name "
                    value={name}
                    onChange={handleChange}
                    name="nameOfPerson"
                    error={errorState.emailIsValid}
                    required={true}
                />
            )}
        </>
    );
}

export default UserData;
