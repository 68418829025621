import "./login.css";

import {
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconAdd from "@material-ui/icons/Add";
import IconEdit from "@material-ui/icons/Edit";
import IconVpnKey from "@material-ui/icons/VpnKey";
import { Alert } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Helmet } from "react-helmet";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    connect,
} from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import CmsImage from "../../../components/cms/CmsImage";
import HtmlContent from "../../../components/cms/HtmlContent";
import ImageHeader from "../../../components/layout/ImageHeader";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import LoginAction from "../../../store/userStore/asyncActions/LoginAction";
import IUser from "../../../store/userStore/interfaces/IUser";
import {
    loginError,
    updateUserdata,
} from "../../../store/userStore/userAction/UserAction";
import ICreateUser from "../../../types/ICreateUser";
import IMandant from "../../../types/IMandant";
import InitialCreateUserData from "../../../types/initialData/InitialCreateUserData";
import InitialLoginUserData from "../../../types/initialData/InitialLoginUserData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import toInputString from "../../../utils/form/toInputString";
import validateEmail from "../../../utils/form/validateEmail";
import {
    checkUserDuplicateEmailRequest,
    checkUserDuplicateNicknameRequest,
    createUserPortalRequest,
    updateUserPortalRequest,
} from "../../../xhr/UserRequests";

interface IFormError {
    firstname?: string;
    lastname?: string;
    nickName?: string;
    email?: string;
    city?: string;
    zip?: string;
    street?: string;
    streetNo?: string;
    password?: string;
    passwordCheck?: string;
    confirmRegister?: string;
    confirmLocal?: string;
}

interface ILoginMapStateProps {
    emailIsValid: boolean;
    emailErrorMessage: string;
    passwordIsValid: boolean;
    passwordErrorMessage: string;
    errorMessage: string;
    loading: boolean;
    userData: IUser | null;
    mandant: IMandant;
    dispatchLoginSuccess: boolean;
}

interface ILoginDispatchProps {
    handleOnLoginEvent: (
        email: string,
        password: string,
        mandantKey: string
    ) => void;
    handleLoginInit: () => void;
    dispatchUpdateUser: (userData: IUser) => void;
}

type IOwnProps = {};

type ILoginParams = ILoginMapStateProps & ILoginDispatchProps & IOwnProps;

const Login = (props: ILoginParams) => {
    const {
        handleOnLoginEvent,
        loading,
        mandant,
        userData,
        handleLoginInit,
        dispatchLoginSuccess,
        dispatchUpdateUser,
    } = props;

    const { mandantKey } = useParams<{ mandantKey: string }>();
    const history = useHistory();
    const [user, setUser] = React.useState<any>({
        ...InitialLoginUserData,
    });
    const [isNewUser, setIsNewUser] = React.useState<boolean>(false);

    const [createUser, setCreateUser] = React.useState<ICreateUser>({
        ...InitialCreateUserData,
    });

    React.useEffect(() => {
        handleLoginInit();
        setIsNewUser(!!userData);

        if (userData) {
            if (mandant.flagSimpleLogin) {
                setCreateUser({
                    ...InitialCreateUserData,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    nickName: userData.nickName,
                    email: userData.email,
                });
            } else {
                setCreateUser({
                    ...InitialCreateUserData,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    nickName: userData.nickName,
                    email: userData.email,
                    city: userData.city,
                    zip: userData.zip,
                    street: userData.street,
                    streetNo: userData.streetNo,
                });
            }
        }
    }, [handleLoginInit, userData]);

    const { errorMessage } = props;

    const [confirmRegister, setConfirmRegister] =
        React.useState<boolean>(false);
    const [confirmLocal, setConfirmLocal] = React.useState<boolean>(false);

    const [registerError, setRegisterError] = React.useState<string | null>(
        null
    );

    const [errorState, setErrorState] = React.useState<IFormError>({});

    const [registerSuccess, setRegisterSuccess] =
        React.useState<boolean>(false);

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const handleChange = (e: any) => {
        const newData = { ...user, [e.target.name]: [e.target.value] };

        // @ts-ignore
        errorState["" + e.target.name] = null;
        setErrorState({ ...errorState });

        setUser(newData);
    };

    const handleCreateChange = (e: any) => {
        const newData = { ...createUser, [e.target.name]: e.target.value };
        setCreateUser(newData);
    };

    const handleOnLoginClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isNewUser) {
            handleOnLoginEvent(user.email[0], user.password[0], mandantKey);
        }
    };

    const handleConfirmRegister = (e: any) => {
        setConfirmRegister(e.target.checked);
    };

    const handleConfirmLocal = (e: any) => {
        setConfirmLocal(e.target.checked);
    };

    /**
     * @param isNew
     */
    const checkAllInfoSet = (isNew: boolean): Promise<boolean> => {
        const newErrorState: IFormError = {};
        let isValid = true;

        const weNeetToWait: Promise<any>[] = [Promise.resolve()];

        if (("" + createUser.firstname).trim() === "") {
            newErrorState.firstname = "Bitte Vornamen angeben.";
            isValid = false;
        }
        if (("" + createUser.lastname).trim() === "") {
            newErrorState.lastname = "Bitte Nachnamen angeben.";
            isValid = false;
        }
        if (("" + createUser.nickName).trim() === "") {
            newErrorState.nickName = "Bitte Anzeigenamen angeben.";
            isValid = false;
        } else {
            weNeetToWait.push(
                checkUserDuplicateNicknameRequest(
                    mandantKey,
                    createUser.nickName
                )
                    .then((response: any) => {
                        if (response.data.duplicate) {
                            newErrorState.nickName =
                                "Der Anzeigename ist bereits vergeben.";
                            isValid = false;
                        }

                        return Promise.resolve(response.data);
                    })
                    .catch((error: any) => {
                        // ok need to check what to do
                        console.error(
                            "checkUserDuplicateNicknameRequestError",
                            error
                        );
                    })
            );
        }
        if (!validateEmail(("" + createUser.email).trim())) {
            newErrorState.email = "Bitte eine korrekte E-Mail angeben.";
            isValid = false;
        } else {
            weNeetToWait.push(
                checkUserDuplicateEmailRequest(mandantKey, createUser.email)
                    .then((response: any) => {
                        if (response.data.duplicate) {
                            newErrorState.email =
                                "Die E-Mail ist bereits vergeben.";
                            isValid = false;
                        }

                        return Promise.resolve(response.data);
                    })
                    .catch((error: any) => {
                        // ok need to check what to do
                        console.error(
                            "checkUserDuplicateEmailRequestError",
                            error
                        );
                    })
            );
        }

        if (!mandant.flagSimpleLogin) {
            if (("" + createUser.city).trim() === "") {
                newErrorState.city = "Bitte die Stadt angeben.";
                isValid = false;
            }
            if (("" + createUser.zip).trim() === "") {
                newErrorState.zip = "Bitte die Postleitzahl angeben.";
                isValid = false;
            }
            if (("" + createUser.street).trim() === "") {
                newErrorState.street = "Bitte die Straße angeben.";
                isValid = false;
            }
            if (("" + createUser.streetNo).trim() === "") {
                newErrorState.streetNo = "Bitte die Hausnummer angeben.";
                isValid = false;
            }
        }

        if (isNew) {
            if (("" + createUser.password).trim() === "") {
                newErrorState.password = "Bitte ein Passwort setzen.";
                isValid = false;
            }

            if (
                ("" + createUser.password).trim() !==
                ("" + createUser.passwordCheck).trim()
            ) {
                newErrorState.passwordCheck =
                    "Die Passwörter sind nicht gleich.";
                isValid = false;
            }

            if (!confirmLocal) {
                newErrorState.confirmLocal = "Bitte bestätigen";
                isValid = false;
            }

            if (!confirmRegister) {
                newErrorState.confirmRegister = "Bitte bestätigen";
                isValid = false;
            }
        } else {
            if (("" + createUser.password).trim() !== "") {
                if (
                    ("" + createUser.password).trim() !==
                    ("" + createUser.passwordCheck).trim()
                ) {
                    newErrorState.password =
                        "Die Passwörter sind nicht gleich.";
                    isValid = false;
                }
            }
        }

        return Promise.all(weNeetToWait).then(() => {
            setErrorState(newErrorState);
            return Promise.resolve(isValid);
        });
    };

    const onLinkClick = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        history.push(
            "/" +
                mandantKey +
                RoutesEnum.PORTAL_PAGE +
                "/" +
                event.currentTarget.dataset.page
        );
    };

    const onForgotClick = () => {
        history.push("/" + mandantKey + RoutesEnum.PORTAL_FORGOT_PASSWORD);
    };

    /**
     */
    const createNewUser = () => {
        checkAllInfoSet(isNewUser).then((isValid: boolean) => {
            if (!isValid) {
                return;
            }

            createUserPortalRequest(mandantKey, createUser)
                .then(() => {
                    setRegisterSuccess(true);
                    setIsNewUser(false);
                })
                .catch(() => {
                    setRegisterError(
                        "Es ist ein Fehler beim Registrieren aufgetreten"
                    );
                });
        });
    };

    const updateUser = () => {
        checkAllInfoSet(false).then((isValid: boolean) => {
            if (!isValid) {
                return;
            }

            updateUserPortalRequest(mandantKey, createUser)
                .then(() => {
                    if (userData) {
                        if (mandant.flagSimpleLogin) {
                            // here always true, just code for the linter
                            dispatchUpdateUser({
                                ...userData,
                                firstname: createUser.firstname,
                                lastname: createUser.lastname,
                                nickName: createUser.nickName,
                                email: createUser.email,
                            });
                        } else {
                            // here always true, just code for the linter
                            dispatchUpdateUser({
                                ...userData,
                                firstname: createUser.firstname,
                                lastname: createUser.lastname,
                                nickName: createUser.nickName,
                                email: createUser.email,
                                city: createUser.city,
                                zip: createUser.zip,
                                street: createUser.street,
                                streetNo: createUser.streetNo,
                            });
                        }
                    }

                    setSnackBar({
                        isOpen: true,
                        type: "success",
                        message: "Ihre Benutzerdaten wurde angepasst.",
                    });
                })
                .catch(() => {
                    setRegisterError(
                        "Es ist ein Fehler beim Registrieren aufgetreten"
                    );
                });
        });
    };

    if (dispatchLoginSuccess) {
        return <Redirect to={`/${mandantKey}/projekte/`} />;
    }

    const renderMenu = () => {
        const isConfirmed = confirmLocal && confirmRegister;

        return (
            <div className="gap-top">
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        {!isNewUser ? (
                            <>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    className="button--primary"
                                    startIcon={<IconVpnKey />}
                                >
                                    Jetzt Anmelden
                                </Button>
                            </>
                        ) : (
                            <>
                                {userData && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={updateUser}
                                        size="large"
                                        className="button--primary"
                                        startIcon={<IconEdit />}
                                    >
                                        Meine Änderungen speichern
                                    </Button>
                                )}
                                {!userData && (
                                    <>
                                        {!isConfirmed && (
                                            <Alert
                                                severity="info"
                                                className="gap-top gap-bottom"
                                            >
                                                Zum Registrieren bitte erst die
                                                Benutzungsbedingungen und die
                                                Regeln bestätigen, sowie alle
                                                Pflichtfelder ausfüllen.
                                            </Alert>
                                        )}
                                        {registerError && (
                                            <Alert
                                                severity="info"
                                                className="gap-top gap-bottom"
                                            >
                                                Beim anlegen ihres Profiles ist
                                                leider ein Fehler aufgetreten.
                                                Bitte versuchen Sie es nochmal.
                                            </Alert>
                                        )}

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            onClick={createNewUser}
                                            size="large"
                                            className="button--primary"
                                            startIcon={<IconVpnKey />}
                                        >
                                            Jetzt den Benutzer anlegen
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                {!userData && (
                    <>
                        {!isNewUser ? (
                            <>
                                <br />
                                <br />
                                <p
                                    className="login-form-link clickable"
                                    onClick={() => setIsNewUser(true)}
                                >
                                    Sie haben noch keinen Benutzernamen?
                                </p>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setIsNewUser(true);
                                    }}
                                    fullWidth
                                    className="button secondary"
                                    startIcon={<IconAdd />}
                                >
                                    Hier können Sie einen Benutzer anlegen.
                                </Button>
                                <br />
                                <br />
                                <p
                                    className="login-form-link clickable"
                                    onClick={onForgotClick}
                                >
                                    Haben Sie ihr Passwort vergessen?
                                </p>
                            </>
                        ) : (
                            <p
                                className="login-form-link"
                                onClick={() => setIsNewUser(false)}
                            >
                                Sie haben bereits einen Benutzernamen und
                                möchten sich anmelden?
                                <br />
                                Hier geht es zum Login.
                            </p>
                        )}
                    </>
                )}
            </div>
        );
    };
    return (
        <div className="portal-section">
            <Helmet>
                <title>Login in den Nutzerbereich</title>
            </Helmet>

            <Header mandant={mandant} history={history} />

            <ImageHeader image={mandant.headerImage} />

            <Container maxWidth="md">
                <Grid container>
                    <Card
                        variant="outlined"
                        className="public-login-form-container"
                    >
                        <CmsImage
                            image={mandant.logoImage}
                            className="login-logo gap-top"
                        />

                        <form onSubmit={handleOnLoginClick}>
                            {isNewUser && !registerSuccess && (
                                <CardContent className="public-register-form-content">
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            {!userData && (
                                                <>
                                                    <Typography variant="h2">
                                                        Registrierung
                                                    </Typography>
                                                    <HtmlContent
                                                        content={
                                                            mandant.textRegisterDescription
                                                                ? mandant.textRegisterDescription
                                                                : ""
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                label="Vorname"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="text"
                                                onChange={handleCreateChange}
                                                error={!!errorState.firstname}
                                                helperText={toInputString(
                                                    errorState.firstname
                                                )}
                                                value={toInputString(
                                                    createUser.firstname
                                                )}
                                                name="firstname"
                                            />

                                            {!mandant.flagSimpleLogin && (
                                                <TextField
                                                    required
                                                    id="outlined-basic"
                                                    label="Nachname"
                                                    variant="outlined"
                                                    className="public-input-text"
                                                    type="text"
                                                    onChange={
                                                        handleCreateChange
                                                    }
                                                    error={
                                                        !!errorState.lastname
                                                    }
                                                    helperText={toInputString(
                                                        errorState.lastname
                                                    )}
                                                    value={toInputString(
                                                        createUser.lastname
                                                    )}
                                                    name="lastname"
                                                />
                                            )}

                                            <TextField
                                                required
                                                id="outlined-basic"
                                                label="E-Mail Adresse"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="text"
                                                onChange={handleCreateChange}
                                                error={!!errorState.email}
                                                helperText={toInputString(
                                                    errorState.email
                                                )}
                                                value={toInputString(
                                                    createUser.email
                                                )}
                                                name="email"
                                            />
                                        </Grid>

                                        {mandant.flagSimpleLogin && (
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    id="outlined-basic"
                                                    label="Nachname"
                                                    variant="outlined"
                                                    className="public-input-text"
                                                    type="text"
                                                    onChange={
                                                        handleCreateChange
                                                    }
                                                    error={
                                                        !!errorState.lastname
                                                    }
                                                    helperText={toInputString(
                                                        errorState.lastname
                                                    )}
                                                    value={toInputString(
                                                        createUser.lastname
                                                    )}
                                                    name="lastname"
                                                />
                                            </Grid>
                                        )}

                                        {!mandant.flagSimpleLogin && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        label="Strasse"
                                                        variant="outlined"
                                                        className="public-input-text"
                                                        type="text"
                                                        onChange={
                                                            handleCreateChange
                                                        }
                                                        error={
                                                            !!errorState.street
                                                        }
                                                        helperText={toInputString(
                                                            errorState.street
                                                        )}
                                                        value={toInputString(
                                                            createUser.street
                                                        )}
                                                        name="street"
                                                    />
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        label="Hausnummer"
                                                        variant="outlined"
                                                        className="public-input-text"
                                                        type="text"
                                                        onChange={
                                                            handleCreateChange
                                                        }
                                                        error={
                                                            !!errorState.streetNo
                                                        }
                                                        helperText={toInputString(
                                                            errorState.streetNo
                                                        )}
                                                        value={toInputString(
                                                            createUser.streetNo
                                                        )}
                                                        name="streetNo"
                                                    />
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        label="Postleitzahl"
                                                        variant="outlined"
                                                        className="public-input-text"
                                                        type="text"
                                                        onChange={
                                                            handleCreateChange
                                                        }
                                                        error={!!errorState.zip}
                                                        helperText={toInputString(
                                                            errorState.zip
                                                        )}
                                                        value={toInputString(
                                                            createUser.zip
                                                        )}
                                                        name="zip"
                                                    />
                                                    <TextField
                                                        required
                                                        id="outlined-basic"
                                                        label="Stadt"
                                                        variant="outlined"
                                                        className="public-input-text"
                                                        type="text"
                                                        error={
                                                            !!errorState.city
                                                        }
                                                        helperText={toInputString(
                                                            errorState.city
                                                        )}
                                                        value={toInputString(
                                                            createUser.city
                                                        )}
                                                        onChange={
                                                            handleCreateChange
                                                        }
                                                        name="city"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="gap-bottom gap-top"
                                        >
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                label="Anzeigename"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="text"
                                                error={!!errorState.nickName}
                                                helperText={toInputString(
                                                    errorState.nickName
                                                )}
                                                value={toInputString(
                                                    createUser.nickName
                                                )}
                                                onChange={handleCreateChange}
                                                name="nickName"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <p className="no-marg">
                                                Dieser Name wird im Portal
                                                angezeigt, wenn Sie Kommentare
                                                schreiben oder sich in sonstiger
                                                Weise beteiligen.
                                            </p>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="gap-bottom gap-top"
                                        >
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required={!isNewUser}
                                                id="outlined-basic"
                                                label="Passwort"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="password"
                                                onChange={handleCreateChange}
                                                name="password"
                                                error={!!errorState.password}
                                                helperText={toInputString(
                                                    errorState.password
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required={!isNewUser}
                                                id="outlined-basic"
                                                label="Passwort zur Sicherheit wiederholen"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="password"
                                                onChange={handleCreateChange}
                                                name="passwordCheck"
                                                error={
                                                    !!errorState.passwordCheck
                                                }
                                                helperText={toInputString(
                                                    errorState.passwordCheck
                                                )}
                                            />
                                            <p className="no-marg">
                                                Das Passwort muss mindestens 8
                                                Zeichen lang sein. Es muss
                                                mindestens einen Groß- und einen
                                                Kleinbuchstaben sowie eine
                                                Ziffer enthalten.
                                            </p>
                                        </Grid>

                                        <Divider />
                                        {!userData && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <div className="gap-top">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        confirmLocal
                                                                    }
                                                                    onClick={
                                                                        handleConfirmLocal
                                                                    }
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                        <span>
                                                            {
                                                                mandant.textRegisterConfirm
                                                            }
                                                        </span>
                                                        {errorState?.confirmLocal && (
                                                            <FormHelperText
                                                                error={true}
                                                            >
                                                                {
                                                                    errorState?.confirmLocal
                                                                }
                                                            </FormHelperText>
                                                        )}
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} md={12}>
                                                    <div className="gap-bottom gap-top">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        confirmRegister
                                                                    }
                                                                    onClick={
                                                                        handleConfirmRegister
                                                                    }
                                                                />
                                                            }
                                                            label={""}
                                                        />

                                                        <span>
                                                            Ich stimme den{" "}
                                                            <a
                                                                onClick={
                                                                    onLinkClick
                                                                }
                                                                data-page="nutzungsbedingungen"
                                                            >
                                                                Nutzungsbedingungen
                                                            </a>{" "}
                                                            und{" "}
                                                            <a
                                                                onClick={
                                                                    onLinkClick
                                                                }
                                                                data-page="datenschutz"
                                                            >
                                                                Datenschutzbestimmungen
                                                            </a>{" "}
                                                            zu.
                                                        </span>

                                                        {errorState?.confirmRegister && (
                                                            <FormHelperText
                                                                error={true}
                                                            >
                                                                {
                                                                    errorState?.confirmRegister
                                                                }
                                                            </FormHelperText>
                                                        )}
                                                    </div>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>

                                    {renderMenu()}
                                </CardContent>
                            )}

                            {(!isNewUser || registerSuccess) && (
                                <CardContent className="public-login-form-content">
                                    {registerSuccess && (
                                        <Alert
                                            severity="info"
                                            className="gap-bottom"
                                        >
                                            Vielen Dank für Ihre Registrierung.
                                            Sie können Sich jetzt einloggen.
                                        </Alert>
                                    )}

                                    {errorMessage && (
                                        <Alert severity="error">
                                            Sie haben entweder einen falschen
                                            Benutzernamen oder ein falsches
                                            Passwort eingegeben.
                                        </Alert>
                                    )}

                                    <Grid item xs={12} md={12}>
                                        <div className="gap-top">
                                            <TextField
                                                required
                                                id="outlined-basic"
                                                label="E-Mail Adresse"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="text"
                                                onChange={handleChange}
                                                name="email"
                                            />

                                            <TextField
                                                required
                                                id="outlined-basic"
                                                label="Passwort"
                                                variant="outlined"
                                                className="public-input-text"
                                                type="password"
                                                onChange={handleChange}
                                                name="password"
                                            />
                                        </div>
                                    </Grid>

                                    {renderMenu()}
                                </CardContent>
                            )}
                        </form>
                    </Card>
                </Grid>
            </Container>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={snackBar.type}
                >
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};
/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    ILoginMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        loading: state.User.loading,
        emailIsValid: state.User.emailIsValid,
        emailErrorMessage: state.User.emailErrorMessage,
        passwordIsValid: state.User.passwordIsValid,
        passwordErrorMessage: state.User.passwordErrorMessage,
        userData: state.User.userData,
        errorMessage: state.User.errorMessage,
        dispatchLoginSuccess: state.User.loginSuccess,
    };
};
/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<
    ILoginDispatchProps,
    IOwnProps
> = (dispatch: ThunkDispatch<IApplicationRootState, {}, Action>) => {
    return {
        handleOnLoginEvent: (
            email: string,
            password: string,
            mandantKey: string
        ) => dispatch(LoginAction(email, password, mandantKey)),

        handleLoginInit: () => dispatch(loginError("")),

        dispatchUpdateUser: (userData: IUser) =>
            dispatch(updateUserdata(userData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
