import IMapProposal from "../../../types/IMapProposal";

export interface IFormValidationState {
    hasError: boolean;
    comment?: boolean;
}
export const isValidProposal = (
    proposal: IMapProposal
): IFormValidationState => {
    const validationState: IFormValidationState = {
        hasError: false,
    };

    if (proposal.comment.length < 1) {
        validationState.comment = true;
        validationState.hasError = true;
    }

    return validationState;
};
