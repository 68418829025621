import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import CmsImage from "../../../../components/cms/CmsImage";
import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderListCms from "../../../../components/folderList/FolderListCms";
import TeamTile from "../../../../components/team/TeamTile";
import CmsPageElementTypeEnum from "../../../../enums/CmsPageElementTypeEnum";
import ICmsPage from "../../../../types/ICmsPage";
import ICmsPageElement from "../../../../types/ICmsPageElement";
import IDocument from "../../../../types/IDocument";
import ITeam from "../../../../types/ITeam";
import ITeamRef from "../../../../types/ITeamRef";
import CmsPageFragmentHeader1 from "./sections/CmsPageFragmentHeader1";
import CmsPageFragmentHeader2 from "./sections/CmsPageFragmentHeader2";
import CmsPageFragmentHeader3 from "./sections/CmsPageFragmentHeader3";

interface ICmsPageRendererProps {
    pageData: ICmsPage;
    images: { [key: string]: IDocument };
    uploads: { [key: string]: IDocument };
    teams: { [key: string]: ITeam };
}

const CmsPageRenderer = (props: ICmsPageRendererProps) => {
    const { images, uploads, teams, pageData } = props;

    const renderHeader1 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader1
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderHeader2 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader2
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderHeader3 = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <CmsPageFragmentHeader3
                key={"" + key}
                pageFragment={pageFragment}
            />
        );
    };

    const renderSimpleText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        return (
            <Grid item key={key} xs={12}>
                <p>{pageFragment.content}</p>
            </Grid>
        );
    };

    const renderHtml = (pageFragment: ICmsPageElement, key: number): any => {
        return (
            <Grid item key={key} xs={12}>
                <HtmlContent content={pageFragment.content as string} />
            </Grid>
        );
    };

    const renderHtmlTextText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={6}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={6}>
                    <HtmlContent content={content2} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlTextTextText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;
        // @ts-ignore
        const content3 = pageFragment.content[2] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={4}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={4}>
                    <HtmlContent content={content2} />
                </Grid>
                <Grid item xs={4}>
                    <HtmlContent content={content3} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlTextImage = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as string;
        // @ts-ignore
        const content2 = pageFragment.content[1] as IDocumentRef;

        return (
            <React.Fragment key={key}>
                <Grid item xs={6}>
                    <HtmlContent content={content1} />
                </Grid>
                <Grid item xs={6}>
                    <CmsImage
                        image={images[content2.key]}
                        className="portal-cms-image"
                    />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlImageText = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const content1 = pageFragment.content[0] as IDocumentRef;
        // @ts-ignore
        const content2 = pageFragment.content[1] as string;

        return (
            <React.Fragment key={key}>
                <Grid item xs={6}>
                    <CmsImage
                        image={images[content1.key]}
                        className="portal-cms-image"
                    />
                </Grid>
                <Grid item xs={6}>
                    <HtmlContent content={content2} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderHtmlDownloadList = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const refs = pageFragment.content[0] as IDocumentRef[];

        return (
            <React.Fragment key={key}>
                <Grid item xs={12}>
                    <FolderListCms refs={refs} uploads={uploads} />
                </Grid>
            </React.Fragment>
        );
    };

    const renderTeamList = (
        pageFragment: ICmsPageElement,
        key: number
    ): any => {
        // @ts-ignore
        const refs = pageFragment.content as ITeamRef[];

        return (
            <React.Fragment key={key}>
                {refs.map((teamRef, teamIndex) => {
                    return (
                        <TeamTile
                            key={teamIndex}
                            participant={teams[teamRef.id]}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    const renderer: any = {};
    renderer[CmsPageElementTypeEnum.HEADER_1] = renderHeader1;
    renderer[CmsPageElementTypeEnum.HEADER_2] = renderHeader2;
    renderer[CmsPageElementTypeEnum.HEADER_3] = renderHeader3;
    renderer[CmsPageElementTypeEnum.SIMPLE_TEXT] = renderSimpleText;
    renderer[CmsPageElementTypeEnum.HTML] = renderHtml;

    renderer[CmsPageElementTypeEnum.HTML_TEXT_TEXT] = renderHtmlTextText;
    renderer[CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT] =
        renderHtmlTextTextText;
    renderer[CmsPageElementTypeEnum.HTML_TEXT_IMAGE] = renderHtmlTextImage;
    renderer[CmsPageElementTypeEnum.HTML_IMAGE_TEXT] = renderHtmlImageText;
    renderer[CmsPageElementTypeEnum.DOWNLOAD_LIST] = renderHtmlDownloadList;
    renderer[CmsPageElementTypeEnum.TEAM_LIST] = renderTeamList;

    return (
        <div className="App-cms-container">
            <Helmet>
                <title>{pageData.data.title}</title>
            </Helmet>
            <Grid container spacing={2}>
                {pageData.elements.map(
                    (pageElement: ICmsPageElement, index: number) => {
                        if (renderer[pageElement.type]) {
                            return renderer[pageElement.type](
                                pageElement,
                                index
                            );
                        } else {
                            console.log("missing type " + pageElement.type);
                            return <React.Fragment key={index} />;
                        }
                    }
                )}
            </Grid>
        </div>
    );
};

export default CmsPageRenderer;
