import React from "react";

interface IHtmlContentProps {
    content: string;
    className?: string;
}

const HtmlContent = (props: IHtmlContentProps) => {
    if (!props.content) {
        return <></>;
    }

    return (
        <div
            className={props.className}
            dangerouslySetInnerHTML={{ __html: props.content }}
        />
    );
};

export default HtmlContent;
