import Typography from "@material-ui/core/Typography";
import React from "react";

import AccordionUi from "../../../../components/accordion/Accordion";
import TabContainer from "../../../../components/tabContainer/TabContainer";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";
import CmsPageLoader from "../../cmsPage/components/CmsPageLoader";

interface IProjectDataContentProps {
    mandant: IMandant;
    pageKey?: string;
    isMobileSize: boolean;
    hideTabHeader: boolean;
    projectData: IProjectFull;
    getTabs: () => any;
    activeTab: string;
    handleTabChange: (event: React.ChangeEvent<{}>, tabIdx: string) => void;
}

const ProjectDataContent = (props: IProjectDataContentProps) => {
    const {
        pageKey,
        mandant,
        projectData,
        hideTabHeader,
        isMobileSize,
        getTabs,
        activeTab,
        handleTabChange,
    } = props;

    if (pageKey) {
        return (
            <CmsPageLoader
                mandantKey={mandant.key}
                projectKey={projectData.urlKey}
                pageKey={pageKey}
            />
        );
    }

    if (!isMobileSize || hideTabHeader) {
        return (
            <>
                {projectData.flagShowTitle && (
                    <Typography
                        className="App-data-header"
                        gutterBottom
                        variant="h2"
                    >
                        {projectData.title}
                    </Typography>
                )}
                <TabContainer
                    tabs={getTabs()}
                    activeTab={activeTab}
                    hideTabHeader={hideTabHeader}
                    ariaLabel={"Public Project Data"}
                    idKey={"Project Data"}
                    handleChange={handleTabChange}
                    mandant={mandant}
                />
            </>
        );
    } else {
        return (
            <>
                {projectData.flagShowTitle && (
                    <Typography
                        className="App-data-header"
                        gutterBottom
                        variant="h2"
                    >
                        {projectData.title}
                    </Typography>
                )}
                <AccordionUi tabs={getTabs()} />
            </>
        );
    }
};

export default ProjectDataContent;
