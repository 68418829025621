export const isAuthenticated = (mandantKey: string): boolean => {
    if (localStorage.getItem("jwt-" + mandantKey)) {
        return true;
    } else {
        return false;
    }
};
export const isStillAuthenticated = (mandantKey: string): boolean => {
    const tokenTime = new Date(
        localStorage.getItem("expiresAt-" + mandantKey) || ""
    );
    const tokenTimeUtc = new Date(
        Date.UTC(
            tokenTime.getFullYear(),
            tokenTime.getMonth(),
            tokenTime.getDate(),
            tokenTime.getHours(),
            tokenTime.getMinutes(),
            tokenTime.getSeconds()
        )
    );
    const now = new Date();

    if (tokenTimeUtc.getTime() > now.getTime()) {
        return true;
    } else {
        return false;
    }
};
