import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import HtmlContent from "../../../components/cms/HtmlContent";
import ContactForm from "../../../components/contact/ContactForm";
import ImageHeader from "../../../components/layout/ImageHeader";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import IApplicationRootState from "../../../store/StoreTypes";
import IMandant from "../../../types/IMandant";

interface IMapStateProps {
    mandant: IMandant;
}

interface IOwnProps {}

interface IContactParams extends IMapStateProps, IOwnProps {}

const Contact = (props: IContactParams) => {
    const history = useHistory();

    const { mandant } = props;

    return (
        <div className="portal-section">
            <Helmet>
                <title>Kontaktanfrage</title>
            </Helmet>
            <Header mandant={mandant} history={history} />

            <ImageHeader
                image={mandant.headerImage}
                isBig={mandant.key === "kernen"}
            />
            <div className="portal-section-body-position">
                <div className="App-main">
                    <Paper className="main-paper" variant="outlined" square>
                        <Typography gutterBottom variant="h2">
                            Kontaktanfrage zum Projektteam
                        </Typography>
                        <HtmlContent
                            className="App-cms-container-textblock"
                            content={"" + mandant.contactFormTextBefore}
                        />

                        <ContactForm />

                        <HtmlContent
                            className="App-cms-container-textblock"
                            content={"" + mandant.contactFormTextAfter}
                        />
                    </Paper>
                </div>
            </div>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(Contact);
