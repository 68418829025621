import {
    Checkbox,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";

import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IMandant from "../../../../types/IMandant";

export interface IMandantDataTabProps {
    mandantData: IMandant;
    onChangeWysiwyg: (event: any, name: string) => void;
    onChange: (event: any) => void;
    onCheckboxChange: (event: any) => void;
}

const MandantTextTab = (props: IMandantDataTabProps) => {
    const { mandantData, onChangeWysiwyg, onCheckboxChange } = props;

    const flagShowFooterRight =
        !!mandantData.flagDisabelFooterContact ||
        !!mandantData.flagDisableContact;

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header-with-gap">
                        Kontakt Formular Texte
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <TextEdit
                        handleChange={(event: any) => {
                            onChangeWysiwyg(event, "contactFormTextBefore");
                        }}
                        title="Text davor"
                        defaultValue={mandantData.contactFormTextBefore || " "}
                    />

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!mandantData.flagDisableContact}
                                    onChange={onCheckboxChange}
                                    name="flagDisableContact"
                                />
                            }
                            label="Kontaktformular deaktivieren"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <TextEdit
                        handleChange={(event: any) => {
                            onChangeWysiwyg(event, "contactFormTextAfter");
                        }}
                        title="Text Danach"
                        defaultValue={mandantData.contactFormTextAfter || " "}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header-with-gap">
                        Footer Texte Links
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="footerLeftTitel"
                        label="Footer Titel Links"
                        fullWidth
                        value={props.mandantData.footerLeftTitel}
                        onChange={props.onChange}
                        variant="outlined"
                    />

                    <TextEdit
                        handleChange={(event: any) => {
                            onChangeWysiwyg(event, "footerLeftText");
                        }}
                        title="Footer Text Links"
                        defaultValue={mandantData.footerLeftText || " "}
                    />

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        !!mandantData.flagDisabelFooterContact
                                    }
                                    onChange={onCheckboxChange}
                                    name="flagDisabelFooterContact"
                                />
                            }
                            label="Kontaktformular im Footer deaktivieren?"
                        />
                    </FormGroup>
                </Grid>

                {flagShowFooterRight && (
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            name="footerRightTitel"
                            label="Footer Titel Rechts"
                            fullWidth
                            value={props.mandantData.footerRightTitel}
                            onChange={props.onChange}
                            variant="outlined"
                        />

                        <TextEdit
                            handleChange={(event: any) => {
                                onChangeWysiwyg(event, "footerRightText");
                            }}
                            title="Footer Text Rechts"
                            defaultValue={mandantData.footerRightText || " "}
                        />
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default MandantTextTab;
