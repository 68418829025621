import React, { useCallback, useState } from "react";
// @ts-ignore
import Carousel, { Image, Modal, ModalGateway } from "react-images";
import Gallery, { PhotoProps } from "react-photo-gallery";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";

interface IImmageGalleryParams {
    images: any[];
}

const ImmageGallery = (props: IImmageGalleryParams) => {
    const [currentImage, setCurrentImage] = useState<number>(0);
    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <>
            <Gallery
                photos={props.images.map(
                    (image: IDocument): PhotoProps => ({
                        src: API_URL_STATIC + image.url,
                        width: image.width,
                        height: image.height,
                    })
                )}
                onClick={openLightbox}
            />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={props.images.map(
                                (image: IDocument): Image => ({
                                    src: API_URL_STATIC + image.url,
                                    caption: image.description,
                                    alt: image.description,
                                })
                            )}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

export default ImmageGallery;
