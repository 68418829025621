import "ol/ol.css";
import "./olmap.css";
import "ol-ext/dist/ol-ext.css";
import "ol-ext/style/FontAwesomeDef.js";

import { Feature, Map, View } from "ol";
import SearchNominatim from "ol-ext/control/SearchNominatim";
import { FullScreen, defaults as defaultControls } from "ol/control";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { transform } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import React, { useEffect } from "react";

interface IOlMapProps {
    location: [number, number];
    zoom: number;
    onLocationSelected?: (lng: number, lat: number, zoom: number) => void;
    persistZoomChange?: (zoom: number) => void;
}
/* function to trabsform coordinates type */
const transfCoord = (coordinates: any) => {
    const transformed = transform(coordinates, "EPSG:3857", "EPSG:4326");
    return { lat: transformed[1], lng: transformed[0] };
};

/* circle feature style */
const myStyle = new Style({
    image: new Circle({
        radius: 7,
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({
            color: [255, 0, 0],
            width: 4,
        }),
    }),
});

/* the layer that contain the Marker */
const markerLayer = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    style: myStyle,
});
/* the search control instance */
const search = new SearchNominatim();
/* defining the map and assigning to it the layers and controls */
const olMap = new Map({
    target: undefined,
    layers: [
        new TileLayer({
            source: new OSM({
                maxZoom: 18,
            }),
        }),
        markerLayer,
    ],
    controls: defaultControls().extend([new FullScreen(), search]),

    view: new View({
        center: [12, 50],
        zoom: 2,
        // projection: "EPSG:4326",
    }),
});
/*  function to put a Point marker on the map */
const putMarker = (coordinate: any, zoom = 17) => {
    const source = markerLayer.getSource();
    source.clear();
    const myfeature = new Feature({
        geometry: new Point(coordinate),
    });
    source.addFeature(myfeature);
    olMap.getView().animate({ zoom }, { center: coordinate });
};
/* the OLMap Component */
export default function OlMap(props: IOlMapProps) {
    const { onLocationSelected, location, zoom, persistZoomChange } = props;

    React.useEffect(() => {
        if (location.length) {
            const transformed = transform(
                props.location,
                "EPSG:4326",
                "EPSG:3857"
            );
            putMarker(transformed, zoom);
        }
    }, [location]);

    /* handle map click  */
    const handleMapClick = (e: any) => {
        const currZoom = olMap.getView().getZoom() || 0;
        if (onLocationSelected) {
            putMarker(e.coordinate, currZoom);
            const transformed = transfCoord(e.coordinate);

            onLocationSelected(transformed.lat, transformed.lng, currZoom);
        }
    };
    /* handle on map search select item from suggestions */
    const handleSearchSelect = (e: any) => {
        //olMap.getView().animate({ zoom: 17 }, { center: e.coordinate });
        putMarker(e.coordinate);
        const transformed = transfCoord(e.coordinate);
        const currZoom = olMap.getView().getZoom() || 0;
        if (onLocationSelected) {
            onLocationSelected(transformed.lat, transformed.lng, currZoom);
        }
    };
    const handleZoomEnd = () => {
        const currZoom = olMap.getView().getZoom() || 0;
        if (persistZoomChange) {
            persistZoomChange(currZoom);
        }
    };
    useEffect(() => {
        olMap.setTarget("map");
        olMap.on("moveend", handleZoomEnd);

        olMap.on("click", handleMapClick);
        search.on("select", handleSearchSelect);
        return () => olMap.setTarget(undefined);
    }, []);

    return <div className="map-container " id="map" />;
}
