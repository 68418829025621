import { Grid, Paper } from "@material-ui/core";
import React from "react";

import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IProjectFull from "../../../../types/IProjectFull";

type IProjectTextTabProps = {
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
};

const ProjectTextTab = (props: IProjectTextTabProps) => {
    const { projectData, onChangeWysiwyg } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "description");
                        }}
                        title="Projekt Beschreibung"
                        defaultValue={
                            props.projectData.description
                                ? projectData.description
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextEdit
                        handleChange={(editorState) => {
                            onChangeWysiwyg(editorState, "motivation");
                        }}
                        title="Motivation"
                        defaultValue={
                            projectData.motivation ? projectData.motivation : ""
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProjectTextTab;
