import "./AdminRoutesContainer.css";
import "./../../../assets/adminTheme.css";

import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import * as router from "react-router-dom";
import { Redirect } from "react-router-dom";

import favIcon from "../../../assets/favicons/favicon.gif";
import AdminHeader from "../../../layout/adminHeader/AdminHeader";
import AdminSideMenu from "../../../layout/adminSideMenu/AdminSideMenu";
import PrivateRoute from "../../../routes/privateRoute/PrivateRoute";
import PrivateRouteWithProps from "../../../routes/privateRouteWithProps/PrivateRouteWithProps";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import InitialLoginUserData from "../../../types/initialData/InitialLoginUserData";
import CampaignForm from "../campaignForm/CampaignForm";
import CampaignListing from "../campaignListing/CampaignListing";
import ChangePassword from "../changePassword/ChangePassword";
import CmsPageForm from "../cmsPageForm/CmsPageForm";
import CmsPageListing from "../cmsPageListing/CmsPageListing";
import CommentListing from "../commentListing/CommentListing";
import EventForm from "../eventForm/EventForm";
import FeedbackListing from "../feedbackListing/FeedbackListing";
import MandantForm from "../mandantForm/MandantForm";
import MandantListing from "../mandantManager/MandantListing";
import MapProposalViewer from "../mapProposalViewer/MapProposalViewer";
import PollCampaignForm from "../pollCampaignForm/PollCampaignForm";
import PollCampaignListing from "../pollCampaignListing/PollCampaignListing";
import ProjectForm from "../projectForm/ProjectForm";
import ProjectListing from "../projectListing/ProjectListing";
import UserForm from "../userForm/UserForm";
import UserListing from "../userListing/UserListing";

interface IStateProps {
    mandant: IMandant;
    activeUser: IUser;
}

interface IOwnProps {
    mandantKey: string;
}

type IAdminDrawerContainerProps = IOwnProps & IStateProps;
const urlPath = window.location.pathname.split("/", 5);
const mandantKey = urlPath[1];

const AdminRoutesContainer = (props: IAdminDrawerContainerProps) => {
    const { mandant, activeUser } = props;
    const { url } = router.useRouteMatch();
    const history = router.useHistory();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleSelectedHomeTab = (index: number) => {
        setSelectedIndex(index);
    };
    const handleLogout = () => {
        localStorage.clear();
        history.push(url);
    };

    const hasPortal = activeUser.roles.find((role: string) => {
        return role === "ROLE_PORTAL";
    });

    if (hasPortal) {
        return <Redirect to={`/${mandantKey}/projekt/`} />;
    }

    const pageTitle =
        mandantKey === "stegadmin"
            ? "STEG Kundenverwaltung"
            : "STEG Bürgerbeteiligungsportal";

    return (
        <div className="root admin-container">
            <Helmet>
                <title>{pageTitle}</title>
                <link
                    href={favIcon}
                    rel="icon"
                    sizes="16x16"
                    type="image/png"
                />
            </Helmet>

            <router.BrowserRouter>
                <AdminHeader />
                <AdminSideMenu
                    handleLogout={handleLogout}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    url={url}
                />

                <main className="content">
                    <Toolbar />
                    <router.Switch>
                        <PrivateRouteWithProps
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_PROJECT_LIST
                            }
                            component={ProjectListing}
                            handleBackhome={handleSelectedHomeTab}
                            exact
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" +
                                RoutesEnum.ADMIN_PROJECT +
                                ":id"
                            }
                            component={ProjectForm}
                            exact
                        />

                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_COMMMENTS}
                            component={CommentListing}
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" +
                                RoutesEnum.ADMIN_CMS_PAGE_LIST +
                                ":pageId"
                            }
                            component={CmsPageForm}
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_CMS_PAGE_LIST
                            }
                            component={CmsPageListing}
                        />

                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={"/:mandantKey" + RoutesEnum.FEEDBACK_LISTING}
                            component={FeedbackListing}
                        />

                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={
                                "/:mandantKey" +
                                RoutesEnum.ADMIN_PROJECT +
                                ":projectId" +
                                RoutesEnum.ADMIN_SUB_EVENT +
                                ":eventId"
                            }
                            component={EventForm}
                            exact
                        />
                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={"/:mandantKey" + RoutesEnum.CHANGE_PASSWORD}
                            component={ChangePassword}
                        />
                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_USER + ":id"
                            }
                            component={UserForm}
                        />
                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_USER_LIST}
                            component={UserListing}
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" +
                                RoutesEnum.ADMIN_POLL_CAMPAIGN +
                                ":id"
                            }
                            component={PollCampaignForm}
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_POLL_CAMPAIGN
                            }
                            component={PollCampaignListing}
                        />

                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" +
                                RoutesEnum.ADMIN_APPOINTMENT +
                                ":id"
                            }
                            component={CampaignForm}
                        />
                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_APPOINTMENT}
                            component={CampaignListing}
                        />
                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_MAP_PROPOSAL
                            }
                            component={MapProposalViewer}
                        />
                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={
                                "/:mandantKey" + RoutesEnum.ADMIN_PORTAL + ":id"
                            }
                            component={MandantForm}
                        />

                        <PrivateRoute
                            mandantKey={mandant.key}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_PORTAL}
                            component={MandantForm}
                        />
                        <PrivateRoute
                            mandantKey={mandantKey}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_PORTAL_LIST}
                            component={MandantListing}
                        />
                        <PrivateRouteWithProps
                            mandantKey={mandantKey}
                            path={"/:mandantKey" + RoutesEnum.ADMIN_HOME}
                            component={
                                mandantKey === "stegadmin"
                                    ? MandantListing
                                    : ProjectListing
                            }
                            handleBackhome={handleSelectedHomeTab}
                        />
                    </router.Switch>
                </main>
            </router.BrowserRouter>
        </div>
    );
};

const mapStateToProps: MapStateToPropsParam<
    IStateProps,
    {},
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.User.userData
            ? state.User.userData
            : { ...InitialLoginUserData },
    };
};
export default connect(mapStateToProps)(AdminRoutesContainer);
