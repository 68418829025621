enum CmsPageElementTypeEnum {
    HEADER_1 = "header_1",
    HEADER_2 = "header_2",
    HEADER_3 = "header_3",
    SIMPLE_TEXT = "simple_text",
    HTML = "html",
    HTML_TEXT_TEXT = "html_text_text",
    HTML_TEXT_TEXT_TEXT = "html_text_text_text",
    HTML_TEXT_IMAGE = "html_text_image",
    HTML_IMAGE_TEXT = "html_image_text",
    DOWNLOAD_LIST = "download_list",
    TEAM_LIST = "team_list",
}

export default CmsPageElementTypeEnum;
