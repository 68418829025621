import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

import { Format } from "../../../../config/config";
import ICampaignAppointmentList from "../../../../types/appointment/ICampaignAppointmentList";

export default function exportTableToExcel(
    tableRows: ICampaignAppointmentList[]
): Promise<void> {
    const workbook = new Excel.Workbook();
    const sheetName = `Termine`;
    const sheet = workbook.addWorksheet(sheetName);
    const headerRow = [
        "Raumname",
        "Raummanager",
        "Startdatum",
        "Dauer",
        "Buchungszeit",
        "Email",
        "Buchungsschlüssel",
        "Per Brief schicken",

        "Anrede",
        "Vorname",
        "Nachname",
        "Strasse",
        "Hausnummer",
        "Postleitzahl",
        "Stadt",

        "Name der Vertreters",
        "Zusätzliche Personen",
        "Kommentar des Benutzers",
        "Ist Gruppe",
    ];

    workbook.created = new Date();
    workbook.views = [
        {
            activeTab: 1,
            firstSheet: 0,
            height: 20000,
            visibility: "visible",
            width: 30000,
            x: 0,
            y: 0,
        },
    ];

    sheet.addRow([]);
    const titleRow = sheet.addRow([sheetName]);
    sheet.addRow([]);
    const theHeadRow = sheet.addRow(headerRow);

    titleRow.font = {
        name: "Calibri",
        family: 4,
        size: 18,
        bold: true,
        color: { argb: "FF00677f" },
    };
    titleRow.height = 30;
    theHeadRow.font = { name: "Calibri", family: 4, size: 14, bold: true };

    for (let i = 0; i < tableRows.length; i++) {
        const col = sheet.getColumn(i + 1);
        const rowWidth = 25;
        col.width = rowWidth;
    }

    if (tableRows?.length > 0) {
        tableRows.forEach((tableRow) => {
            // only export booked ones
            if (!tableRow.bookingTime) {
                return;
            }

            const rowData = [
                tableRow?.campaignRoom?.name,
                tableRow?.campaignRoom?.assignedName,
                moment(tableRow.startDate).format(Format.dateTime),
                tableRow.duration,
                tableRow.bookingTime,

                tableRow.bookingParticipant?.email,
                tableRow.bookingParticipant?.accessToken,
                tableRow.bookingParticipant?.flagSendPostalMail
                    ? "Bitte Per Post"
                    : "E-Mail reicht",

                tableRow.bookingParticipant?.user?.salutation,
                tableRow.bookingParticipant?.user?.firstname,
                tableRow.bookingParticipant?.user?.lastname,
                tableRow.bookingParticipant?.user?.street,
                tableRow.bookingParticipant?.user?.streetNo,
                tableRow.bookingParticipant?.user?.zip,
                tableRow.bookingParticipant?.user?.city,

                tableRow.attorneyName,
                tableRow.additionalPerson,
                tableRow.userComment,
                tableRow.isGroup ? "Gruppe" : "Einzeln",
            ];

            sheet.addRow(rowData);
        });
    } else {
        sheet.addRow(["keine Termine für den Export verfügbar."]);
    }

    return workbook.xlsx.writeBuffer().then((buf) => {
        return saveAs(new Blob([buf]), sheetName + ".xlsx");
    });
}
