import "ol/ol.css";
import "./olmap.css";
import "ol-ext/dist/ol-ext.css";
import "ol-ext/style/FontAwesomeDef.js";

import { Feature, Map, View } from "ol";
import { FullScreen, defaults as defaultControls } from "ol/control";
import Point from "ol/geom/Point";
import { defaults as defaultInteraction } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { transform } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import React, { useEffect } from "react";

interface IOlMapProps {
    data: any;
}

/* circle feature style */
const myStyle = new Style({
    image: new Circle({
        radius: 7,
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({
            color: [255, 0, 0],
            width: 4,
        }),
    }),
});

/* the layer that contain the Marker */
const markerLayer = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    style: myStyle,
});
const myIneration = defaultInteraction({
    doubleClickZoom: false,
    dragPan: false,
    mouseWheelZoom: false,
    shiftDragZoom: false,
    altShiftDragRotate: false,
});
/* defining the map and assigning to it the layers and controls */
const olMap = new Map({
    interactions: myIneration,
    target: undefined,
    layers: [
        new TileLayer({
            source: new OSM({
                maxZoom: 18,
            }),
        }),
        markerLayer,
    ],
    //controls: defaultControls({ attribution: false }),
    controls: defaultControls({ attribution: false }).extend([
        new FullScreen(),
    ]),

    view: new View({
        center: [12, 50],
        zoom: 2,
        // projection: "EPSG:4326",
    }),
});
/*  function to put a Point marker on the map */
const putMarker = (coordinate: any, data: any, zoom: number) => {
    const source = markerLayer.getSource();
    source.clear();
    const myfeature = new Feature({
        geometry: new Point(coordinate),
        labelPoint: new Point(coordinate),
        name: data.title,
    });
    source.addFeature(myfeature);
    olMap.getView().animate({ zoom: zoom }, { center: coordinate });
};

/* the OLMap Component */
export default function MapPicker(props: IOlMapProps) {
    const { data } = props;
    React.useEffect(() => {
        if (data) {
            const transformed = transform(
                [data.longitude, data.latitude],
                "EPSG:4326",
                "EPSG:3857"
            );
            putMarker(transformed, data, data.zoom);
        }
    }, [data]);

    useEffect(() => {
        olMap.setTarget("picker-map");
        return () => olMap.setTarget(undefined);
    }, []);

    return <div className="map-container-picker " id="picker-map"></div>;
}
