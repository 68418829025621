import { LinearProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ImageHeader from "../../../components/layout/ImageHeader";
import ProjectTile from "../../../components/project/projectTile/ProjectTile";
import { ISimpleEntryParams } from "../../../components/sidebar/Element/SimpeEntry";
import { Format } from "../../../config/config";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import Header from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IDeadlineList from "../../../types/IDeadlineList";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import IProjectList from "../../../types/IProjectList";
import { loadPublicDeadlineList } from "../../../xhr/DeadlineRequests";
import { loadPublicEventList } from "../../../xhr/EventRequests";
import { loadPublicProjectListRequest } from "../../../xhr/ProjectRequests";
import ProjectSidebar from "./ProjectSidebar";

interface IMapStateProps {
    mandant: IMandant;
    userData: any;
}

interface IOwnProps {}

interface IProjectListParams extends IMapStateProps, IOwnProps {}

const ProjectList = (props: IProjectListParams) => {
    const history = useHistory();
    const { mandant } = props;
    const { mandantKey }: { mandantKey: string } = useParams();

    const [projectList, setProjectList] = React.useState<IProjectList[]>([]);
    const [projectReady, setProjectsReady] = React.useState<boolean>(false);
    const [deadlines, setDeadlines] = React.useState<IDeadlineList[]>([]);
    const [events, setEvents] = React.useState<IEventList[]>([]);
    React.useEffect(() => {
        loadPublicProjectListRequest(mandantKey)
            .then((result: any) => {
                setProjectList(result.data);
                setProjectsReady(true);
                return Promise.resolve();
            })
            .catch(() => {
                setProjectsReady(true);
            });
        loadPublicDeadlineList(mandantKey).then((result: any) => {
            setDeadlines(result.data);
            return Promise.resolve();
        });
        loadPublicEventList(mandantKey).then((result: any) => {
            setEvents(result.data);
            return Promise.resolve();
        });
    }, []);

    if (!mandant.id) {
        return <></>;
    }

    const linkToProject = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        history.push("/" + mandantKey + "/" + event.currentTarget.dataset.id);
    };

    const onClickEventSidebar = (
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
        history.push(
            "/" +
                mandantKey +
                RoutesEnum.PORTAL_EVENT +
                "/" +
                event.currentTarget.dataset.id
        );
    };
    // if we have only one project we direct jump into the project
    if (projectList.length === 1) {
        history.push("/" + mandantKey + "/" + projectList[0].urlKey);
    }

    return (
        <div className="portal-section">
            <Helmet>
                <title>Unsere aktuellen Projekte für Sie</title>
            </Helmet>

            <Header mandant={mandant} history={history} />

            <ImageHeader
                image={mandant.headerImage}
                isBig={mandantKey === "kernen"}
            />

            <div className="portal-section-body-position">
                <div className="App-main-list">
                    {projectReady ? (
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} sm={12} md={8} lg={9}>
                                <div className="App-list-container-body">
                                    <h2 className="App-list-container-header">
                                        Unsere aktuellen Projekte für Sie
                                    </h2>

                                    {projectList.length === 0 && (
                                        <p>
                                            Im Moment gibt es keine aktuellen
                                            Projekte
                                        </p>
                                    )}

                                    <Grid container spacing={2} direction="row">
                                        {projectList.length > 0 &&
                                            projectList
                                                .sort((a: any, b: any) => {
                                                    const firstdate = new Date(
                                                        a.theStart
                                                    );
                                                    const seconddate = new Date(
                                                        b.theStart
                                                    );
                                                    return (
                                                        firstdate.getTime() -
                                                        seconddate.getTime()
                                                    );
                                                })
                                                .map(
                                                    (
                                                        project: IProjectList,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <ProjectTile
                                                                key={index}
                                                                onLinkClick={
                                                                    linkToProject
                                                                }
                                                                fallbackImage={
                                                                    mandant.defaultMenuImage
                                                                }
                                                                {...project}
                                                            />
                                                        );
                                                    }
                                                )}
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <ProjectSidebar
                                    deadlines={deadlines
                                        .filter((check: IDeadlineList) => {
                                            const deadlineDate = new Date(
                                                new Date(
                                                    check.deadlineDate
                                                ).getTime() +
                                                    60 * 60 * 24 * 1000
                                            ).getTime();

                                            const today = new Date().getTime();

                                            return deadlineDate > today;
                                        })
                                        .sort(
                                            (
                                                a: IDeadlineList,
                                                b: IDeadlineList
                                            ) => {
                                                const firstdate = new Date(
                                                    a.deadlineDate
                                                );
                                                const seconddate = new Date(
                                                    b.deadlineDate
                                                );

                                                if (
                                                    firstdate.getTime() <
                                                    seconddate.getTime()
                                                ) {
                                                    return -1;
                                                }

                                                if (
                                                    firstdate.getTime() >
                                                    seconddate.getTime()
                                                ) {
                                                    return 1;
                                                }

                                                return 0;
                                            }
                                        )
                                        .slice(0, 3)
                                        .map(
                                            (
                                                deadline: IDeadlineList
                                            ): ISimpleEntryParams => {
                                                return {
                                                    id: deadline.id,
                                                    subTitle: moment(
                                                        deadline.deadlineDate
                                                    ).format(Format.dateTime),
                                                    title: deadline.title,
                                                    description:
                                                        deadline.content,
                                                };
                                            }
                                        )}
                                    events={events
                                        .filter((check: IEventList) => {
                                            const startDate = new Date(
                                                new Date(
                                                    "" + check.theEnd
                                                ).getTime() +
                                                    60 * 60 * 24 * 1000
                                            ).getTime();

                                            const today = new Date().getTime();

                                            return startDate > today;
                                        })
                                        .sort((a: any, b: any) => {
                                            const firstdate = new Date(
                                                a.theStart
                                            );
                                            const seconddate = new Date(
                                                b.theStart
                                            );
                                            if (
                                                firstdate.getTime() >
                                                seconddate.getTime()
                                            ) {
                                                return 1;
                                            }

                                            if (
                                                firstdate.getTime() <
                                                seconddate.getTime()
                                            ) {
                                                return -1;
                                            }

                                            return 0;
                                        })
                                        .slice(Math.max(events.length - 3, 0))
                                        .map(
                                            (
                                                event: IEventList
                                            ): ISimpleEntryParams => {
                                                return {
                                                    id: event.id,
                                                    title: event.title,
                                                    subTitle:
                                                        moment(
                                                            event.theStart
                                                        ).format(
                                                            Format.dateTime
                                                        ) +
                                                        " - " +
                                                        moment(
                                                            event.theEnd
                                                        ).format("HH:mm") +
                                                        " " +
                                                        event.locationName,

                                                    description:
                                                        event.shortDesc,
                                                    onLinkClick:
                                                        onClickEventSidebar,
                                                };
                                            }
                                        )}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <div
                            className="App-main"
                            style={{ minHeight: "300px" }}
                        >
                            <LinearProgress color="secondary" />
                        </div>
                    )}
                </div>
            </div>
            <PortalFooter history={history} mandant={mandant} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<
    IMapStateProps,
    IOwnProps,
    IApplicationRootState
> = (state: IApplicationRootState) => {
    return {
        mandant: state.mandant.mandant,
        userData: state.User.userData,
    };
};

export default connect(mapStateToProps)(ProjectList);
