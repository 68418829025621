import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isStillAuthenticated } from "../../utils";

type IPublicRouteArgs = {
    component: any;
    path: string;
    mandantKey: string;
};
const PublicRoute = ({
    mandantKey,
    component: Component,
    ...rest
}: IPublicRouteArgs) => {
    const redirectRoute = "/" + mandantKey + "/datenverwaltung/login";
    return (
        <Route
            {...rest}
            render={(props: any) =>
                isStillAuthenticated(mandantKey) ? (
                    <Redirect to={redirectRoute} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PublicRoute;
