/*
import data from "../assets/allData";
import defaultTheme from "../assets/defaultTheme";
import LoadMandantTheme from "../store/mandantStore/LoadMandantTheme";
import setCssVariable from "./setCssVariable";
 */

function setMandantTheme(): string {
    const urlPath = window.location.pathname.split("/", 2);
    const mandantKey = urlPath[1];

    /*
  if (!mandantKey) {
    for (const [key, value] of Object.entries(defaultTheme)) {
      setCssVariable("--" + key, value);
    }
    return "";
  }

  LoadMandantTheme(data[mandantKey].styles);

  for (const [key, value] of Object.entries(data[mandantKey].styles)) {
    setCssVariable("--" + key, value);
  }

   */

    return mandantKey;
}

export default setMandantTheme;
