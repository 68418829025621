import { Typography } from "@material-ui/core";
import React from "react";

import HtmlContent from "../../../../components/cms/HtmlContent";
import FolderList from "../../../../components/folderList/FolderList";
import ImmageGallery from "../../../../components/imageGallery/ImageGallery";
import { API_URL_STATIC } from "../../../../config/config";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/IProjectFull";

interface IProjectDataTabProps {
    project: IProjectFull;
    mandant: IMandant;
}

function ProjectAboutTab(props: IProjectDataTabProps) {
    const { project, mandant } = props;

    return (
        <>
            {mandant.key !== "silbernes-erzgebirge" && (
                <Typography gutterBottom variant="h2" className="gap-top">
                    Über das Projekt
                </Typography>
            )}

            <HtmlContent
                className="content-block"
                content={project.description}
            />

            {project.imageMap?.url && (
                <>
                    {mandant.key === "silbernes-erzgebirge"}
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Projekt Übersichtsplan
                    </Typography>
                    <img
                        src={API_URL_STATIC + project.imageMap.url}
                        alt={project.imageMap.description}
                        style={{ maxWidth: "100%" }}
                    />
                    <p>
                        {project.imageMap?.description.length
                            ? project.imageMap.description
                            : ""}
                    </p>
                </>
            )}

            {Boolean(project?.attachments?.length) && (
                <FolderList files={project.attachments} fromEventTab={false} />
            )}

            {Boolean(project?.images.length) && (
                <>
                    <Typography gutterBottom variant="h2" className="gap-top">
                        Bilder zum Projekt
                    </Typography>
                    <ImmageGallery images={project.images} />
                </>
            )}
        </>
    );
}

export default ProjectAboutTab;
