import {
    ADMIN_CHANGE_ADMIN_PASSWORD,
    ADMIN_MANDANT,
    LOAD_MANDANT,
} from "../enums/Endpoints";
import IMandantList from "../types/IMandantList";
import {
    deleteData,
    getData,
    postData,
    putData,
} from "../utils/httpRequest/request";
import ISyncMandant from "./interface/ISyncMandant";

/**
 * login Management
 */

const loadMandantRequest = (mandant: string): Promise<any> => {
    return getData<ISyncMandant>(LOAD_MANDANT, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadMandantSingleRequest = (
    mandant: string,
    mandantId: string
): Promise<any> => {
    return getData<ISyncMandant[]>(ADMIN_MANDANT + mandantId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadMandantListRequest = (mandant: string): Promise<any> => {
    return getData<ISyncMandant[]>(ADMIN_MANDANT, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const deleteMandantRequest = (
    mandant: string,
    mandantId: number
): Promise<any> => {
    return deleteData<IMandantList>(ADMIN_MANDANT, mandantId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const createMandantRequest = (mandant: string, bodyData: any): Promise<any> => {
    return postData<any, any>(ADMIN_MANDANT, bodyData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const resetAdminPasswortRequest = (
    mandant: string,
    bodyData: any
): Promise<any> => {
    return putData<any, any>(ADMIN_CHANGE_ADMIN_PASSWORD, "", bodyData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const saveMandantRequest = (
    mandant: string,
    bodyData: any,
    mandantId: number
): Promise<any> => {
    return putData<any, any>(ADMIN_MANDANT, mandantId, bodyData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export {
    loadMandantSingleRequest,
    loadMandantRequest,
    saveMandantRequest,
    createMandantRequest,
    loadMandantListRequest,
    deleteMandantRequest,
    resetAdminPasswortRequest,
};
