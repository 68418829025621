import React from "react";

import { APP_VERSION } from "../../config/config";

interface ICacheBusterProps {
    children: (
        loading: boolean,
        isLatestVersion: boolean,
        refreshCacheAndReload: () => void
    ) => any;
}

interface ICacheBusterState {
    loading: boolean;
    isLatestVersion: boolean;
}

export default class CacheBuster extends React.Component<
    ICacheBusterProps,
    ICacheBusterState
> {
    constructor(props: ICacheBusterProps) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
        };
    }

    componentDidMount() {
        fetch("/meta.json")
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion: string = "" + meta.version;
                const currentVersion = APP_VERSION;

                const shouldForceRefresh = this.semverGreaterThan(
                    latestVersion,
                    currentVersion
                );

                if (shouldForceRefresh) {
                    // eslint-disable-next-line no-console
                    console.log(
                        `We have a new version - ${latestVersion}. Should force refresh`
                    );
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    // eslint-disable-next-line no-console
                    console.log(
                        `You already have the latest version - ${latestVersion}. No cache refresh needed.`
                    );
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }

    private semverGreaterThan = (versionA: string, versionB: string) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            if (a === b) {
                continue;
            }
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };

    private refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
                for (const name of names) {
                    caches.delete(name);
                }
            });
            // delete browser cache and hard reload
            window.location.reload(true);
        }
    };

    public render() {
        const { loading, isLatestVersion } = this.state;
        return this.props.children(
            loading,
            isLatestVersion,
            this.refreshCacheAndReload
        );
    }
}
